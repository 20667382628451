export function darkenHexColor(hex, percent) {
  // Ensure the hex string starts with "#"
  if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
  }

  // Convert hex to r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  // Darken each color component
  r = parseInt(r * (1 - percent/100));
  g = parseInt(g * (1 - percent/100));
  b = parseInt(b * (1 - percent/100));

  // Ensure values are within 0-255 range
  r = Math.max(0, Math.min(255, r));
  g = Math.max(0, Math.min(255, g));
  b = Math.max(0, Math.min(255, b));

  // Convert back to hex and return
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
}

// Example usage:
const originalColor = "#3498db"; // Some blue color
const darkenedColor = darkenHexColor(originalColor, 20);
console.log(darkenedColor);  // Outputs the darkened color