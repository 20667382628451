import {Select, MenuItem} from "@material-ui/core"
import {useContext, useState, useEffect} from "react"
import GPTBillContext from "../../../../contexts/GPTBillContext"
import {titleize} from "../../../helpers/string-helpers"
import { UTILITY_CHARGE_TYPES, UNFOCUSABLE_PROPS } from "../../../helpers/constants"

export default function ChargeTypeSelect({charge, liIndex, setGptBill, disabled, li}) {

    const handleChargeTypeChange = e => {
        const chargeTypeId = e.target.value;
        setGptBill(prevState => {
            const newCharges = [...prevState.charges];
            const liIndex = newCharges.findIndex(c => c.id == charge.id)
            newCharges[liIndex].utility_charge_type_id = chargeTypeId;
            return { ...prevState, charges: newCharges };
        });
    }

    return (
        <Select
            value={charge.utility_charge_type_id || 'blank'}
            onChange={handleChargeTypeChange}
            disabled={disabled}
            {...UNFOCUSABLE_PROPS}
        >
            <MenuItem key={'none'} value={'blank'}>Select Charge Type</MenuItem>
            {UTILITY_CHARGE_TYPES.map(ct => <MenuItem key={ct.id} value={ct.id}>{titleize(ct.name)}</MenuItem>)}
        </Select>
    )
}