import {Line} from 'react-chartjs-2'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const chartData= {
	"datasets": [
		{
			"label": "Baseline",
			"data": [
				2268708.8484,
				2552057.3356,
				2561477.1856,
				2503456.5455,
				2968362.7781,
				2926984.7299,
				3241349.837,
				3238862.445,
				2846384.4551,
				2934255.9484,
				2580263.6255
			],
			"fill": 'origin', // Shade the area under the line
			"backgroundColor": "rgba(128, 128, 128, 0.2)", // Light grey fill
			"borderColor": "rgb(128, 128, 128)",
			"tension": 0 // Straight lines
		},
		{
			"label": "Actual",
			"data": [
				2378807.5097,
				2347230.1091,
				2449422.3264,
				2203763.6333999997,
				2483077.9809,
				3021738.2857,
				3197669.0795,
				3160903.5428,
				2935911.1596,
				3274321.4044,
				2653773.8482999997
			],
			"fill": 'origin', // Shade the area under the line
			"backgroundColor": "rgba(173, 216, 230, 0.4)", // Light blue fill
			"borderColor": "rgb(173, 216, 230)",
			"tension": 0 // Straight lines
		},
		{
			"label": "Target",
			"data": [
				2268708.8484,
				2552057.3356,
				2561477.1856,
				2503456.5455,
				2968362.7781,
				2926984.7299,
				3241349.837,
				3238862.445,
				2846384.4551,
				2934255.9484,
				2580263.6255
			],
			"fill": false,
			"borderColor": "rgb(255, 22,22)",
			"borderWidth": 3, // Make the target line wider
			"tension": 0 // Straight lines
		}
	],
	"labels": [
		"2022-06-01",
		"2022-07-01",
		"2022-08-01",
		"2022-09-01",
		"2022-10-01",
		"2022-11-01",
		"2022-12-01",
		"2023-01-01",
		"2023-02-01",
		"2023-03-01",
		"2023-04-01"
	]
}

// Example Chart.js configuration
const options = {
  scales: {
    x: {
      title: {
        display: true,
        text: 'Month'
      }
    },
    y: {
      title: {
        display: true,
        text: 'Usage (kBtus)'
      }
    }
  },
  plugins: {
    legend: {
      position: 'bottom',
      
    }
  }
  
};

export default function ChartTester() {
  return (
    <div class='chart-test-container'>
      <Line options={options} data={chartData} />
    </div>
  )
}