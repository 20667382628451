import { Box, Alert, Snackbar, Modal, Paper, Typography, makeStyles, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem, Button, TextField, IconButton, Grid, Divider } from "@material-ui/core";
import { useState, useEffect, useContext } from 'react';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@material-ui/icons/Close';
import {resizeIframeScript} from "./shareable-helpers"
import WidgetContext from "../../../contexts/WidgetContext";
import axiosAuth from "../../axiosWithAuth";
import orange from '@material-ui/core/colors/orange';
import { SketchPicker } from "react-color";

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    maxWidth: 800,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    outline: 'none',
    paddingTop: 26
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  codeBlock: {
    fontFamily: 'monospace',
    backgroundColor: theme.palette.mode == 'dark' ? '#444' : theme.palette.grey[200],
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    overflowX: 'auto',
    whiteSpace: 'pre',
  }
}));

export default function YoyModal(props) {
  const classes = useStyles();
  const {properties} = useContext(WidgetContext)
  const [form, setForm] = useState({
    propertySelect: true, 
    includeFYSelect: false,
    selectedPropertyIds: [],
    includeCosts: true,
    includeUsage: true,
    hideDarkModeToggle: false,
    hideExportData: false,
    hideShareLink: true,
    showLogo: true,
    primaryColor: orange[600],
    darkMode: 'light',
    fillScreen: false,
    slideShow: false,
  });

  const [shareableToken, setShareableToken] = useState(null)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [openColorPicker, setOpenColorPicker] = useState(false)

  const [shareables, setShareables] = useState({
    link: "placeholder link",
    iframe: "<iframe id='embedded-ep-app' src='https://qa-management.energyprint.com/widgets/yearoveryear'></iframe>"
  })

  const buildLink = token => {
    return `${process.env.REACT_APP_URL}/widgets/yearoveryear?widgetToken=${token}`;
  }

  const buildIframe = token => `<iframe src='${buildLink(token)}'></iframe>${resizeIframeScript}`;

  const generateShareables = form => {
    axiosAuth().post("/properties/generate_shareables", form)
      .then(r => {
        console.log(r.data.token)
        setShareableToken(r.data.token)
        // build link and iframe here
        setSnackbarMessage("Generated new links")
      })
      .catch(e => console.log(e))
  }

  useEffect(_ => {
    const link = buildLink(shareableToken)
    const iframe = buildIframe(shareableToken)
    setShareables({
      link, 
      iframe 
    })
  },[shareableToken])

  const handleSelectAll = () => {
    setForm(prevState => ({...prevState, selectedPropertyIds: properties.map(p => p.id)}));
  };

  useEffect(() => {
    if (props.propertyId) {
      console.log("setting to propertyId")
      setForm(prevState => ({...prevState, selectedPropertyIds: [+props.propertyId]}))
    } else {
      console.log("selecting all")
      handleSelectAll();
    }
  }, [properties, props.propertyId]);

  const handleCheckboxChange = e => setForm(prevState => ({ ...prevState, [e.target.name]: e.target.checked}))
  const handleFormChange = e => setForm(prevState => ({ ...prevState, [e.target.name]: e.target.value}))

  const handleCopy = str => {
    navigator.clipboard.writeText(str);
  };

  const handleColorChange = (color) => {
    if (!color) return
    setForm(form => ({...form, primaryColor: color.hex}));
  };


  return (
    <Modal
      {...props}
      aria-labelledby="yoy-modal-title"
      aria-describedby="yoy-modal-description"
    >
      <Paper className={classes.paper}>
        <IconButton
          style={{ position: 'absolute', right: '10px', top: '10px' }}
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" id="yoy-modal-title">
          Share
        </Typography>
        <Typography paragraph id="yoy-modal-description">
          Share your year-over-year utility data analysis by sending it to collaborators or embedding it within your own applications, with control over which property data is included.
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox name="includeCosts" checked={form.includeCosts} />}
              label="Share Cost Data"
              onChange={handleCheckboxChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox name="includeUsage" checked={form.includeUsage} />}
              label="Share Usage Data"
              onChange={handleCheckboxChange}
            />
          </Grid>
          <Divider style={{ width: '100%', margin: '10px 0' }} />
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox name="propertySelect" checked={form.propertySelect} />}
              label="Include Property Select"
              onChange={handleCheckboxChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox name="includeFYSelect" checked={form.includeFYSelect} />}
              label="Include Fiscal Year Selector"
              onChange={handleCheckboxChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox name="hideDarkModeToggle" checked={form.hideDarkModeToggle} />}
              label="Hide Dark Mode Toggle"
              onChange={handleCheckboxChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox name="hideExportData" checked={form.hideExportData} />}
              label="Hide Export Data"
              onChange={handleCheckboxChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox name="hideShareLink" checked={form.hideShareLink} />}
              label="Hide Sharing Options"
              onChange={handleCheckboxChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            
          </Grid>
          <Divider style={{ width: '100%', margin: '10px 0' }} />
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox name="darkMode" checked={form.darkMode == 'dark'} />}
              label="Use Dark Mode"
              onChange={e => handleFormChange({ target: { name: 'darkMode', value: e.target.checked ? 'dark' : 'light' } })}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
            style={{marginTop: 7}}
              control={
                <Box style={{ marginLeft: 12, marginRight: 8, width: 18, height: 18, borderRadius: 2, backgroundColor: form.primaryColor, border: '1px solid #ccc', cursor: 'pointer' }} onClick={() => setOpenColorPicker(!openColorPicker)} />
              }
              label="Color"
            />
            {openColorPicker && (
              <div style={{ position: 'absolute', zIndex: 2 }}>

                <SketchPicker
                  color={form.primaryColor}
                  onChangeComplete={color => {
                    handleColorChange(color);
                  }}
                  onClose={() => setOpenColorPicker(false)}
                />
              </div>
            )}
          </Grid>
          <Divider style={{ width: '100%', margin: '10px 0' }} />
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox name="slideShow" checked={form.slideShow} />}
              label="Slideshow Mode"
              onChange={handleCheckboxChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControlLabel
              control={<Checkbox name="fillScreen" checked={form.fillScreen} />}
              label="Fullscreen (Kiosk)"
              onChange={handleCheckboxChange}
            />
          </Grid>
        </Grid>

        <FormControl className={classes.formControl}>
          <InputLabel id="property-select-label">Select Properties</InputLabel>
          <Select
            labelId="property-select-label"
            id="property-select"
            multiple
            value={form.selectedPropertyIds}
            onChange={handleFormChange}
            name="selectedPropertyIds"
            renderValue={(selected) => properties.filter(prop => selected.includes(prop.id)).map(prop => prop.name).join(', ')}
          >
            {properties.map(property => (
              <MenuItem key={property.id} value={property.id}>
                <Checkbox checked={form.selectedPropertyIds.includes(property.id)} />
                {property.name}
              </MenuItem>
            ))}
          </Select>
          <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '10px' }}>
            <Button variant="contained" color="primary" onClick={handleSelectAll}>
              Select All
            </Button>
            &nbsp;
            <Button variant="outlined" color="secondary" onClick={() => setForm(prevState => ({ ...prevState, selectedPropertyIds: [] }))}>
              Select None
            </Button>
          </div>
        </FormControl>
        <FormControl className={classes.formControl}>
          <Button onClick={_ => generateShareables(form)} variant='contained' disabled={(!form.includeCosts && !form.includeUsage) || !form.selectedPropertyIds.length}>Generate Sharing Links</Button>
        </FormControl>

        <div style={{ minHeight: 270}}>
          {shareableToken ? (
            <div>
              <Typography variant='h5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Shareable Link:
                <IconButton onClick={() => {
                    handleCopy(shareables.link);
                    setSnackbarMessage("Copied sharing link");
                }} color="primary">
                  <ContentCopyIcon />
                </IconButton>
              </Typography>
              <Typography variant="body2" className={classes.codeBlock} style={{ maxHeight: '100px', overflowY: 'auto' }}>
                {shareables.link}
              </Typography>
              <Typography variant='h5' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                Embed Code:
                <IconButton onClick={() => {
                    handleCopy(shareables.iframe);
                    setSnackbarMessage("Copied embed code");
                }} color="primary">
                  <ContentCopyIcon />
                </IconButton>
                <Snackbar
                  open={!!snackbarMessage}
                  autoHideDuration={2000}
                  onClose={() => setSnackbarMessage("")}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                  <Alert onClose={() => setSnackbarMessage("")} severity="success">
                    {snackbarMessage}
                  </Alert>
                </Snackbar>
              </Typography>
              <Typography variant="body2" className={classes.codeBlock} style={{ maxHeight: '100px', overflowY: 'auto' }}>
                {shareables.iframe}
              </Typography>
            </div>
          ) : (
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 270}}>
              <Typography variant="h6" style={{ textAlign: 'center' }}>
                Your links will appear here
              </Typography>
            </div>
        )}
      </div>
      </Paper>
    </Modal>
  )
}
