import React, {useState, useEffect, useContext} from "react";
import { Alert, Container, Button, Typography, capitalize} from "@material-ui/core";
import {DataGrid} from '@material-ui/data-grid';
import {Link} from 'react-router-dom';
import './PropertiesList.scss';
import axiosWithAuth from "../../utils/axiosWithAuth";
import UserContext from "../../contexts/UserContext"

export default function PropertiesList({history}) {
    const {properties, setProperties} = useContext(UserContext);

    const columns = [
        {
            width: 100,
            field: " ",
            headerName: "",
            disableClickEventBubbling: true,
            renderCell: (params) => {
              const onClick = () => {
                const api = params.api;
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
                return alert(`You clicked ${thisRow.name}`);
              };
              return <Button onClick={onClick}>Update</Button>;
            }
        },
        { field: 'name', headerName: 'Name', flex: 0.3 },
        { field: 'created_at', renderCell: params => new Date(params.value).toLocaleString(), headerName: 'Submitted At', width: 200 },
        {
          field: 'workflow_state',
          renderCell: params => capitalize(params.value),
          headerName: 'Status',
          width: 250,
        }
      ];

    
    const [loaded, setLoaded] = useState(false)
    const [propertyPluralization, setPropertyPluralization] = useState("0 properties")
    useEffect(_ => {
        axiosWithAuth().get(`/properties/onboarding`)
        .then(r => {
            // lots more data here, can populate edit form!
            setProperties(r.data)
            setLoaded(true)
        })
        .catch(err => console.log(err))
    }, [])

    useEffect(_ => {
      const unsubmittedCount = properties.filter(p => p.workflow_state === 'unsubmitted').length;
      unsubmittedCount === 1
      ? setPropertyPluralization("1 property")
      : setPropertyPluralization(`${unsubmittedCount} properties`)
    }, [properties])
    return(
        <>
            <Typography variant='h4' style={{marginTop: 30, marginBottom: 10}}>
                My Properties
                <Link className="unstyled-link" to="/properties/new"><Button id='add-property-button' variant='contained'>+</Button></Link>
                {!!properties.filter(p => p.workflow_state === "unsubmitted").length && 
                  <Alert className="checkout-alert" severity='info'
                  action={
                    <Button onClick={ _ => history.push('/checkout') } color='inherit' size='small'>
                      Check Out
                    </Button>
                  }>
                    You have {propertyPluralization} ready to submit.
                  </Alert>
                }
            </Typography>

            <div style={{height: "80vh", width: "100%"}}>
                {!properties.length && loaded
                  ? <Typography variant='h5' >You haven't added any properties yet.</Typography> 
                  : <DataGrid rows={properties} columns={columns} pageSize={10} />}
            </div>
            
        </>
    )
}