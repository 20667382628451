export const resizeIframeScript = `
<script>
  // Script for dynamically resizing iframe based on postMessage events
  window.addEventListener('message', function(event) {
    const newHeight = parseInt(event.data, 10); // Ensure the height is an integer
    const iframe = document.getElementById('embedded-ep-app');
    const newHeightString = \`\${newHeight}px\`;

    // Update iframe height if the new height is valid and different from the current
    if (iframe && !isNaN(newHeight) && newHeight > 0 && \`\${iframe.style.height}\` !== newHeightString) {
      iframe.style.height = newHeightString;
    }
  }, false);
  document.addEventListener('click', () => {
    const iframe = document.getElementById('embedded-ep-app');
    iframe.contentWindow.postMessage('closeDropdown', '*');
  });
</script>
`;