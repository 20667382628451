// TODO: redirect logins to the live.energyprint.com onboarding tracker

import React, {useState, useContext, useEffect} from 'react';
import {useHistory, useLocation} from "react-router-dom";
import {Avatar, Button, Alert, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Typography, Container} from "@material-ui/core"
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import {Link as RouterLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import {redirectToRails} from "./helpers/rails-helpers";
import axios from "axios";
import UserContext from "../contexts/UserContext";
import LoggedIn from "./LoggedIn";
import "./AuthForm.scss"

const baseUrl = process.env.REACT_APP_EP_API_URL;
// TODO: move these to another file - LOCALIZATION MATTERS
const VALIDATION_ERROR = "Please provide an email and password.";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));




export default function SignIn() {
  const classes = useStyles();
  const {user, setUser} = useContext(UserContext);
  const [expired, setExpired] = useState(false);
  const [errors, setErrors] = useState([])
  const [creds, setCreds] = useState({
    email: "",
    password: "",
    remember: false
  })
  const toggleRemember = _ => setCreds({...creds, remember: !creds.remember })
  const handleChange = e => setCreds({...creds, [e.target.name] : e.target.value })
  const history = useHistory();
  const location = useLocation();
  const handleSubmit = e => {
    e.preventDefault()

    // LOGIN FORM VALIDATIONS

    if (!creds.email || !creds.password) {
      setErrors([VALIDATION_ERROR])
      return
    }
    
    axios.post(`${baseUrl}/auth/login`, creds)
    .then(r => {
        setErrors([])
        localStorage.setItem("jwt", r.data.token)
        setUser({...user, loggedIn: true})
        // will this need a confirmatoin message?
        redirectToRails();
        // history.push("/properties")
      })
      .catch(err => {
        console.log(err)
        if (err && err.response && err.response.status === 401) {
          setErrors(["Invalid credentials."])
        } else if (err && err.response && err.response.status === 400) {
          setErrors(["That account is not registered."])
        } else {
          setErrors(["Something went wrong. Try again in a minute."])
        }
      })
  }

  useEffect(_ => {
    if (new URLSearchParams(location.search).get('expired')) {
      setExpired(true);
      history.push('/login')
    }
  },[location])
  

  return (
    !user.loggedIn ? 
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        {expired && <Alert variant='outlined' className="expired-token-alert" severity="warning">Your session has expired. Please log in again.</Alert> }
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={creds.email}
            onChange={handleChange}
            autoComplete="email"
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            value={creds.password}
            onChange={handleChange}
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox  name="remember" 
                                onChange={toggleRemember} 
                                value={creds.remember} 
                                color="primary" 
                    />}
            label="Remember me"
          />
          {!!errors.length && <Alert variant='outlined' className="form-validation-error" severity="error">{errors.join(" \n")}</Alert>}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link target="_blank" rel="noopener noreferrer" href="https://live.energyprint.com/password_resets/new" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <RouterLink to="/signup">
                <Link component="span" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </RouterLink> 
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
    : 
    <LoggedIn />
  );
}