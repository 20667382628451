import { useState, useEffect, useContext } from "react"
import TextractContext from "../../../contexts/TextractContext"
import { Link, Typography, Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from "@material-ui/core"
import { PictureAsPdf, Layers } from "@material-ui/icons"


export default (props) => {
  const {selectedTextractBill} = useContext(TextractContext)
  const [groupedTemplates, setGroupedTemplates] = useState({})

  const sortTemplateTypes = (a, b) => {
    const order = ["Invoices", "Histories", "Other"];
    return order.indexOf(a[0]) - order.indexOf(b[0]);
  };

  useEffect(_ => {
    setGroupedTemplates((selectedTextractBill.templates || []).reduce((obj, template) => {
      if (!obj[template.type]) obj[template.type] = []
      obj[template.type].push(template)
      return obj
    },{}))
  },[selectedTextractBill])
  return (
    <div className="template-container" style={{}}>
      {
        JSON.stringify(groupedTemplates) != '{}' && 
        <>
          <Divider style={{margin: 20, marginTop: 30}}/>
          <Typography variant='h5'>Templates <Layers/></Typography>
          <List component="div">

          {Object.entries(groupedTemplates).sort(sortTemplateTypes).map(([type, templates]) => 
            <>

            <ListSubheader sx={{fontSize: 24}}>{type.replace(/s$/, "").replace(/ie$/, "y")}</ListSubheader>
              {templates.sort((a,b) => a.name.length - b.name.length).map(template => 
                <>
                <ListItem button onClick={e => window.open(template.link)}>
                  <ListItemIcon>
                    <Link target="_blank" href={template.link}>
                      <PictureAsPdf />
                    </Link>
                  </ListItemIcon>
                  <ListItemText>
                  <Link target="_blank" href={template.link}>
                      {template.name}
                    </Link>
                  </ListItemText>
                </ListItem>
                </>
              )}
            </>
          )}
          </List>
        </>
      }

    </div>
    

  )
}