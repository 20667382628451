import React, { useEffect, useRef, useContext } from 'react';
import { Paper, TextField, Button, Divider, Typography } from '@material-ui/core';
import AudioRecorder from '../util/AudioRecorder';
import axiosAuth from '../../../utils/axiosWithAuth';
import MessageList from './MessageList';
import WidgetContext from '../../../contexts/WidgetContext';
import ChatHeader from './ChatHeader';

export default function Chat() {
  const {queryParams, selectedProperty, setSelectedProperty, properties, setProperties} = useContext(WidgetContext)
  const [messages, setMessages] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [loadingReply, setLoadingReply] = React.useState(true)
  const [tokenUsage, setTokenUsage] = React.useState(0)
  const [perTokenPrice, setPerTokenPrice] = React.useState(0.00000015)
  const messagesEndRef = useRef(null);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSendMessage = (messages, tokenUsage) => {
    if (inputValue.trim()) {
      
      const newMessage = { role: 'user', content: inputValue };
      setMessages([...messages, newMessage]);
      setInputValue('');
      setLoadingReply(true)
      axiosAuth().post("/admin/chat/continue", {messages, userMessage: newMessage, tokenUsage: tokenUsage})
      .then(r => {
        console.log(r.data)
        setTokenUsage(+r.data.tokenUsage)
        
        setMessages(r.data.messages)
        setLoadingReply(false)
      }).catch(err => console.log(err))
    }
  };


  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    console.log(messages)
  }, [messages]);

  useEffect(_ => {
    // initialize chat, once complete, unlock interface
    console.log("Property changed...", selectedProperty)
    if (!properties.length) {

      axiosAuth().get("/admin/properties")
      .then(r => {
        setProperties(r.data.properties)
        let propertyToSelectByDefault = r.data.properties.find(p => p.id == queryParams.property_id) || r.data.properties[0]
        setSelectedProperty(propertyToSelectByDefault)
      }).catch(err => console.log(err))
    } else {
      setMessages([])
      setLoadingReply(true)
      axiosAuth().post("/admin/chat/initialize", {utilityType: "", property_id: selectedProperty.id})
      .then(r => {
        console.log(r.data.messages)
        setMessages(r.data.messages)
        
        setPerTokenPrice(+r.data.perTokenPrice)
        setTokenUsage(+r.data.tokenUsage)
        setLoadingReply(false)
      }).catch(err => console.log(err))
    }
  }, [selectedProperty])

  return (
    <>
      <ChatHeader properties={properties} setSelectedProperty={setSelectedProperty} selectedProperty={selectedProperty} />
      <div style={{ padding: '20px', margin: '20px auto', display: 'flex', flexDirection: 'column', minHeight: '85vh', maxWidth: '1200px' }}>
        <MessageList loadingReply={loadingReply} messages={messages} messagesEndRef={messagesEndRef} />
        <Divider style={{ backgroundColor: 'gray', width: '100%', margin: 10, marginBottom: 16 }} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <TextField
            fullWidth
            variant="outlined"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Type your message..."
            multiline
            disabled={!messages.length}
            style={{ marginRight: '10px', flexGrow: 1 }}
            onKeyPress={(event) => {
              if (event.key === 'Enter' && event.shiftKey) {
                  setInputValue(inputValue + '\n');
                  event.preventDefault();
              } else if (event.key === 'Enter') {
                  handleSendMessage(messages, tokenUsage);
                  event.preventDefault();
              }
            }}
          />
          <Button variant="contained" color="primary" onClick={_ => handleSendMessage(messages, tokenUsage)}>
            Send
          </Button>
        </div>
      </div>
      {/* <Typography style={{textAlign: 'center'}} variant="body1">Tokens used: {tokenUsage}</Typography>
      <Typography style={{textAlign: 'center'}} variant="body1">Cost of this chat: ${(tokenUsage * perTokenPrice).toFixed(3)}</Typography> */}
    </>
  );
}