import OnboardAccount from "./OnboardAccount"
import OnboardCompany from "./OnboardCompany"
import OnboardMeter from "./OnboardMeter"
import axiosAuth from "../../../../utils/axiosWithAuth"
import {useEffect} from "react"
import {useState} from "react"


export default ({bill, setBill}) => {
  const [formData, setFormData] = useState({
    properties: [],
    energyTypes: [],
    etmuMap: {},
    dmuMap: {}
  })
  useEffect(_ => {
    axiosAuth().get('/admin/textract_bills/onboarding/formData')
    .then(r => {console.log(r.data); setFormData(r.data)})
    .catch(e => console.log(e))

  },[]) 
  return(
    <div className='onboarding-form'>
      <OnboardCompany bill={bill} setBill={setBill} />
      <OnboardAccount bill={bill} setBill={setBill} />
      {bill.utility_account_id && <OnboardMeter bill={bill} setBill={setBill} formData={formData} />}

    </div>
  )
}