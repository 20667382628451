import {TextField, Modal, Typography, Dialog, Button, Divider, IconButton} from "@material-ui/core"
import {useContext, useState, useEffect} from 'react'
import RequestListContext from "../../contexts/RequestListContext";
import axiosAuth from "../../utils/axiosWithAuth";
import { getFormattedDate } from "../helpers/date-helpers";
import Loader from "react-loader-spinner";
import CloseIcon from '@material-ui/icons/Close';

export default function UamNotesModal({setOpenSnackbar, openModal, request, handleClose}) {
    const [newNote, setNewNote] = useState("")
    const [notes, setNotes] = useState([])
    const [editingNote, setEditingNote] = useState(null)

    const handleCreateNote = e => {
        e.preventDefault()
        if (!newNote) return
        const {utility_account_meter_id} = request;
        axiosAuth().post(`/admin/utility_account_meter_notes/`, {utility_account_meter_id, content: newNote})
            .then(r => {
                setNotes([...notes, r.data.note])
                setNewNote("")
            })
            .catch(err => console.log(err))
    }

    const handleDelete = note_id => {
        if (window.confirm("Are you sure you want to delete?")) {
            axiosAuth().delete(`/admin/utility_account_meter_notes/${note_id}`)
            .then(r => {
                setNotes(notes.filter(n => n.id !== note_id))
            })
            .catch(console.error)
        }
    }

    const handleSave = _ => {
        console.log(editingNote)
        const editingNoteId = editingNote.id
        axiosAuth().put(`/admin/utility_account_meter_notes/`, editingNote)
        .then(r => {
            setEditingNote(null)
            setNotes([...notes.filter(n => n.id !== editingNoteId), r.data.newNote])
        })
        .catch(console.log)
    }

    useEffect(_ => {
        setNewNote("")
        if (request) {
            setNotes([])
            axiosAuth().get(`/admin/utility_account_meter_notes/${request.utility_account_meter_id}`)
            .then(r => {setNotes(r.data.notes)})
            .catch(err => console.log(err))
        }
    }, [request])

    const EditDeleteButtonPair = ({n}) => {
        return (
            <div className="note-button-row">
                <Button onClick={_ => setEditingNote(n)}>Edit</Button> 
                <Button onClick={_ => handleDelete(n.id)}>Delete</Button>
            </div>
        )
    }

    return (
        <Dialog maxWidth={960} open={openModal} onClose={handleClose}>
            <div style={{width: 960, height: '60vh', padding: 10}}>
                <div style={{display: 'flex', justifyContent: 'space-between', padding: 10}}>
                    <Typography style={{marginBottom: 10}} variant="h4">Connection Notes (#{request ? request.utility_account_meter_id : ""})</Typography>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <form className="add-note-widget" onSubmit={handleCreateNote}>
                    <div style={{display: 'flex', width: '100%'}}>
                    <TextField
                        style={{margin: 0, height: 56}}
                        rows={1}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        id="newNote"
                        label="New Note"
                        name="newNote"
                        value={newNote}
                        onChange={e => setNewNote(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                    />
                    <Button style={{}} disabled={!newNote} type="submit">Create Note +</Button>
                    </div>
                </form>
                <div className="uam-notes-list">
                   
                    {!!notes.length 
                    ? notes.sort((a,b) => b.id-a.id ).map(n => {
                        return <>
                            <div>
                                <Typography style={{display: 'block', marginBottom: 10}} variant="p" color='textSecondary'>
                                    {getFormattedDate(n.created_at)} 
                                </Typography>
                                {
                                    editingNote && editingNote.id === n.id
                                    ?   <TextField
                                    style={{margin: 0}}
                                    multiline
                                    rows={1}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="name"
                                    label="Edit Note"
                                    name="name"
                                    value={editingNote.content}
                                    onChange={e => setEditingNote({...editingNote, content: (e.target.value)})}
                                    InputLabelProps={{ shrink: true }}
                                />
                                    : <Typography variant="h5">
                                        {n.content}
                                    </Typography>

                                }
                                {
                                    !editingNote
                                    ? <EditDeleteButtonPair n={n} />
                                    : editingNote.id !== n.id
                                            ? <EditDeleteButtonPair n={n} />
                                            : <div className="note-button-row"><Button onClick={handleSave}>Save Changes</Button></div>
                                }
                                
                            </div>
                            <Divider style={{marginBottom: 10}} />
                        </>
                    })
                    : <Loader
                        style={{marginLeft: 5}}
                        type="Oval"
                        color="orange"
                        height={40}
                        width={40} />
                    }

                </div>

                <div style={{display: 'flex' }}>
                    <Button variant="outlined" onClick={handleClose}>Close</Button>
   
                </div>
            </div>
        </Dialog>
    )
}