import Loader from 'react-loader-spinner';

const SnackbarLoader = _ => (
  <Loader
    style={{marginLeft: 8}}
    type="ThreeDots"
    height={16}
    color='blue'
    width={16} 
  />
)

export default SnackbarLoader