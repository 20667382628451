import { Paper, Typography, Button, TextField, Link, Fade } from "@material-ui/core";


export default function IntakeCard({error, setSelectedError}) {

  return (
    <Paper style={{width: '98.5%', minHeight: 400, margin: '10px auto', padding: 15 }} elevation={5}>
      <Fade in={true} timeout={200}>
        {!!error
        ? <div className={`intake-card-container`} key={error.id}>
            <div className="intake-card-title-bar">
              <div style={{textAlign: "left"}}>
                <Typography variant='h3' style={{marginTop: 0}}>
                  {error.utility_company_name}
                </Typography>
                <Typography variant='h6' style={{marginTop: 20}}>
                  {error.error}
                </Typography>
              </div>
            
              <div style={{marginTop: -7}}>
                <div style={{display:'flex', justifyContent: 'flex-end', alignItems: 'flex-start'}}>
                  <div style={{display: 'flex', flexDirection: 'row'}}>    
                    <Button>Corner Button One</Button>   
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Button>Corner Button Two</Button>   
                  </div>


                  <Button onClick={_ => setSelectedError(null)}>Close X</Button>
                </div>
              </div>
            </div>
            <div className="intake-card-controls" style={{marginTop: 15}}>
              
            </div>
            {error.screenshots.map((ss, idx) => 
              <>
                {idx == 0 && <Typography variant="h3">Screenshots</Typography>}
                <Link href={ss.url}>{ss.s3_key.split("/").pop()}</Link>
                <br/>
                <img src={ss.url} />
                <br/>
              </>
            )}
            <div className="intake-card-bottom-row">
              <div>
                Bottom Left Corner
              </div>
              <div>
              Bottom Right Corner

              </div>
            </div>
          </div>
        : <Typography variant='h1' style={{paddingTop: 140}}>Select an error</Typography>}
      </Fade>
    </Paper>
  )
}