import { useContext } from 'react';
import { Button } from "@material-ui/core"
import Loader from "react-loader-spinner"
import GPTBillContext from "../../../contexts/GPTBillContext"
export default ({handleSave, gptBill, textractBill, handleReprocess, handleSkip, loading, billIsValid}) => {

  return (
    
    <div className='bill-creation-controls'>
      <Loader
        style={{opacity: loading ? 1 : 0, marginTop: 4, marginRight: "8px"}}
        type="Grid"
        height={36}
        color = 'orange'
        width={36} /> 
      {!!textractBill.status && !textractBill.status.includes("PROCESSED_")
      ? <>
          <Button 
              disabled={loading}
              onClick={_ => handleSkip(textractBill)} 
              className='control-button' 
              variant="outlined">Skip</Button>
          {gptBill && <Button 
              disabled={loading || !billIsValid(gptBill)}
              onClick={_ => handleSave(gptBill, textractBill)} 
              className='control-button' 
              variant="contained">Save Bill</Button>}
        </>
      : <Button 
          disabled={loading}
          onClick={_ => handleReprocess(textractBill)} 
          className="control-button reprocess-button" 
          variant="outlined">Reprocess</Button>}
    </div>
  )
}