import {Link as RouterLink} from "react-router-dom"
import { DataGrid } from "@material-ui/data-grid"
import {Link, Typography} from "@material-ui/core"
import {useState, useEffect} from "react"
import axiosAuth from "../../utils/axiosWithAuth"
export default function BillableCompaniesList() {
  const [companies, setCompanies] = useState([])
  useEffect(_ => {
    axiosAuth().get("/companies/active_directs")
    .then(r => setCompanies(r.data))
    .catch(e => console.log(e.response))
  },[])
  const getEntry = params => {
    const {api} = params;
    const fields = api
    .getAllColumns()
    .map((c) => c.field)
    .filter((c) => c !== "__check__" && !!c);
    const thisRow = {};
    
    fields.forEach((f) => {
        thisRow[f] = params.getValue(f);
    });

    return thisRow
}
  const INITIAL_COLUMNS = [
    { field: 'id', headerName: 'DataGrid uuid', width: 100, hide: true },
    {
        field: "name",
        headerName: `Company`,
        headerAlign: "center",
        align: "center",
        width: 300,
        renderCell: (params) => {
            
            const row = getEntry(params)
            
            return <RouterLink to={`/billing_portfolios/${row.id}`}><Link>{row.name}</Link></RouterLink>
        }
    },
    {
      field: "customer_type",
      headerName: "Customer Type",
      headerAlign: "center",
      align: "center",
      width: 200,
    }
  ]

  return (
    <>
    <Typography style={{margin: 20}} variant='h3'>Manage Billing Portfolios</Typography>
        
    <DataGrid
      rows={companies}
      columns={INITIAL_COLUMNS}
      pageSize={20}
      rowsPerPageOptions={[50]}
      disableColumnMenu
      autoHeight
    />
    </>
  )
}

