import {Box, Typography, Tooltip} from "@material-ui/core"
import InvoyageTooltip from "./InvoyageTooltip"
const COLOR_MAP = {
  PROCESSED_AUTO: "#1ab394",
  PROCESSED_MANUAL: "#1ab394",
  PROCESSED_FORCE: "#d88428",
  PROCESSED_FORCE_AUTO: "#d88428",
  ERROR: "red"
}

const TEXT_MAP = {
  PROCESSED_AUTO: "Auto Imported",
  PROCESSED_MANUAL: "Manually Imported",
  PROCESSED_FORCE: "Skipped",
  PROCESSED_FORCE_AUTO: "Skipped 🤖",
  ERROR: "Error"
}

export default ({table, status, title}) => {

  return (
    <InvoyageTooltip open={status == "ERROR" ? undefined : false} title={title}>

      <div style={{display: 'flex', justifyContent: 'center'}}>
        <Box 
          sx={{
            margin: table ? 0 : "0 5px",
            backgroundColor: COLOR_MAP[status] || '#999',
            borderRadius: 15, // rounded corners
            border: "2px solid gray",
            padding: "2px 8px",
            width: 'fit-content',
            textAlign: 'center'
          }}
          >
          <Typography variant="body1" color="white" style={{fontWeight: 'bold'}}>
            {TEXT_MAP[status] || "Unprocessed"}
          </Typography>
        </Box>
      </div>
    </InvoyageTooltip>
  );
}