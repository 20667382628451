import React, { useState, useEffect, useContext } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Typography, Button, IconButton, Menu, MenuItem, makeStyles, TableContainer } from '@material-ui/core';
import { ArrowUpward, ArrowDownward, CloudDownload as CloudDownloadIcon } from '@material-ui/icons';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {abbreviateNumber} from "../../../components/helpers/number-helpers"
import InvoyageTooltip from '../../../components/textract/bill/InvoyageTooltip';
import WidgetContext from '../../../contexts/WidgetContext';
import UserContext from '../../../contexts/UserContext';
import NightModeToggle from '../../../components/NightModeToggle';
import { buildTheme } from '../../../themes/eptheme';
import CodeIcon from '@material-ui/icons/Code';
import ShareIcon from '@material-ui/icons/Share';

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: '2px !important',
    fontSize: '.75rem !important', // default fontSize
    '@media (max-width: 900px)': {
      fontSize: '.65rem !important'
    },
    '@media (max-width: 700px)': {
      fontSize: '.6rem !important'
    },
  },
  iconButton: {
    padding: '0px 5px', // default padding
    '@media (max-width: 900px)': {
      padding: '0px 2px', // smaller padding for smaller screens
      '& svg': {
        fontSize: '1.45rem', // smaller icon size for smaller screens
      },
    },
  },
}));

const ROW_COLORS = {
  light: {
    row1: '#f7f7f7',
    row2: '#ffffff',
    title: '#e5e5e5',
    highlight: '#FFEBD6',
    border: "rgba(224, 224, 224, 1)"
  },
  dark: {
    row1: '#171717',
    row2: '#333',
    title: '#2e2e2e',
    highlight: '#6C3A00',
    border: "#444"
    
  }
}

const baseUrl = process.env.REACT_APP_URL;

const YoyDataTable = ({ data, cost, loadingChart, actualCostData, actualUsageData, cumulativeCostData, cumulativeUsageData, property, energyType }) => {
  const classes = useStyles();
  const [sortParams, setSortParams] = useState({ column: 'year', direction: 'asc' });
  const [sortedData, setSortedData] = useState(data.datasets);
  const {windowWidth, handleOpenModal, queryParams, widgetOptions} = useContext(WidgetContext)
  const {activeTheme, setActiveTheme} = useContext(UserContext)
  const [rowColors, setRowColors] = useState(ROW_COLORS[activeTheme.palette.mode])
  
  const handleDarkModeToggle = (mode) => {

    localStorage.setItem('dark-mode', mode);

    setActiveTheme(buildTheme());
  };

  const handleSort = (column) => {
    setSortParams(prevParams => ({
      column,
      direction: prevParams.column === column ? (prevParams.direction === 'asc' ? 'desc' : 'asc') : 'asc'
    }));
  };

  const sortData = (a, b) => {
    const asort = sortParams.column == 'year' ? a.year : a.data[sortParams.column];
    const bsort = sortParams.column == 'yearne' ? b.year : b.data[sortParams.column];
    return sortParams.direction === 'asc' ? asort - bsort : bsort - asort;
  };

  useEffect(() => {
    setSortedData(data.datasets.slice().sort(sortData));
  }, [sortParams]);

  useEffect(() => {
    if (sortParams.column != "year" || sortParams.direction != 'asc') {
      setSortParams({ column: 'year', direction: 'asc' });
    } else {
      setSortedData(data.datasets.slice().sort(sortData));
    }
  }, [data]);

  const flattenDatasets = dataObj => {
    console.log(dataObj.datasets)
    const months = dataObj.labels
    return {
      title: dataObj.datasets[0] && dataObj.datasets[0].title,
      json: dataObj.datasets.map(ds => ({
        Year: ds.label,
        "Measurement Unit": ds.measurementUnit,
        ...months.reduce( (acc,label, idx) => {
          acc[label] = ds.data[idx]
          return acc
        }, {})
      }))
    }
  }



  const exportData = (format) => {
    const flatData = [actualCostData, actualUsageData, cumulativeCostData, cumulativeUsageData].map(flattenDatasets)
    // const flatData = flattenDatasets();

    const worker = new Worker(`${baseUrl}/workers/exportWorker.js`);
    
    worker.postMessage({ flatData, format, baseUrl, widgetOptions: widgetOptions });

    worker.onmessage = function(e) {
      const { blob } = e.data;
      const fileExtension = format === 'xlsx' ? '.xlsx' : '.csv';
      const fileName = `${property.name.replace(/[^\w\d\s]/g, "")}_${energyType}_year_over_year` + fileExtension;
      FileSaver.saveAs(blob, fileName);
      worker.terminate();
    };
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    if (e) e.stopPropagation()
    setAnchorEl(null);
  };

  const getBorderRule = windowWidth => `${windowWidth > 700 ? `1px solid ${rowColors.border}` : "none" }`

  return (
    <>
    <TableContainer style={{ overflowX: 'auto' }}>
      <Table border={windowWidth <= 700 ? 0 : 1} style={{overflowX: 'auto'}}>
        <TableHead>
          <TableRow style={{ backgroundColor: ROW_COLORS[activeTheme.palette.title] }}>  {/* Distinct header row color */}
            <TableCell style={{ width: 100, borderRight: getBorderRule()}} className={`${classes.tableCell} sort-header yoy-cell`} key='year-header' onClick={() => handleSort('year')}>
              <div className="table-column-title-container">
                <span>Year&nbsp;</span>
                {sortParams.column === 'year' && (sortParams.direction === 'asc' ? <ArrowUpward fontSize="1em" /> : <ArrowDownward fontSize="1em" />)}
              </div>
            </TableCell>
            {data.labels.map((label, index) => (
              <TableCell className={`${classes.tableCell} sort-header yoy-cell`} style={{ borderRight: (index == 11 ? `1px solid ${rowColors.border}` : getBorderRule()) }} key={index} onClick={() => handleSort(index)}>
                <div className="table-column-title-container">
                  <span>{windowWidth <= 700 ? label.substring(0, 3) : label}&nbsp;</span>
                  {sortParams.column === index && (sortParams.direction === 'asc' ? <ArrowUpward fontSize="1em" /> : <ArrowDownward fontSize="1em" />)}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{opacity: loadingChart ? 0 : 1}}>
          {sortedData.filter(row => !row.hidden).map((row, rowIndex) => (
            !row.hidden && <TableRow key={rowIndex} 
              style={{ backgroundColor: rowIndex % 2 === 0 ? rowColors.row1 : rowColors.row2 }}
              onMouseEnter={e => e.currentTarget.style.backgroundColor = rowColors.highlight}
              onMouseLeave={e => e.currentTarget.style.backgroundColor = rowIndex % 2 === 0 ? rowColors.row1 : rowColors.row2}>
              <TableCell className={`${classes.tableCell} yoy-cell`} key={`${row.cellIndex}-year`} borderRight={1}><Typography style={{ fontWeight: "bold", fontSize: 'inherit'}}>{row.label}</Typography></TableCell>
              {row.data.map((cell, cellIndex) => (
                <TableCell className={`${classes.tableCell} yoy-cell`} key={cellIndex} borderRight={cellIndex === row.data.length - 1 ? 0 : 1}>{cell && `
                  ${cell === 0 
                    ? "0" 
                    : windowWidth <= 700 
                      ? `${cost ? "$" : ""}${abbreviateNumber(cell)}`
                      : cost 
                        ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(cell) 
                        : cell.toLocaleString()}`}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      <div className="widget-controls">
        {(!queryParams.hideDarkModeToggle && !widgetOptions.hideDarkModeToggle) && <InvoyageTooltip title={`Toggle Dark Mode`} arrow>
          <NightModeToggle color='primary' activeTheme={activeTheme} handleDarkModeToggle={handleDarkModeToggle} />
        </InvoyageTooltip>}
        {(!queryParams.hideExportData && !widgetOptions.hideExportData) && <InvoyageTooltip title={`Export Data`} arrow>
          <IconButton onClick={handleClick}>
            <CloudDownloadIcon color='primary' />
          </IconButton>
        </InvoyageTooltip>}
        {(!queryParams.hideShareLink && !widgetOptions.hideShareLink) &&<InvoyageTooltip title={`Share`} arrow>
          <IconButton onClick={handleOpenModal}>
            <ShareIcon color='primary' />
          </IconButton>
        </InvoyageTooltip>}
        <>
                    
          <Menu
            id="export-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={(e) => { e.stopPropagation(); exportData("xlsx"); handleClose(); }}>
              Export to XLSX
            </MenuItem>
            <MenuItem onClick={(e) => { e.stopPropagation(); exportData("csv"); handleClose(); }}>
              Export to CSV
            </MenuItem>
          </Menu>
        </>
      </div>
    </>
  );
};

export default YoyDataTable;
