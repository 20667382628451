import {useState} from 'react';
import {Divider, TableRow, Table, TableBody, TableCell, TableContainer, TableHead, Fab, Modal, Grid, Button, Typography, Paper, TextField} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import {DataGrid} from '@material-ui/data-grid';
const INITIAL_USER = {
    email: "",
    full_name: ""
}

const columns = [
    { field: 'name', headerName: 'Name', flex: 0.45 },
    { field: 'email', renderCell: params => new Date(params.value).toLocaleString(), headerName: 'Email', flex: 0.45 },
    {
        width: 100,
        field: " ",
        headerName: "",
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onClick = () => {
            const api = params.api;
            const fields = api
              .getAllColumns()
              .map((c) => c.field)
              .filter((c) => c !== "__check__" && !!c);
            const thisRow = {};
            fields.forEach((f) => {
              thisRow[f] = params.getValue(f);
            });
            return alert(`You clicked ${thisRow.name}`);
          };
          return <Button onClick={onClick}>Update</Button>;
        }
    },
  ];
  const top = 5;
  const left = 50;


const useStyles= makeStyles(theme => ({

    paper: {
        position: 'absolute',
        width: '50%',
        backgroundColor: 'white',
        border: '2px solid black',
        boxShadow: "2px 2px 2px #888888",
        padding: theme.spacing(2,4,3),
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${left}%, 0%)`
    }
}))

export default function AttachUsers({attachedUsers, setAttachedUsers}) {
    const [newUser, setNewUser] = useState(INITIAL_USER)
    const [openModal, setOpenModal] = useState(false)
    
    const styles = useStyles();
    
    
    const handleChange = e => {
        setNewUser({ ...newUser, [e.target.name]: e.target.value, id: Date.now() })
    }
    const handleClose = e => setOpenModal(false)

    const addUser = e => {
        e.preventDefault();
        setAttachedUsers([...attachedUsers, newUser])
        setNewUser(INITIAL_USER)
    }

    const removeUser = name => {
        setAttachedUsers(attachedUsers.filter(au => au.full_name !== name))
    }

    return(
        <div>
            <div style={{margin: 20, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Typography variant='h9'>Invite team members:</Typography>
                <Fab style={{marginLeft: 15}} onClick={_ => setOpenModal(true)} color="primary">
                    <AddIcon />
                </Fab>
            </div>

            <div className="added-users-container">
            {!!attachedUsers.length && 
                <Table>
                    <TableHead>

                    </TableHead>
                    <TableBody>
                        {attachedUsers.map(au => 
                            <>
                            <TableRow>
                                <TableCell>{au.full_name}</TableCell>
                                <TableCell>{au.email}</TableCell>
                                <TableCell><Button onClick={_ => removeUser(au.full_name)}>Remove User</Button></TableCell>
                            </TableRow>
                            <Divider />
                            </>
                        )}
                    </TableBody>
                        
                
                </Table>
                }
            </div>
            <Modal
                open={openModal}
                onClose={handleClose}
            >
                <div className={styles.paper}>
                <Typography variant='h9'>Add up to 5 team members:</Typography>
                    {!!attachedUsers.length && 
                                    <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {attachedUsers.map(au => 
                                            <>
                                            <TableRow>
                                                <TableCell>{au.full_name}</TableCell>
                                                <TableCell>{au.email}</TableCell>
                                                <TableCell align='right'><Button onClick={_ => removeUser(au.full_name)}>Remove User</Button></TableCell>
                                            </TableRow>
                                            </>
                                        )}
                                    </TableBody>
                                        
                                
                                </Table>
                            
                    }

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="full_name"
                        label="Full Name"
                        name="full_name"
                        value={newUser.full_name}
                        onChange={handleChange}
                        autoComplete="full_name"
                        autoFocus
                    />

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        value={newUser.email}
                        onChange={handleChange}
                        autoComplete="email"
                        autoFocus
                    />

                    
                    <Button
                        id="add-user-button"
                        type="button"
                        onClick={addUser}
                        style={{margin: 20}}
                        disabled={!newUser.email || !newUser.full_name || attachedUsers.length >= 5 }
                        variant="contained"
                        color="primary"
                        fullwidth
                    >Add User</Button>
                    <Button
                        id="close-modal-button"
                        type="button"
                        fullwidth
                        onClick={handleClose}
                        variant='outlined'
                        color="primary"
                        // className={classes.submit}
                    >Done</Button>
                </div>
            </Modal>
        </div>
    )
}