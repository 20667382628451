import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_ID || "pk_test_51Ii0I9FCmHB9Lnii9QESczlPbK3OTBzm2WcesHGpIdlRJKw6eUQ6E4oTWx44pLJgSlUDdlI07kzS0N0NVrOGPkP200ru88ztui" || process.env.REACT_APP_STRIPE_ID
);

export default function StripeContainer() {
  return (

      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>

  );
}
