import React, {useState, useEffect} from "react";
import {MenuItem, Divider,InputLabel, Select, Grid, TextField, 
        FormControlLabel, Checkbox, Typography, Button,
        List, ListItem, ListItemText} from '@material-ui/core';
import './NewPropertyForm.scss';
import UtilityAccountsForm from "./UtilityAccountsForm";
import axiosWithAuth from "../../utils/axiosWithAuth";

export default function NewPropertyForm({history}) {
    const [energystarEnabled, setEnergystarEnabled] = useState(false)
    const [EPFormData, setEPFormData] = useState({  utility_companies: [], 
                                                    utility_accounts: [], 
                                                    primary_construction_types: [{id: 1, display_name: "Brick & Mortar"}], 
                                                    cooling_energy_types: [{id: 1, display_name: "Electric"}], 
                                                    heating_energy_types: [{id: 2, display_name: "Gas"}], 
                                                    primary_use_types: [{id: 133, display_name: "K-12 School"}]})
    const [uploadedFiles, setUploadedFiles] = useState([])
    const [loading, setLoading] = useState(true)
    const [esCreds, setEsCreds] = useState({
        energystar_username: "",
        energystar_password: ""
    })


    useEffect(_ => {
        axiosWithAuth().get("/properties/new")
            .then(r => setEPFormData(r.data))
            .catch(err => console.log(err))
    },[])

    const onFileLoad = e => {
        const formData = new FormData();
        formData.append("uploaded_file", e.target.files[0])
        formData.append("name", e.target.files[0].name)
        // axios post to rails here ?
        axiosWithAuth().post("properties/upload_file", formData)
        .then(r => {
            // if successful
            // append file data to our list of uploaded files
            setUploadedFiles([...uploadedFiles, e.target.files[0].name])
            e.target.value = ""; // clears file input
        }).catch(err => console.log(err))
    }

    
    const [accounts, setAccounts] = useState([])
    
    const [formInfo, setFormInfo] = useState({
        name: "",
        sq_ft: 0,
        street1: "",
        street2: "",
        city: "",
        state: "",
        zip_code: "",
        tax_id: "",
        number_of_floors: 1,
        year_built: "",
        federally_owned: false,
        heating_energy_type_id: 2,
        cooling_energy_type_id: 1,
        is_energystar_property: false,
        // energystar_username: "", 
        // figure out energystar password encryption scheme
        primary_construction_type_id: 1,
        primary_use_id: 133
    })
    
    const handleChange  = e => setFormInfo({ ...formInfo, [e.target.name]: e.target.value })
    const handleTaxIdChange  = e => setFormInfo({ ...formInfo, tax_id: formatTaxId(e.target.value) })
    
    const handleEsCredChange = e => setEsCreds({...esCreds, [e.target.name]: e.target.value})
    

    const handleSubmit = e => {
        e.preventDefault();
        axiosWithAuth().post(`/properties/create`, {property: formInfo, accounts: accounts, esCreds} )
        .then(r => history.push('/properties'))
        .catch(err => console.log(err))
    }

    const formatTaxId = str => {
        return str.replace(/[^\d]/g, "").replace( /(\d{2})(\d{1,7})\w*/, "$1" + "-" + "$2")
    }

    const range = (s, e, length = e - s) => Array.from({length}, (_,i) => s + i)

    return(
        <div className={`add-property-form-container`}>
            <Typography variant='h5' style={{margin: 10}}>
            Add a Property
            </Typography>
            <form onSubmit={handleSubmit} className='add-property-form' noValidate>
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="name"
                    label="Property Name"
                    id="property_name"
                    value={formInfo.name}
                    onChange={handleChange}
                    autoComplete="current-password"
                    autoFocus
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                
                    fullWidth
                    name="street1"
                    label="Street Address"
                    id="property_street1"
                    value={formInfo.street1}
                    onChange={handleChange}
                    autoComplete="current-password"
                    
                />
                <Grid container spacing={4}>
                    <Grid item xs>
                        <TextField
                            variant="outlined"
                            margin="normal"
                        
                            fullWidth
                            name="city"
                            label="City"
                            id="property_city"
                            value={formInfo.city}
                            onChange={handleChange}
                            autoComplete="current-password"
                            
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            variant="outlined"
                            margin="normal"

                            fullWidth
                            name="state"
                            InputProps={{ inputProps: { maxLength: 2 } }}
                            label="State"
                            id="property_state"
                            value={formInfo.state}
                            onChange={handleChange}
                            onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                            autoComplete="current-password"
                            
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            variant="outlined"
                            margin="normal"
                        
                            fullWidth
                            name="zip_code"
                            InputProps={{ inputProps: { maxLength: 6 } }}
                            label="Zip"
                            id="property_zip_code"
                            value={formInfo.zip_code}
                            onChange={handleChange}
                            autoComplete="current-password"
                            
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <TextField
                            variant="outlined"
                            margin="normal"
                        
                            fullWidth
                            name="tax_id"
                            label="Tax ID"
                            id="property_tax_id"
                            value={formInfo.tax_id}
                            onChange={handleTaxIdChange}
                            autoComplete="current-password"
                            
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            variant="outlined"
                            margin="normal"
                        
                            fullWidth
                            name="sq_ft"
                            type="number"
                            InputProps={{ inputProps: { min: 0, max: 2147483647 } }}
                            label="Square Footage"
                            id="property_sq_ft"
                            value={formInfo.sq_ft}
                            onChange={handleChange}
                            autoComplete="current-password"
                            
                        />
                    </Grid>
                    <Grid item xs>

                    <InputLabel id="number-of-floors-select-label">Number of Floors</InputLabel>
                        <Select
                            variant='outlined' 
                            labelid="number-of-floors-select-label"
                            id="number-of-floors-select"
                            placeholder="Number of Floors"
                            fullWidth
                            displayEmpty
                            name="number_of_floors"
                            value={formInfo.number_of_floors}
                            onChange={handleChange}>   
                            {range(1, 51).map(num => 
                                <MenuItem key={num} value={num}>{num}</MenuItem>    
                                )}
                                <MenuItem key={"51+"} value={"51+"}>{"51+"}</MenuItem>    
                        </Select>
                        {/* <TextField
                            variant="outlined"
                            margin="normal"
                        
                            fullWidth
                            name="number_of_floors"
                            type="number"
                            InputProps={{inputProps: {max: 51}}}
                            label="Number of Floors"
                            id="property_number_of_floors"
                            value={formInfo.number_of_floors}
                            onChange={handleChange}
                            autoComplete="current-password"
                            
                        /> */}
                    </Grid>
                    <Grid item xs>
                        {/* <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="year_built"
                            type="number"
                            label="Year Built"
                            id="property_year_built"
                            value={formInfo.year_built}
                            onChange={handleChange}
                            autoComplete="current-password"
                            min={1800}
                        /> */}
                        <InputLabel id="year-built-type-select-label">Year Built</InputLabel>
                        <Select
                            variant='outlined' 
                            labelid="year-built-type-select-label"
                            id="year-built-type-select"
                            placeholder="Year built"
                            fullWidth
                            displayEmpty
                            name="year_built"
                            value={formInfo.year_built}
                            onChange={handleChange}>   
                            {range(1800, +new Date().getFullYear()).reverse().map(year => 
                                <MenuItem key={year} value={year}>{year}</MenuItem>    
                            )}
                        </Select>
                        
                    </Grid>

                </Grid>
                <Grid container spacing={4}>
                    <Grid item lg>
                        <InputLabel id="primary-use-type-select-label">Primary Use</InputLabel>
                        <Select
                            variant='outlined' 
                            labelid="primary-use-type-select-label"
                            id="primary-use-type-select"
                            fullWidth
                            displayEmpty
                            name="primary_use_id"
                            value={formInfo.primary_use_id}
                            onChange={handleChange}>
                            {EPFormData.primary_use_types.map(pu => 
                                <MenuItem key={pu.id} value={pu.id}>{pu.display_name}</MenuItem>    
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs>
                        <InputLabel id="primary-construction-type-select-label">Primary Construction Type</InputLabel>
                        <Select
                            variant='outlined' 
                            labelid="primary-construction-type-select-label"
                            id="primary-construction-type-select"
                            fullWidth
                            name="primary_construction_type_id"
                            value={formInfo.primary_construction_type_id}
                            onChange={handleChange}>
                            {EPFormData.primary_construction_types.map(pct => 
                                <MenuItem key={pct.id} value={pct.id}>{pct.display_name}</MenuItem>    
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs>
                        <InputLabel id="heating-energy-type-select-label">Heating Energy Type</InputLabel>
                        <Select
                            variant='outlined' 
                            labelid="heating-energy-type-select-label"
                            id="heating-energy-type-select"
                            name="heating_energy_type_id"
                            value={formInfo.heating_energy_type_id}
                            fullWidth
                            onChange={handleChange}>
                            {EPFormData.heating_energy_types.map(pct => 
                                <MenuItem key={pct.id} value={pct.id}>{pct.display_name}</MenuItem>    
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs>
                        <InputLabel id="cooling-energy-type-select-label">Cooling Energy Type</InputLabel>
                        <Select
                            variant='outlined'
                            labelid="cooling-energy-type-select-label"
                            id="cooling-energy-type-select"
                            name="cooling_energy_type_id"
                            value={formInfo.cooling_energy_type_id}
                            onChange={handleChange}
                            fullWidth>
                            {EPFormData.cooling_energy_types.map(pct => 
                                <MenuItem key={pct.id} value={pct.id}>{pct.display_name}</MenuItem>    
                            )}
                        </Select>
                    </Grid>
                    <Grid item xs>
                        <FormControlLabel 
                        control={<Checkbox
                            variant='outlined'
                            labelid="cooling-energy-type-select-label"
                            id="cooling-energy-type-select"
                            onChange={_ => setEnergystarEnabled(!energystarEnabled)}
                            checked={energystarEnabled} />}
                        label="Enable ENERGY STAR"
                        id="cooling-energy-type-select-label">ENERGY STAR Enabled</FormControlLabel>
                        
                        
                    </Grid>
                </Grid>
                {energystarEnabled && 
                <Grid container spacing={4}>
                     <Grid item xs>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            name="energystar_username"
                            label="ENERGY STAR username"
                            id="property_energystar_username"
                            value={esCreds.energystar_username}
                            onChange={handleEsCredChange}
                            autoComplete="current-password"
                            min={1800}
                        />
                    </Grid>
                    <Grid item xs>
                        <TextField
                            variant="outlined"
                            margin="normal"
                        
                            fullWidth
                            name="energystar_password"
                            label="Energystar Password"
                            id="property_energystar_password"
                            value={esCreds.energystar_password}
                            onChange={handleEsCredChange}
                            autoComplete="current-password"
                            
                        />
                    </Grid>

                </Grid>}

                <UtilityAccountsForm 
                    utilityCompanies={EPFormData.utility_companies}
                    accounts={accounts}
                    setAccounts={setAccounts}
                />
                <List>
                {uploadedFiles.map(file => 
                    <ListItem>
                        <ListItemText primary={file} />
                    </ListItem>
                )}
                </List>
                <Typography style={{marginBottom: '12px', display: 'block'}}variant='p'>Upload Documents (LOA, Bill Copies)</Typography>
                <TextField
                    fillWidth
                    style={{display: 'block'}}
                    name='upload-document'
                    type='file' 
                    onChange={onFileLoad} />


                <Divider />
                <Button
                    id="save-property-button" 
                    type="submit"
                    variant='contained'>
                        Submit
                </Button>
                
            </form>
        </div>
    )
}