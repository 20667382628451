import {Alert, Typography, Link} from "@material-ui/core"

export default function ConfirmEmail() {
    const alertStyles={
        width: 600,
        margin: '0 auto',
        marginTop: '4%'
    }

    return(
        <div className="confirm-email-cta">

            <Alert style={alertStyles} severity='success'>Account(s) Created!</Alert>
            <Typography variant='h6' style={{marginTop: 30, marginBottom: 10}}>
                Please confirm your email to begin onboarding properties. Check your email for instructions. 
            </Typography>
            <Typography variant='h7' style={{marginTop: 30, marginBottom: 10}}>
                After confirming, <Link href={`${process.env.REACT_APP_EP_ENV_URL.split("/api/get_rails_session").join("")}`}>login here.</Link>
            </Typography>
            
        </div>
    )
}