import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export default function PropertySelect({ properties, setSelectedProperty, selectedProperty }) {
  return (
    <div style={{ minWidth: '600px' }}>
      <Autocomplete
        options={properties}
        getOptionLabel={(option) => option.name}
        value={selectedProperty}
        onChange={(event, property) => setSelectedProperty(property)}
        renderInput={(params) => <TextField {...params} label="Select Property" variant="outlined" />}
      />
    </div>
  );
} 