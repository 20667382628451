import React, {useEffect, useState} from 'react'
import {Alert, Button, Typography, TextField, Select, MenuItem, Divider, Link} from "@material-ui/core"
import axiosAuth from "../utils/axiosWithAuth"
import Loader from "react-loader-spinner"
import CloudDownload from "@material-ui/icons/CloudDownload"
import FileDownload from 'js-file-download';
import moment from 'moment'

export default function SourceBillFiles() {
    const [properties, setProperties] = useState([])
    const [billIds, setBillIds] = useState("")
    const [fileLink, setFileLink] = useState("")
    const [error, setError] = useState("")
    const [delay, setDelay] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const [selectedProperty, setSelectedProperty] = useState(null)

    useEffect(_ => {
        axiosAuth().get("/admin/properties")
            .then(r => setProperties(r.data.properties))
            .catch(err => console.log(err))
    },[])

    function getBills(bill_ids) {
        setDelay(null)
        setError("")
        setFileLink("")
        axiosAuth().post("/admin/source_bill_files", {utility_bill_ids: bill_ids})
        .then(res => {
            if (res.data.delay) {
                console.log("here")
                const delayInMinutes = Math.ceil(bill_ids.length / 400)
                console.log(delayInMinutes)
                console.log(submitting)
                setDelay(!!res.data.delay ? moment().add(delayInMinutes, "minutes") : null)
                setTimeout(_ => {
                    setSubmitting(false)
                    setFileLink(res.data.downloadUrl)
                    setDelay(null)
                }, delayInMinutes * 60000)
            } else {
                setSubmitting(false)
                setFileLink(res.data.downloadUrl)
            }

        })
        .catch(e => {
            console.log(e)
            console.log(e.response)
            setSubmitting(false);
            setDelay(null)
            if (e.response && e.response.data && e.response.data.message) {
                setError(e.response.data.message)
            } else {
                setError("Something went wrong. The export failed.")
            }
        })
    }

    function submitForm(billIds) {
        setSubmitting(true)
        billIds = billIds.replace(/[^\d,]/g, "")
        billIds = billIds.split(",").map(Number)
        getBills(billIds)
    }

    return(
        <div style={{maxWidth: 1200, margin: "5px auto"}}>
            {/* <Typography variant="h4">Download by Property</Typography>
            <Select 
                label="Property"
                variant='standard'
                className='property-select'
                onChange={e => setSelectedProperty(properties.find(p => p.id === +e.target.value))} 
                value={selectedProperty ? selectedProperty.id : ""}>
                {properties.map(t => {
                    return <MenuItem key={"property-" + t.id} value={t.id}>{t.name}</MenuItem>
                })}
            </Select>
            <Button>Go</Button>
        <Divider /> */}
        <br/>
        <Typography variant="h4">Get Source Bills from Utility Bill Ids</Typography>
        <TextField
            InputLabelProps={{ shrink: true }}
            inputProps={{maxLength: 400000}}
            className="source-bill-id-input"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="body"
            label="Comma-Separated Utility Bill Ids"
            name="body"
            value={billIds}
            onChange={e => setBillIds(e.target.value)}
            multiline
            rows={5}
        />
        <Button disabled={submitting} variant="contained" onClick={_ => submitForm(billIds)}>Go</Button>
        {!!error && <Alert style={{marginTop: '10px'}} severity="error">{error}</Alert>}
        <div style={{display: 'flex', alignItems: 'center', justifyContent: "center", margin: '10px', transition: '.2s', opacity: submitting ? '1' : '0'}}>
            <Typography style={{marginRight: "10px"}}>Exporting...</Typography>
            <Loader
            type="Grid"
            color="orange"
            height={40}
            width={40} /> 
        </div>
        {!!delay && <Alert style={{marginTop: '10px'}} severity="info">This is a big download and may take a while. ⏰  ETA: {delay.format("LT")}</Alert>}

        <Link target="_blank" style={{transition: '.2s', opacity: !!fileLink ? '1' : '0', cursor: fileLink ? "pointer" : "default"}} href={fileLink || "#"}><Button variant="contained">Download<CloudDownload style={{marginLeft: 10}} /></Button></Link>
        
        </div>
        // Select dropdown with properties
        // textarea for comma-separated
    )
}