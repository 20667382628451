import axiosAuth from "../../../../utils/axiosWithAuth"
import { Button, Typography, TextField, InputLabel, Select, MenuItem, FormControl, IconButton, Tooltip, Link } from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import {Add, Launch} from "@material-ui/icons"
import {useState, useContext, useEffect} from "react";
import TextractContext from "../../../../contexts/TextractContext";
import UamTable from "./UamTable";

export default ({bill, setBill, formData, }) => {
  const [form, setForm] = useState({
    meter_num: bill.meter_num || "",
    property_id: null,
    energy_type_id: 1,
    energy_type_measurement_unit_id: 1,
    energy_type_demand_measurement_unit_id: 1
  })

  useEffect(_ => {
    console.log(form)
    const etmus = formData.etmuMap[form.energy_type_id]
    const dmus = (formData.dmuMap[form.energy_type_id] || [])
    console.log(formData.etmuMap[form.energy_type_id])
    if (!formData.properties.length || !etmus) return
    const etmuIds = etmus.map(etmu => etmu.id)
    const dmuIds = dmus.map(dmu => dmu.id)
    const newParams = {}
    let changes = false;
    if (!etmuIds.includes(form.energy_type_measurement_unit_id)) {
      newParams.energy_type_measurement_unit_id = etmuIds[0]
      changes = true
    } if (dmuIds.length && !dmuIds.includes(form.energy_type_demand_measurement_unit_id)) {
      newParams.energy_type_demand_measurement_unit_id = dmuIds[0]
    } if (!dmuIds.length && form.energy_type_demand_measurement_unit_id) {
      newParams.energy_type_demand_measurement_unit_id = dmuIds[0]
      changes = true

    }
    if (changes) setForm({...form, ...newParams})
    
   },[form])

  const {setSelectedTextractBill, uams, setUams} = useContext(TextractContext)
  const handleAdd = _ => {
    const newMeter = {
      ...form,
      utility_account_id: bill.utility_account_id
    }
    axiosAuth().post(`/admin/textract_bills/onboarding/utility_meter`, {textract_bill_id: bill.id, newMeter})
      .then(createResponse => {
        console.log("createResponse", createResponse)
        axiosAuth().post(`/admin/utility_companies/${bill.utility_company_id}/accounts/${bill.utility_account_id}/uams`)
        .then(uams => {
          console.log("list", uams)
          setUams(uams.data.uams)
          setSelectedTextractBill(createResponse.data.newBill)
        })
      })
      .catch(err => console.log(err.response))
  }

  const handleChange = e => setForm({...form, [e.target.name]: e.target.value})

  const handlePropertyChange = (e, newValue) => {
    console.log(newValue)
    setForm({...form, property_id: newValue.id})
  }

  return(

      <>
        <Typography variant="h3">Account Meter Connections</Typography>
        {uams && !!uams.length && <UamTable uams={uams} />}
        <div style={{marginTop: 30}}>
        
          <TextField 
            label="Meter Number"
            style={{width: 500}}
            value={form.meter_num}
            name="meter_num"
            onChange={handleChange}
          />
          <br/>
          <div className='textract-meter-form'>

          <Autocomplete
              options={[{name: "None", id: null}, ...formData.properties]}
              value={formData.property_id}
              onChange={handlePropertyChange}
              getOptionLabel={(option) => option.name}
              style={{ width: 300 }}
              filterOptions={(options, { inputValue }) =>{
              console.log(inputValue)
              console.log(options.filter(option =>
                option.name.toLowerCase().includes(inputValue.toLowerCase())
              ))
                return options.filter(option =>
                  option.name.toLowerCase().includes(inputValue.toLowerCase())
                )
              }}
              // renderInput={(params) => <TextField {...params} label="Utility" variant="outlined" />}

              renderInput={(params) => (
                  <TextField 
                      {...params}
                      label="Property"
                      variant="outlined"
                      InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                              <>
                                  {true && 
                                    <Tooltip title="View Property in EP" arrow>
                                      <IconButton color='primary'>
                                        <Link target="_blank" href={`${process.env.REACT_APP_EP_BASE_URL}/admin/properties/${form.property_id}/edit`}>
                                          <Launch style={{ color: "#fb8c00", cursor: 'pointer' }} />
                                        </Link>
                                      </IconButton>
                                    </Tooltip>
                                  }
                                  {params.InputProps.endAdornment}
                              </>
                          )
                      }}
                  />
              )}
          /> 

          <FormControl>
            <InputLabel>Energy Type</InputLabel>
            <Select
              name="energy_type_id"
              value={form.energy_type_id}
              onChange={handleChange}
            >
              {formData.energyTypes.map((energyType) => (
                <MenuItem value={energyType.id} key={energyType.id}>
                  {energyType.display_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel>ETMU Map</InputLabel>
            <Select
              value={form.energy_type_measurement_unit_id}
              onChange={(e) => setForm({ ...form, energy_type_measurement_unit_id: e.target.value })}
            >
              {(formData.etmuMap[form.energy_type_id] || []).map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {!!formData.dmuMap[form.energy_type_id] && <FormControl>
            <InputLabel>DMU Map</InputLabel>
            <Select
              value={form.energy_type_demand_measurement_unit_id}
              onChange={(e) => setForm({ ...form, energy_type_demand_measurement_unit_id: e.target.value })}
            >
              {(formData.dmuMap[form.energy_type_id] || []).map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>}
          </div>
          
          <br/>
          <Button onClick={handleAdd} variant="contained" disabled={!form.property_id || !bill.utility_company_id || !bill.utility_account_id} color='primary'> Create Meter & Account Meter +</Button>
        </div>
      </>
      
  )
}