import React, { useContext, useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import UserContext from '../contexts/UserContext';
import { createMuiTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import NightModeToggle from './NightModeToggle';
const useStyles = makeStyles({
  colorPickerWrapper: {
    position: 'relative',
  },
  colorPicker: {
    position: 'absolute',
    zIndex: 9999999999999, // Ensure it floats above other content
    marginTop: '10px', // Small gap between the button and the color picker
  },
});

function ColorPicker() {
  const classes = useStyles();
  const {activeTheme, setActiveTheme} = useContext(UserContext)
  const [color, setColor] = useState(activeTheme.palette.primary.main);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  useEffect(() => {
    const savedColor = localStorage.getItem('theme-color');
    if (savedColor) {
      setColor(savedColor);
      setActiveTheme({
        ...activeTheme,
        palette: {
          ...activeTheme.palette,
          primary: {
            main: savedColor,
          },
        },
      });
    }
  }, []);

  const handleColorChange = (color) => {
    setColor(color.hex);
    console.log(color.hex)
    localStorage.setItem('theme-color', color.hex);

    setActiveTheme({
      ...activeTheme,
      palette: {
        ...activeTheme.palette,
        primary: {
          main: color.hex,
        },
      },
    });
  };

  const handleDarkModeToggle = (mode) => {

    localStorage.setItem('dark-mode', mode);

    setActiveTheme({
      ...activeTheme,
      palette: {
        ...activeTheme.palette,
        mode: mode,
      }
    });
  };

  return ( 
    <>
    <div>
      <Button style={{marginTop: 5, color: 'white'}}  onClick={() => setDisplayColorPicker(!displayColorPicker)}>
        {displayColorPicker ? "Close X" : "Pick Color"}
      </Button>
      <div>

        {displayColorPicker ? (
          <SketchPicker
          color={color}
          onChangeComplete={handleColorChange}
          />
          ) : null}
      </div>
    </div>
    <NightModeToggle activeTheme={activeTheme} handleDarkModeToggle={handleDarkModeToggle} />
    </>
  );
}

export default ColorPicker;