import axiosAuth from "../utils/axiosWithAuth";
import React, { createContext, useContext, useState } from 'react';

const AccountContext = createContext({
  markAsChecked: () => console.warn("markAsChecked not provided"),
  fetchAccounts: () => console.warn("fetchAccounts not provided")
});

export const useIntakeAccounts = () => useContext(AccountContext);

export const IntakeAccountProvider = ({ children }) => {
  const [accounts, setAccounts] = useState([]);

  const fetchAccounts = (params, cb) => {
    const {sources, filter, order} = params

    axiosAuth().post('/admin/scraped_bill_queue/', {sources, filter, order})
    .then(r => {
      setAccounts(r.data.results)
      if (cb) cb()
    })
    .catch(e => console.log(e))
  }


  const updateAccount = (account, key, value) => {
    setAccounts(prevState => {
      const unchangedAccounts = prevState.filter(acc => acc.id != account.id)
      const changedAccount = {
        ...account,
        [key]: value
      }
      return [...unchangedAccounts, changedAccount]
    })
  }
  // Scraped Account API actions
  const markAsChecked = (account, fetchParams, cb) => {
    axiosAuth().put(`/admin/scraped_bill_queue/${account.id}/mark_as_checked`)
      .then(r => {
        console.log(r);
        fetchAccounts(fetchParams, cb)
      })
      .catch(e => console.log(e));
  };

  const handleFollowUp = (account, follow_up_date, open=false, fetchParams, cb) => {
    axiosAuth().put(`/admin/scraped_bill_queue/${account.id}/set_follow_up`, { follow_up_date })
      .then(r => {
        fetchAccounts(fetchParams, cb)
      })
      .catch(e => console.log(e));
  };

  // Package the state and functions for context consumers
  const value = {
    accounts,
    setAccounts,
    markAsChecked,
    handleFollowUp,
    fetchAccounts
  };

  return <AccountContext.Provider value={value}>{children}</AccountContext.Provider>;
};