import React, {useState, useEffect} from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Typography, useTheme } from '@material-ui/core';


const UNMAPPED_OPTION ={ id: 'unmapped', name: 'Unmapped' }
const ALL_UTILITIES_OPTION ={ id: 'all', name: 'All Utilities' }


export default function FilterCompanySelect({ filters, handleFilterChange, utilities }) {
  const theme = useTheme();
  const highlightColor = theme.palette.mode === 'light' ? '#DAA520aa' : '#d88428aa';
  const [seletedUtility, setSelectedUtility] = useState(null)
  const handleChange = (event, newValue) => {
    const fakeEvent = {
      target: {
        name: 'utility_company_id',
        value: newValue ? newValue.id : 'all',
      },
    };
    handleFilterChange(fakeEvent);
  };

  const getValueFromFilters = (filters) => {
    return filters.utility_company_id == 'all' 
    ? ALL_UTILITIES_OPTION 
    : filters.utility_company_id == 'unmapped' 
      ? UNMAPPED_OPTION 
      : utilities.find(uc => uc.id === filters.utility_company_id)
  }

  useEffect(_ => { 
    if (utilities) setSelectedUtility(getValueFromFilters(filters))
  }, [filters, utilities])

  return (
    <Autocomplete
      id="utility-select"
      options={[ALL_UTILITIES_OPTION, UNMAPPED_OPTION, ...utilities]}
      getOptionLabel={(option) => `${option.name}${option.unprocessedCount ? ` (${option.unprocessedCount})` : ""}`}
      style={{ width: 250 }}
      renderOption={(option) => (
        <li style={{ 
          backgroundColor: option.unprocessedCount ? highlightColor : '',
          fontWeight: option.unprocessedCount ? "bold" : '',
          padding: '10px',
          margin: '-6px',
          marginLeft: "-15px",
          marginRight: "-20px",
          width: '207px',
        }}>
          {/* <Typography noWrap> */}
            {option.name}{option.unprocessedCount ? ` (${option.unprocessedCount})` : ""}
          {/* </Typography> */}
        </li>
      )}
      value={seletedUtility}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label="Utility" variant="outlined" />
      )}
    />
  );
}
