import {useContext} from "react"
import {Autocomplete, TextField, Tooltip, IconButton} from "@material-ui/core"
import TextractContext from "../../../../contexts/TextractContext";
import LaunchIcon from "@material-ui/icons/Launch"
import axiosAuth from "../../../../utils/axiosWithAuth";
import InvoyageTooltip from "../InvoyageTooltip";

const AccountSelect = ({isDisabled}) => {
    const {selectedAccount, accounts, setSelectedAccount, selectedTextractBill, setSelectedTextractBill} = useContext(TextractContext)
    const handleChange = (e, newValue) => {
        axiosAuth().put(`/admin/textract_bills/${selectedTextractBill.id}`, {utility_account_id: newValue ? newValue.id : null})
            .then(console.log)
            .catch(console.error)
        setSelectedAccount(newValue ? accounts.find(ua => ua.id == newValue.id) : null)
        setSelectedTextractBill({...selectedTextractBill, utility_account_id: newValue ? newValue.id : null })
    }

    const navigateToResource = account => {
        window.open(`${process.env.REACT_APP_EP_BASE_URL}/admin/utility_accounts/${account.id}/edit`)
    }

    return (
        <Autocomplete
            id="combo-box-demo"
            options={[{acct_num: "None", id: null}, ...accounts]}
            disabled={isDisabled}
            value={selectedAccount}
            onChange={handleChange}
            getOptionLabel={(option) => option.acct_num}
            style={{ width: 300 }}
            renderInput={(params) => (
                <TextField 
                    {...params}
                    label="Account"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {selectedAccount && 
                                    <InvoyageTooltip title="View Account in EP" arrow>
                                        <IconButton color='primary'>
                                            <LaunchIcon style={{ cursor: 'pointer' }} onClick={() => navigateToResource(selectedAccount)} />
                                        </IconButton>
                                    </InvoyageTooltip>
                                }
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
            // renderInput={(params) => <TextField {...params} label="Account" variant="outlined" />}
            
        />  
    )   
}
export default AccountSelect;