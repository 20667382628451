import { useState, useEffect, useContext } from "react"
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Typography, Button } from "@material-ui/core"
import PortfolioIcon from "./PortfolioIcon"
import PortfolioIndex from "./PortfolioIndex"
import PortfolioList from "./PortfolioList"
import PropertyEntry from "./PropertyEntry"
import axiosAuth from "../../utils/axiosWithAuth"
import BillingPortfolioContext from "../../contexts/BillingPortfolioContext"

export default function BillingPortfolios({match, history}) {

  const [companyId, setCompanyId] = useState(match.params.id)
  const [portfolios, setPortfolios] = useState([])
  const [openPortfolios, setOpenPortfolios] = useState([])
  const pushBack = _ => history.push("/billing_portfolios")
  useEffect(_ => {
    axiosAuth().get(`/admin/billing_property_groups/${companyId}`)
      .then(r => {
        console.log(r)
        setPortfolios(r.data.portfolios) 
        setOpenPortfolios([r.data.portfolios[0].bpg_id])
      })
      .catch(console.error)
  },[])

  return (
    <BillingPortfolioContext.Provider value={{companyId, openPortfolios, setOpenPortfolios}}>
      <div>
        
        <Typography style={{margin: 20}} variant='h3'><Button onClick={pushBack} style={{marginRight: 30}} variant="outlined">◀&nbsp;Back</Button>Manage Billing Portfolios</Typography>
        <DndProvider backend={HTML5Backend}>
          <div className="billing-portfolio-manager" style={{display: "flex"}} >
            <PortfolioIndex setPortfolios={setPortfolios} companyId={companyId} portfolios={portfolios} />
            <div className="portfolio-wrapper" style={{display: "flex", flexFlow: "wrap"}}>
              {portfolios.map(p => 
                <div key={p.bpg_id}>
                {openPortfolios.includes(p.bpg_id) 
                  && <PortfolioList key={p.bpg_id} portfolio={p} setPortfolios={setPortfolios} /> 
                }
                </div>
              )}
            </div>
          </div>
        </DndProvider>
      </div>
    </BillingPortfolioContext.Provider>
  )
}