import Loader from "react-loader-spinner";
import { useContext } from "react";
import UserContext from "../contexts/UserContext";

export default function PrimaryColorLoader({height, width, loaderType, style}) {
  const {activeTheme} = useContext(UserContext)
  return <Loader
    style={{marginTop: 8, display: 'inline-block', ...(style || {})}}
    color={activeTheme.palette.primary.main}
    type={loaderType || "Oval"}
    height={height || 32}
    width={width || 32} 
  /> 
}