import React from 'react';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import InvoyageTooltip from './InvoyageTooltip';

function ClearButton({handleClear}) {
  return (
    <InvoyageTooltip title={`Remove all Line Items`}>
      <Button
        variant="outlined"
        startIcon={<ClearIcon />}
        onClick={handleClear}
      >
        Clear
      </Button>
    </InvoyageTooltip>
  );
}

export default ClearButton;