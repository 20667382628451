import React, {useState, useEffect,useContext, useRef} from 'react';
import WidgetContext  from '../../../contexts/WidgetContext';
import axiosAuth from "../../axiosWithAuth";
import Loader from "react-loader-spinner";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {FormControl, InputLabel, NativeSelect, Grid, Button, Tabs, Tab, ListSubheader, MenuItem, Select, makeStyles } from "@material-ui/core"
import UserContext from '../../../contexts/UserContext';
import { abbreviateCommaNumber } from '../../../components/helpers/string-helpers';
import PrimaryColorLoader from '../../../components/PrimaryColorLoader';
import {callFunctionAtInterval} from "../../../components/helpers/interval-helpers"
import { deepMerge } from '../../../components/helpers/object-helpers';
import {debounce} from 'lodash'
import CostAvoidanceNav from './CostAvoidanceNav';
import CostAvoidanceDataTable from './CostAvoidanceDataTable';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  interaction: {
    mode: 'x'
  },
  responsive: true,
  
  hover: {
    mode: 'index',
    intersect: false
  },
  plugins: {
    legend: {
      position: 'top',
      display: false,
    },
    title: {
      display: true,
      text: 'Property Details',
    },
    tooltip: {
      titleFont: {
        size: 24,
        weight: 'bold'
      },
      bodyFont: {
        size: 20
      },
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function({ dataset, parsed }) {
          const { label = '', measurementUnit = 'kbtus' } = dataset;
          let result = label ? ` ${label}: ` : '';
          if (parsed.y !== null) {
            result += `${parseInt(parsed.y).toLocaleString()} ${measurementUnit}`;
          }
          return result;
        }
      },
      itemSort: function(a, b) {
        return b.raw - a.raw;
      },
    }
  },
  scales: {
    x: {
      ticks: {
        display: false, // This hides the labels on the x-axis
      },
      grid: {
        drawTicks: false, // This removes the tick marks below the x-axis
      },
    },
    y: {
        scaleLabel: {
            display: true,
            labelString: 'Total kbtu'
        },
      ticks: {
          beginAtZero: true,
          callback: function numberWithCommas(x) {
            return abbreviateCommaNumber(parseInt(x));
        }
      }
    },
  }     
};

const currencyOptions = {
  scales: {
    ...options.scales,
    y: {
      scaleLabel: {
        display: true,
        labelString: "Total Cost",
      },
      ticks: {
        beginAtZero: true,
        callback: function numberWithCommas(x) {
          return "$" + abbreviateCommaNumber(parseInt(x));
        }
      }
    }
  },
  plugins: {
    tooltip: {
      ...options.plugins.tooltip,
      callbacks: {
        label: function(context) {
          let label = " " + context.dataset.label || '';
  
          if (label) {
              label += ': ';
          }
          const y = context.parsed.y
          if (y !== null) {
              const currencyFormattingOptions = { style: 'currency', currency: 'USD' }
              if (y < 1) {
                currencyFormattingOptions.minimumSignificantDigits = 2
                currencyFormattingOptions.maximumSignificantDigits = 3
              } 
              label += new Intl.NumberFormat('en-US', currencyFormattingOptions).format(y);
          }
          return label;
        }
      },
    },
  }
}

const darkModeOptions = {
  scales: {
    x: {

      ticks: {
        color: "#fff",
      },
      grid: {
        color: "#515151",
      },
    },
    y: {
      ticks: {
        color: "#fff",
      },
      grid: {
        color: "#515151",
      },
    },
  }
}
// labels are all the months
const labels = ['INITIAL'];

export const INITIAL_DATA = {
  labels,
  initial: true,
  datasets: [
    {
      label: 'Dataset 1',
      data: [22,33,44,55,66,77,88],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }
  ],
};

const MOBILE_BREAKPOINT = 700

const useStyles = makeStyles((theme) => ({
  menuItem: {
    whiteSpace: 'normal',  // Ensures normal wrapping behavior
    wordWrap: 'break-word',  // Allows words to break onto new lines
    maxWidth: '600px',  // Optional: Set a max width to control wrapping
  },
}));

export default function CostAvoidanceChart({projectId, sideNav, propertySelect, includePropertyName, includeFYSelect, sendHeight, includeTable, includeChart, fillScreen, slideShow}) {
    const {activeTheme} = useContext(UserContext)
    const classes = useStyles()
    const [selectedProjectId, setSelectedProjectid] = useState(4929)
    const [selectOpenStates, setSelectOpenStates] = useState(Array(3).fill(false));
    const {windowWidth, properties, setProperties, widgetOptions} = useContext(WidgetContext)
    const [selectedProject, setSelectedProject] = useState(null)
    const [projects, setProjects] = useState([])
    const [energyTypes, setEnergyTypes] = useState([])
    const [activeData, setActiveData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [chartData, setChartData] = useState(null)
    const [energyType, setEnergyType] = useState("all-energy")
    const [sideNavTabIndex, setSideNavTabIndex] = useState(0)
    const [loadingChart, setLoadingChart] = useState(false)
    const [activeOptions, setActiveOptions] = useState(options)
    const [groupedEnergyTypes, setGroupedEnergyTypes] = useState([<ListSubheader>Loading...</ListSubheader>])
    const [energyTypeValues, setEnergyTypeValues] = useState([])
    const sideNavTabOptions = [0,1,2,3].filter(n => widgetOptions.includeCosts == false 
      ? n % 2 != 1 
      : widgetOptions.includeUsage  == false
                                                    ? n % 2 != 0 
                                                    : true
                                                )
    const selectRefs = useRef([]);
    const sideNavTabIndexRef = useRef(sideNavTabIndex);
    

    useEffect(() => {
      sideNavTabIndexRef.current = sideNavTabIndex;
    }, [sideNavTabIndex]);

    useEffect(() => {
      let intervalId;
      if (slideShow && energyTypeValues.length && properties.length) {
        intervalId = setInterval(() => advanceSlideShow(), 4000);
      }
      return () => clearInterval(intervalId);
    }, [slideShow])

    const advanceSlideShow = (options, energyType, sideNavTabIndex) => {
      // TODO
    }
    

    const addSelectRef = (ref, index) => {
      if (ref && !selectRefs.current.includes(ref)) {
          selectRefs.current[index] = ref;
      }
    };

    const handleCloseDropdowns = () => {
      setSelectOpenStates(states => states.map(() => false));
    };

    useEffect(() => {
      const handleMessage = (event) => {
          if (event.data === 'closeDropdown') {
            console.log("closing dropdowns")
            console.log(selectOpenStates)
              handleCloseDropdowns()
          }
      };

      window.addEventListener('message', handleMessage);

      return () => {
          window.removeEventListener('message', handleMessage);
      };
  }, []);
    
    const showTable = _ => includeTable != false
    const showChart = _ => includeChart != false

    useEffect(() => {
      setActiveOptions(() => {
        const activeDarkModeOptions = activeTheme.palette.mode == "dark" ? darkModeOptions : {}
        const newOptionsRoot = {...options}
        if (fillScreen) {
          newOptionsRoot.maintainAspectRatio = false
        }
        if (sideNavTabIndex === 0) {
          setActiveData()

        }
      })
    }, [sideNavTabIndex, chartData, activeTheme])

    useEffect(_ => {
      if (slideShow) {
        // todo - reset view on project change
      }
    }, [selectedProjectId])

    useEffect(_ => {
      const fetchData = () => {
        const {selectedPropertyIds} = widgetOptions
        if (!projects.length) {
          // get Ca-projects
        }
        setLoadingChart(true)
        if (!selectedProjectId) return
        axiosAuth().get(`/cost_avoidance_projects/${selectedProjectId}?energy_type_id=1`)
            .then(r => {
                console.log(r)
                const {labels, chartData } = r.data

                setChartData(chartData)


                setLoading(false)
                setLoadingChart(false)
                callFunctionAtInterval(sendHeight, 200, 1000)

            })
            .catch(err => {
              // handle error
              // show error message
            })
      };

      const debouncedFetchData = debounce(fetchData, 100);
      debouncedFetchData();
    }, [energyType, selectedProjectId])


  return <>
      {loading
          ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 500}}>
            <Loader
              type="Oval"
              color="orange"
              height={40}
              width={40} />
            </div>
          : 
          <div>
            <div style={{margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              {projects.length == 1 || slideShow ||  <h1 style={{display: 'inline-block', marginRight: 15}} align='center'>{"PLACEHOLDER" || selectedProject.name}</h1>}
              <div className="filter-form">
                <span>Form filter here</span>
               
                <div style={{width: 24}}>
                  {loadingChart &&
                    <div className="ca-loader">
                      <PrimaryColorLoader />
                    </div>
                  }
                </div>
              </div>
            </div>

            {windowWidth > MOBILE_BREAKPOINT && 
              <div class='horizontal-nav-container'>
                <CostAvoidanceNav
                  sideNavTabIndex={sideNavTabIndex}
                  energyType={energyType}
                  setSideNavTabIndex={setSideNavTabIndex}
                  setLoadingChart={setLoadingChart}
                  orientation={"horizontal"}
                />
            </div>
            }

            
            <div class="ca-flex-nav-container">
              {sideNav && <div class="ca-side-nav">
                <CostAvoidanceNav
                  sideNavTabIndex={sideNavTabIndex}
                  energyType={energyType}
                  setSideNavTabIndex={setSideNavTabIndex}
                  setLoadingChart={setLoadingChart}
                />

            </div>}
            </div>
            <div class='ca-content'>
              {showChart() && <div style={{
                  width: '100%',
                  height: fillScreen ? 'calc(100vh - 420px)' : 'default', // Adjust based on the remaining components' height
              }}>
                 <Line options={activeOptions} data={activeData} />
              </div>}
                
                { showTable() && <div style={{marginRight: 4}}>
                  <CostAvoidanceDataTable 
                    project={selectedProject}
                    energyType={energyType}
                    projectId={projectId}
                    activeTheme={activeTheme}
                    loadingChart={loadingChart}
                    data={activeData} 
                    chartData={chartData}
                  />
                </div>}
            </div>
          </div>
            
      }
    </>
}