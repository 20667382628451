import {Checkbox} from "@material-ui/core"
import {useEffect} from "react"

export default function UamSelect({li, liIndex, liType, setGptBill, disabled}) {
    const handleApplicableChange = e => {
        const checked = e.target.checked;
        setGptBill(prevState => {
            if (liType == "charge") {
                const newCharges = [...prevState.charges];
                const liIndex= newCharges.findIndex(c => c.id == li.id)
                newCharges[liIndex].applicable = checked;
                return { ...prevState, charges: newCharges };
                
            } else {
                const newUsages = [...prevState.usages];
                const liIndex= newUsages.findIndex(u => u.id == li.id)
                newUsages[liIndex].applicable = checked;
                return { ...prevState, usages: newUsages };
            }
        });
    }

    useEffect(_ => {
        handleApplicableChange({target: {checked: li.applicable == undefined ? true : !!li.applicable}})
    },[])
    return (
        <Checkbox
            disabled={disabled}
            checked={!!li.applicable}
            onChange={handleApplicableChange}
            color="primary"
            tabIndex="-1"
        />
    )
}