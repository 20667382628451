import {useEffect, useState, useContext} from 'react'
import {Route, useLocation, useParams} from 'react-router-dom'
import EnergyPrint from "../../utils/ep-components/EnergyPrint"
import YearOverYearChartContainer from '../../utils/ep-components/YearOverYearChart/YearOverYearChartContainer'
import {tokenIsExpired} from "../helpers/token-helpers"
import "./Widgets.scss"
import WidgetContext from '../../contexts/WidgetContext'
import UserContext from '../../contexts/UserContext'
import Chat from "./chat/Chat"
import { callFunctionAtInterval } from '../helpers/interval-helpers';
import CostAvoidanceChartContainer from '../../utils/ep-components/CostAvoidance/CostAvoidanceContainer'
import ChartTester from './ChartTester';

export default function Widgets() {
    const { search } = useLocation();
    const queryParams = Object.fromEntries(new URLSearchParams(search).entries());

    const [properties, setProperties] = useState([])
    const [selectedProperty, setSelectedProperty] = useState(null)
    const [selectedProperties, setSelectedProperties] = useState([])
    const [modeString, setModeString] = useState("")
    const [openModal, setOpenModal] = useState(false);
    const {widgetOptions, setActiveTheme, activeTheme} = useContext(UserContext)
  
    const handleOpenModal = () => {
        setOpenModal(true)
        callFunctionAtInterval(sendHeight, 200, 1000)
    };
    const handleCloseModal = () => {
        setOpenModal(false)
        callFunctionAtInterval(sendHeight, 200, 1000)
    };
  
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const themesAreDifferent = (theme1, theme2) => {
        return !(theme1.palette.mode == theme2.palette.mode && 
        theme1.palette.primary.main == theme2.palette.primary.main &&
        theme1.palette.secondary.main == theme2.palette.secondary.main)
    }

    useEffect( _ => {
        const primaryColor = widgetOptions.primaryColor || queryParams.primaryColor
        const darkMode = widgetOptions.darkMode || queryParams.darkMode
        const newTheme = {...activeTheme}
        if (darkMode && ["dark", "light"].includes(darkMode)) {
            newTheme.palette.mode = darkMode
            localStorage.setItem("dark-mode", darkMode)
        }
        if (primaryColor && /^[0-9a-f]{3}|[0-9a-f]{6}$/i.test(primaryColor)) {
            const primaryColorHex = `#${primaryColor.replace("#", "")}`
            newTheme.palette.primary.main = primaryColorHex
            localStorage.setItem("theme-color", primaryColorHex)
            newTheme.palette.secondary.main = primaryColorHex
        }
        if (themesAreDifferent(newTheme, activeTheme)) {
            setActiveTheme(newTheme)
        }
    }, [queryParams, widgetOptions, activeTheme])
    
    const sendHeight = () => {
        const height = document.body.scrollHeight;
        console.log("Sending height of ", height)
        window.parent.postMessage(height, "*"); // Make sure to replace with your actual domain
    }

    useEffect( _ => {
        const urlJwt = new URLSearchParams(search).get('jwt')
        if (urlJwt && (!localStorage.getItem('jwt') || tokenIsExpired())) { 
            localStorage.setItem("jwt", urlJwt)
            window.location.reload()
        }

        sendHeight();
    
        // Optional: Send height on window resize
        window.addEventListener('resize', sendHeight);
        return () => window.removeEventListener('resize', sendHeight);
        
    }, [])
    return (
        <WidgetContext.Provider value={{windowWidth, setWindowWidth, modeString, setModeString, properties, setProperties, selectedProperties, setSelectedProperties, openModal, handleOpenModal, handleCloseModal, widgetOptions, queryParams, selectedProperty, setSelectedProperty}}>
            <div className="widget-container" style={{minHeight: queryParams.embed ? undefined : '100vh'}}>
                <Route path='/widgets/intervalchart' component={EnergyPrint} />
                <Route exact path='/widgets/yearoveryear' render={_ => <YearOverYearChartContainer widgetOptions={widgetOptions} sendHeight={sendHeight} showPropertySelect={true} />} />
                <Route path='/widgets/:id/yearoveryear' render={_ => <YearOverYearChartContainer widgetOptions={widgetOptions} sendHeight={sendHeight} showPropertySelect={false} />} />
                <Route exact path='/widgets/cost_avoidance' render={_ => <CostAvoidanceChartContainer widgetOptions={widgetOptions} sendHeight={sendHeight} />} />
                <Route path='/widgets/:id/cost_avoidance' render={_ => <CostAvoidanceChartContainer widgetOptions={widgetOptions} sendHeight={sendHeight} />} />
                <Route path='/widgets/chat' render={_ => <Chat widgetOptions={widgetOptions} sendHeight={sendHeight} />} />
                <Route path='/widgets/testchart' render={_ => <ChartTester widgetOptions={widgetOptions} sendHeight={sendHeight} />} />
            </div>
        </WidgetContext.Provider>
    )
}