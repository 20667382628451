export function getDateInOneMonth() {
    let d = new Date()
    d.setMonth(d.getMonth() + 1)
    return d.toLocaleString().split(",")[0]
}

export function getDateInTwelveMonths() {
    let d = new Date()
    d.setFullYear(d.getFullYear() + 1)
    return d.toLocaleString().split(",")[0]
}

export function olderThanDays(date, days) {
    const oneDayInMs = 1000 * 60 * 60 * 24;
    return (new Date() - new Date(date)) > (oneDayInMs * days)
}

export function isDue(request) {
    let delivery_method = request.delivery_method || ""
    let cadence = 35
    if (/bi-monthly/i.test(delivery_method)) {
        cadence = 70
    }
    if (/quarterly/i.test(delivery_method)) {
        cadence = 100
    }
    if (/bi-annual/i.test(delivery_method)) {
        cadence = 185
    }
    if (/annual/i.test(delivery_method)) {
        cadence = 365
    }
    return olderThanDays(request.latest_invoice_date, cadence)
}

export function getLaterDate(d1, d2) {
    if (!d1 && !d2) return null
    if (!d1) return d2
    if (!d2) return d1
    if (new Date(d1) > new Date(d2)) {
        return d1
    } 
    return d2
}

export const requestHasAlarm = request => {
    return  (request.follow_up_date && new Date() > new Date(request.follow_up_date)) ||
            ((request.last_received_date || request.last_sent_date) && olderThanDays( getLaterDate(request.last_sent_date, request.last_received_date), 14) && isDue(request))
}

export function getFormattedDate(date) {
    date = new Date(date)
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
  }

  export function getFormattedDateUTC(date) {
    date = new Date(date);
    var year = date.getUTCFullYear();
  
    var month = (1 + date.getUTCMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getUTCDate().toString();
    day = day.length > 1 ? day : '0' + day;
  
    return month + '/' + day + '/' + year;
  }

  export function getFormattedDateTZ(date) {
    var d = new Date(date)
    if (!date || d == "Invalid Date") return ""
    
    d.setDate(d.getDate() + 1)

    return getFormattedDate(d)
  }

  export function parseYYYYMMDDToLocaleString(yyyyMMdd) {
    const year = yyyyMMdd.substring(0, 4)
    const month = yyyyMMdd.substring(4, 6)
    const day = yyyyMMdd.substring(6, 8)
    return `${month}/${day}/${year}`;
  }

export function getDaysArray(startDate, endDate) {
    const dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        // Push a copy of currentDate converted to UTC
        dateArray.push(new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate())));
        
        // Increment the date by adding 1 day
        currentDate.setUTCDate(currentDate.getUTCDate() + 1);
    }

    return dateArray;
}