import Markdown from 'markdown-to-jsx'
import ChatBadge from './ChatBadge';
import PrimaryColorLoader from '../../PrimaryColorLoader';

export default function MessageList({messages, messagesEndRef, loadingReply}) {
  return (
    <div style={{ flexGrow: 1, overflowY: 'auto', display: 'flex', flexDirection: 'column-reverse' }}>
      <div ref={messagesEndRef} />
      {loadingReply && <PrimaryColorLoader loaderType="Grid" style={{margin: '5px auto'}} />}
      <div  style={{ display: 'flex', flexDirection: 'column' }}>
        {messages.map((message, idx) => (
          !!idx && <div className='chat-message' key={idx} style={{
            textAlign: message.role === 'user' ? 'right' : 'left',
            border: '1px solid #ddd',
            margin: '5px',
            padding: '10px',
            paddingTop: '5px',
            borderRadius: '10px',
            backgroundColor: message.role === 'user' ? '#f0f0f0' : '#e0e0e0'
          }}>
            <ChatBadge message={message} />
            <Markdown>
              {message.content}
            </Markdown>
          </div>
        ))}
      </div>
      
    </div>
  )
}