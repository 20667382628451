import React, {useState, useEffect,useContext, useRef} from 'react';
import WidgetContext  from '../../../contexts/WidgetContext';
import axiosAuth from "../../axiosWithAuth";
import Loader from "react-loader-spinner";
import YoYDataTable from "./YoyDataGrid";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import {FormControl, InputLabel, NativeSelect, Grid, Button, Tabs, Tab, ListSubheader, MenuItem, Select, makeStyles } from "@material-ui/core"
import UserContext from '../../../contexts/UserContext';
import { abbreviateCommaNumber } from '../../../components/helpers/string-helpers';
import YoYNav from './YoYNav';
import PrimaryColorLoader from '../../../components/PrimaryColorLoader';
import {callFunctionAtInterval} from "../../../components/helpers/interval-helpers"
import { deepMerge } from '../../../components/helpers/object-helpers';
import {debounce} from 'lodash'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  interaction: {
    mode: 'x'
  },
  responsive: true,
  
  hover: {
    mode: 'index',
    intersect: false
  },
  plugins: {
    legend: {
      position: 'top',
      display: false,
    },
    title: {
      display: true,
      text: 'Property Details',
    },
    tooltip: {
      titleFont: {
        size: 24,
        weight: 'bold'
      },
      bodyFont: {
        size: 20
      },
      mode: 'index',
      intersect: false,
      callbacks: {
        label: function({ dataset, parsed }) {
          const { label = '', measurementUnit = 'kbtus' } = dataset;
          let result = label ? ` ${label}: ` : '';
          if (parsed.y !== null) {
            result += `${parseInt(parsed.y).toLocaleString()} ${measurementUnit}`;
          }
          return result;
        }
      },
      itemSort: function(a, b) {
        return b.raw - a.raw;
      },
    }
  },
  scales: {
    x: {
      ticks: {
        display: false, // This hides the labels on the x-axis
      },
      grid: {
        drawTicks: false, // This removes the tick marks below the x-axis
      },
    },
    y: {
        scaleLabel: {
            display: true,
            labelString: 'Total kbtu'
        },
      ticks: {
          beginAtZero: true,
          callback: function numberWithCommas(x) {
            return abbreviateCommaNumber(parseInt(x));
        }
      }
    },
  }     
};

const currencyOptions = {
  scales: {
    ...options.scales,
    y: {
      scaleLabel: {
        display: true,
        labelString: "Total Cost",
      },
      ticks: {
        beginAtZero: true,
        callback: function numberWithCommas(x) {
          return "$" + abbreviateCommaNumber(parseInt(x));
        }
      }
    }
  },
  plugins: {
    tooltip: {
      ...options.plugins.tooltip,
      callbacks: {
        label: function(context) {
          let label = " " + context.dataset.label || '';
  
          if (label) {
              label += ': ';
          }
          const y = context.parsed.y
          if (y !== null) {
              const currencyFormattingOptions = { style: 'currency', currency: 'USD' }
              if (y < 1) {
                currencyFormattingOptions.minimumSignificantDigits = 2
                currencyFormattingOptions.maximumSignificantDigits = 3
              } 
              label += new Intl.NumberFormat('en-US', currencyFormattingOptions).format(y);
          }
          return label;
        }
      },
    },
  }
}

const darkModeOptions = {
  scales: {
    x: {

      ticks: {
        color: "#fff",
      },
      grid: {
        color: "#515151",
      },
    },
    y: {
      ticks: {
        color: "#fff",
      },
      grid: {
        color: "#515151",
      },
    },
  }
}
// labels are all the months
const labels = ['INITIAL'];

export const INITIAL_DATA = {
  labels,
  initial: true,
  datasets: [
    {
      label: 'Dataset 1',
      data: [22,33,44,55,66,77,88],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }
  ],
};

const MOBILE_BREAKPOINT = 700

const useStyles = makeStyles((theme) => ({
  menuItem: {
    whiteSpace: 'normal',  // Ensures normal wrapping behavior
    wordWrap: 'break-word',  // Allows words to break onto new lines
    maxWidth: '600px',  // Optional: Set a max width to control wrapping
  },
}));

export default function YearOverYearChart({propertyId, sideNav, propertySelect, includePropertyName, includeFYSelect, sendHeight, includeTable, includeChart, fillScreen, slideShow}) {
    const {activeTheme} = useContext(UserContext)
    const classes = useStyles()
    const [selectOpenStates, setSelectOpenStates] = useState(Array(3).fill(false));
    const {windowWidth, properties, setProperties, widgetOptions} = useContext(WidgetContext)
    const [property, setProperty] = useState(null)
    const [energyTypes, setEnergyTypes] = useState([])
    const [loading, setLoading] = useState(true)
    const [actualCostData, setActualCostData] = useState(null)
    const [actualUsageData, setActualUsageData] = useState(null)
    const [cumulativeUsageData, setCumulativeUsageData] = useState(null)
    const [cumulativeCostData, setCumulativeCostData] = useState(null)
    const [actualUsageCostPerUnitData, setActualUsageCostPerUnitData] = useState(null)
    const [energyType, setEnergyType] = useState("all-energy")
    const [fyStart, setFyStart] = useState(0)
    const [sideNavTabIndex, setSideNavTabIndex] = useState(0)
    const [loadingChart, setLoadingChart] = useState(false)
    const [visibleYears, setVisibleYears] = useState(new Set())
    const [activeOptions, setActiveOptions] = useState(options)
    const [activeData, setActiveData] = useState(null)
    const [costActive, setCostActive] = useState(false)
    const [utilityAccountMeters,setUtilityAccountMeters] = useState([])
    const [selectedPropertyId, setSelectedPropertyId] = useState(propertyId)
    const [yearsLoaded, setYearsLoaded] = useState(false)
    const [groupedEnergyTypes, setGroupedEnergyTypes] = useState([<ListSubheader>Loading...</ListSubheader>])
    const [energyTypeValues, setEnergyTypeValues] = useState([])
    const sideNavTabOptions = [0,1,2,3].filter(n => widgetOptions.includeCosts == false 
      ? n % 2 != 1 
      : widgetOptions.includeUsage  == false
                                                    ? n % 2 != 0 
                                                    : true
                                                )
    const selectRefs = useRef([]);
    const sideNavTabIndexRef = useRef(sideNavTabIndex);
    

    useEffect(() => {
      sideNavTabIndexRef.current = sideNavTabIndex;
    }, [sideNavTabIndex]);

    useEffect(() => {
      let intervalId;
      if (slideShow && energyTypeValues.length && properties.length) {
        intervalId = setInterval(() => advanceSlideShow(energyTypeValues, energyType, sideNavTabIndexRef.current), 4000);
      }
      return () => clearInterval(intervalId);
    }, [properties, energyTypeValues, slideShow])

    const advanceSlideShow = (options, energyType, sideNavTabIndex) => {
      console.log("advancing slideshow")
      console.log("options", options)
      console.log("energyType", energyType)
      if (options[options.length-1] == energyType) {
        // advance property
        console.log("advancing property")
        const propIdx = properties.findIndex(p => p.id == selectedPropertyId)
        setSelectedPropertyId(properties[(propIdx + 1) % properties.length].id)
      } else if (sideNavTabIndex == sideNavTabOptions[sideNavTabOptions.length-1]) {
        console.log("advancing energy type")
        console.log("options", options)
        console.log("energyType", energyType)
        const etIdx = options.findIndex(o => o === energyType)
        console.log("changing to energy type", options[etIdx + 1])
        setEnergyType(options[etIdx + 1])
      } else {
        console.log("advancing slideshow metric", sideNavTabIndex + 1)
        setSideNavTabIndex(sideNavTabOptions[sideNavTabIndex + 1])
      }
    }
    

    const addSelectRef = (ref, index) => {
      if (ref && !selectRefs.current.includes(ref)) {
          selectRefs.current[index] = ref;
      }
    };

    const handleCloseDropdowns = () => {
      setSelectOpenStates(states => states.map(() => false));
    };

    useEffect(_ => console.log(selectOpenStates), [selectOpenStates])


    useEffect(() => {
      const handleMessage = (event) => {
          if (event.data === 'closeDropdown') {
            console.log("closing dropdowns")
            console.log(selectOpenStates)
              handleCloseDropdowns()
          }
      };

      window.addEventListener('message', handleMessage);

      return () => {
          window.removeEventListener('message', handleMessage);
      };
  }, []);

    useEffect(_ => {
      if (!widgetOptions.includeCosts && actualUsageData) {
        setSideNavTabIndex(1)
      }
    }, [widgetOptions, actualUsageData])

    const setters = {
      ActualCostData: setActualCostData,
      ActualUsageData: setActualUsageData,
      ActualUsageCostPerUnitData: setActualUsageCostPerUnitData,
      CumulativeCostData: setCumulativeCostData,
      CumulativeUsageData: setCumulativeUsageData
    };
    

    const showPropertyName = _ => !!includePropertyName
    const showFYSelect = _ => includeFYSelect != false
    const showTable = _ => includeTable != false
    const showChart = _ => includeChart != false

    useEffect(() => {
      setActiveOptions(() => {
        const activeDarkModeOptions = activeTheme.palette.mode == "dark" ? darkModeOptions : {}
        const newOptionsRoot = {...options}
        if (fillScreen) {
          newOptionsRoot.maintainAspectRatio = false
        }
        if (sideNavTabIndex === 0) {
          setActiveData(actualCostData)
          setCostActive(true)
          return {...deepMerge(deepMerge(deepMerge({}, newOptionsRoot), currencyOptions), activeDarkModeOptions), plugins: {...options.plugins, ...currencyOptions.plugins, title: { display: false, text: `Monthly Cost Year over Year`, font: {size: 30 } }}}
        } else if (sideNavTabIndex === 1) {
          setCostActive(false)
          setActiveData(actualUsageData)
          return {...deepMerge(deepMerge({}, newOptionsRoot), activeDarkModeOptions), plugins: {...options.plugins, title: { display: false, text: `Monthly Usage Year over Year (${actualUsageData.datasets[0].measurementUnit})`, font: {size: 30 } }}}
        } else if (sideNavTabIndex === 2) {
          setCostActive(true)
          setActiveData(cumulativeCostData)
          return {...deepMerge(deepMerge(deepMerge({}, newOptionsRoot), currencyOptions), activeDarkModeOptions), plugins: {...options.plugins, ...currencyOptions.plugins, title: { display: false, text: `Cumulative Cost Year over Year`, font: {size: 30 } }}}
        } else if (sideNavTabIndex === 3) {
          setCostActive(false)
          setActiveData(cumulativeUsageData)
          return {...deepMerge(deepMerge({}, newOptionsRoot), activeDarkModeOptions), plugins: {...options.plugins, title: { display: false, text: `Cumulative Usage Year over Year (${actualUsageData.datasets[0].measurementUnit})`, font: {size: 30 } }}}
        } else if (sideNavTabIndex === 4) {
          setCostActive(true)
          setActiveData(actualUsageCostPerUnitData)
          return {...options, plugins: {...options.plugins, ...currencyOptions.plugins, title: { display: false,  text: `Monstly Usage Cost Per Unit Year Over Year`, font: {size: 30 } }}}
        } else {
          setActiveData(actualCostData)
          return options
        }
      })
    }, [sideNavTabIndex, cumulativeUsageData, activeTheme])

    const toggleDataset = (year) => {
      console.log("toggling year", year)
      setVisibleYears(prevYears => {
        const updatedYears = new Set(prevYears);
        if (updatedYears.has(year)) {
          updatedYears.delete(year);
        } else {
          updatedYears.add(year);
        }
        console.log("updatedYears", updatedYears)
        return updatedYears;
      });
    }; 

    useEffect(_ => {
      if (yearsLoaded && !loading) {
        const updateVisibility = (prevState) => ({
          ...prevState,
          datasets: prevState.datasets.map(ds => ({
            ...ds,
            hidden: !visibleYears.has(ds.label)
          }))
        });
    
        setActualCostData(prevState => updateVisibility(prevState));
        setActualUsageData(prevState => updateVisibility(prevState));
        setCumulativeCostData(prevState => updateVisibility(prevState));
        setCumulativeUsageData(prevState => updateVisibility(prevState));
        console.log("sending height on 289")
        callFunctionAtInterval(sendHeight, 100, 1000)
      }
      
    }, [visibleYears, loading, energyType])

    const sortPropertiesByName = (a, b) => a.name.replace("*static*", "").trim().localeCompare(b.name.replace("*static*", "").trim())
    const sortPropertiesByStatus = (a,b) => a.status.localeCompare(b.status)

    useEffect(_ => {
      if (energyType.includes("utility-account-meter") || slideShow) {
        setEnergyType("all-energy")
      }
    }, [selectedPropertyId])

    useEffect(_ => {
      const fetchData = () => {
        const {selectedPropertyIds} = widgetOptions
        if (!properties.length) {
          axiosAuth().get(`/properties`)
            .then(r => {
              let sortedProperties = r.data.properties.filter(p => !["Inactive", "Delete"].includes(p.status)).sort(sortPropertiesByName).sort(sortPropertiesByStatus)
              if (selectedPropertyIds) {
                sortedProperties = sortedProperties.filter(p => selectedPropertyIds.includes(p.id))
              }
              setProperties(sortedProperties);
              console.log(sortedProperties)
              if (!selectedPropertyId) {
                setSelectedPropertyId(sortedProperties[0].id)
              }
            })
            .catch(e => console.log(e))
        }
        setLoadingChart(true)
        if (!selectedPropertyId) return
        axiosAuth().post(`/properties/${selectedPropertyId}/yearoveryear`, {fy_start_month: fyStart, energy_type_name: energyType})
            .then(r => {
                const {labels, cumulativeCostDatasets, actualCostDatasets, actualUsageDatasets, cumulativeUsageDatasets, actualCostPerUnitDatasets, energyTypes, property, utilityAccountMeters} = r.data

                if (!visibleYears.size || slideShow) {
                  const newVisibility = actualCostDatasets.map((ds, index) => ds.label).map(Number).sort().reverse().slice(0,5).map(String)

                  setTimeout(_ => setVisibleYears(new Set(newVisibility)), 100)
                  setYearsLoaded(true)
                }
                if (slideShow) {
                  setSideNavTabIndex(widgetOptions.includeCosts == false ? 1 : 0)
                }

                if (energyType == 'all-costs' && [1,3].includes(sideNavTabIndex)) {
                  console.log("Deactivating tabs")
                  setSideNavTabIndex(0)
                }

                setEnergyTypes(energyTypes)
                setUtilityAccountMeters(utilityAccountMeters)
                setProperty(property)
                if (!actualCostDatasets.length) {
                  if (slideShow) {
                    advanceSlideShow(energyTypeValues, energyType, sideNavTabIndexRef.current)
                  } else {
                    setEnergyType(energyType == "all-energy" ? "all-costs": "all-energy")
                  }
                  return
                }

                const dataSets = [
                  { key: 'ActualCostData', value: actualCostDatasets },
                  { key: 'ActualUsageData', value: actualUsageDatasets },
                  { key: 'ActualUsageCostPerUnitData', value: actualCostPerUnitDatasets },
                  { key: 'CumulativeCostData', value: cumulativeCostDatasets },
                  { key: 'CumulativeUsageData', value: cumulativeUsageDatasets }
                ];

                dataSets.forEach(dataset => {
                  const setter = setters[dataset.key];
                  setter({
                    labels,
                    datasets: dataset.value.map(ds => ({
                      ...ds,
                      hidden: !visibleYears.has(ds.label)
                    }))
                  });
                });

                setLoading(false)
                setLoadingChart(false)
                console.log("sending height on 361")
                callFunctionAtInterval(sendHeight, 200, 1000)

            })
            .catch(console.error)
      };

      const debouncedFetchData = debounce(fetchData, 100);
      debouncedFetchData();
    }, [fyStart, energyType, selectedPropertyId])

    useEffect(_ => {
      let groupedEnergyTypeSelector = []
      let newEnergyTypeValues = []
      if (widgetOptions.includeCosts != "false") {
        groupedEnergyTypeSelector.push(<MenuItem className={classes.menuItem} value="all-costs" style={{ paddingLeft: "25px" }}>All Costs</MenuItem>)
        newEnergyTypeValues.push('all-costs')
      }
      groupedEnergyTypeSelector.push(<ListSubheader style={{fontSize: "1.25rem", fontWeight: "bold"}}>Energy</ListSubheader>)
      groupedEnergyTypeSelector.push(<MenuItem className={classes.menuItem} value="all-energy" style={{ paddingLeft: "25px" }}>All Energy</MenuItem>)
      newEnergyTypeValues.push('all-energy')

      const kbtuEnergyTypes = energyTypes.filter(et => !et.water && ![22,23,27].includes(et.id))
      kbtuEnergyTypes.forEach(et => {
        groupedEnergyTypeSelector.push(<MenuItem className={classes.menuItem} key={"energy-type-" + et.id} value={et.name} style={{ paddingLeft: "25px" }}>{et.display_name}</MenuItem>)
        newEnergyTypeValues.push(et.name)
      })
      
      const waterTypes = energyTypes.filter(et => et.water)
      if (waterTypes.length) {
        groupedEnergyTypeSelector.push(<ListSubheader style={{fontSize: "1.25rem", fontWeight: "bold"}}>Water</ListSubheader>)
        groupedEnergyTypeSelector.push(<MenuItem className={classes.menuItem} value="all-water" style={{ paddingLeft: "25px" }}>All Water</MenuItem>)
        newEnergyTypeValues.push('all-water')
        waterTypes.forEach(et => {
          groupedEnergyTypeSelector.push(<MenuItem className={classes.menuItem} key={"energy-type-" + et.id} value={et.name} style={{ paddingLeft: "25px" }}>{et.display_name}</MenuItem>)
          newEnergyTypeValues.push(et.name)
        })
      }
      
      const wasteTypes = energyTypes.filter(et => [22,23,27].includes(et.id))
      
      if (wasteTypes.length) {
        
        groupedEnergyTypeSelector.push(<ListSubheader style={{fontSize: "1.25rem", fontWeight: "bold"}}>Materials</ListSubheader>)
        groupedEnergyTypeSelector.push(<MenuItem className={classes.menuItem} value="all-materials" style={{ paddingLeft: "25px" }}>All Materials</MenuItem>)
        newEnergyTypeValues.push("all-materials")
        wasteTypes.forEach(et => {
          groupedEnergyTypeSelector.push(<MenuItem className={classes.menuItem} key={"energy-type-" + et.id} value={et.name} style={{ paddingLeft: "25px" }}>{et.display_name}</MenuItem>)
          newEnergyTypeValues.push(et.name)
        })
      }
      
      groupedEnergyTypeSelector.push(<ListSubheader style={{fontSize: "1.25rem", fontWeight: "bold"}}>Meters</ListSubheader>)
      utilityAccountMeters.forEach(uam => {
        groupedEnergyTypeSelector.push(<MenuItem className={classes.menuItem} key={"energy-type-uam-" + uam.id} value={`utility-account-meter-${uam.water ? "water-" : ""}${uam.id}`} style={{ paddingLeft: "25px" }}>{uam.energy_type_display_name } - {uam.utility_company_name} - {uam.acct_num} {uam.meter_num ? `(${uam.meter_num})` : ""}</MenuItem>)

      })
      setGroupedEnergyTypes(groupedEnergyTypeSelector)
      setEnergyTypeValues(newEnergyTypeValues)
    }, [energyTypes, utilityAccountMeters])
  return <>
      {loading
          ? <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 500}}>
            <Loader
              type="Oval"
              color="orange"
              height={40}
              width={40} />
            </div>
          : 
          <div>
            <div style={{margin: '0 auto', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              {(properties.length == 1 || slideShow || (property && showPropertyName())) && <h1 style={{display: 'inline-block', marginRight: 15}} align='center'>{property.name}</h1>}
              <div className="filter-form">
                {propertySelect && !slideShow && propertySelect != 'false' && properties.length > 1 &&
                  <FormControl >
                    <InputLabel shrink htmlFor="energy-typeproperty-select">Property</InputLabel>
                    <Select
                      disabled={slideShow}  
                      inputRef={(ref) => addSelectRef(ref, 0)}
                      label="Property"
                      value={selectedPropertyId}
                      onChange={e => setSelectedPropertyId(e.target.value)}
                      style={{fontWeight: 'bold'}}
                      open={selectOpenStates[0] || false}
                      onClose={() => setSelectOpenStates(states => {
                        const newStates = [...states];
                        newStates[0] = false;
                        return newStates;
                      })}
                      onOpen={() => setSelectOpenStates(states => {
                          const newStates = [...states];
                          newStates[0] = true;
                          return newStates;
                      })}
                    >
                      {
                        properties.map(prop => <MenuItem value={prop.id}>{prop.name} {prop.status !== "Active" && `(${prop.status})`}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                }
                {showFYSelect() && <FormControl >
                  <InputLabel shrink htmlFor="energy-type-select">Fiscal Period</InputLabel>
                  <Select
                    disabled={slideShow} 
                    inputRef={(ref) => addSelectRef(ref, 1)}
                    label="Fiscal Period"
                    value={fyStart}
                    open={selectOpenStates[1] || false}
                    onChange={e => setFyStart(e.target.value)}
                    onClose={() => setSelectOpenStates(states => {
                      const newStates = [...states];
                      newStates[1] = false;
                      return newStates;
                    })}
                    onOpen={() => setSelectOpenStates(states => {
                        const newStates = [...states];
                        newStates[1] = true;
                        return newStates;
                    })}
                  >
                    <MenuItem value={0}>January-December</MenuItem>
                    <MenuItem value={1}>February-January</MenuItem>
                    <MenuItem value={2}>March-February</MenuItem>
                    <MenuItem value={3}>April-March</MenuItem>
                    <MenuItem value={4}>May-April</MenuItem>
                    <MenuItem value={5}>June-May</MenuItem>
                    <MenuItem value={6}>July-June</MenuItem>
                    <MenuItem value={7}>August-July</MenuItem>
                    <MenuItem value={8}>September-August</MenuItem>
                    <MenuItem value={9}>October-September</MenuItem>
                    <MenuItem value={10}>November-October</MenuItem>
                    <MenuItem value={11}>December-November</MenuItem>
                  </Select>
                </FormControl>}
                <FormControl style={{minWidth: 110}}>
                  <InputLabel shrink htmlFor="energy-type-select">Resource Type</InputLabel>
                  <Select
                    disabled={slideShow} 
                    inputRef={(ref) => addSelectRef(ref, 2)}
                    value={energyType}
                    onChange={e => setEnergyType(e.target.value)}
                    open={selectOpenStates[2] || false}
                    inputProps={{
                      name: 'energyType',
                      id: 'energy-type-select',
                    }}
                    onClose={() => setSelectOpenStates(states => {
                      const newStates = [...states];
                      newStates[2] = false;
                      return newStates;
                    })}
                    onOpen={() => setSelectOpenStates(states => {
                        const newStates = [...states];
                        newStates[2] = true;
                        return newStates;
                    })}
                  >
                    {groupedEnergyTypes}
                    {/* <option value="all-kbtu">All</option>
                    {
                      energyTypes.map(et => <option key={et.name} value={et.name}>{et.display_name}</option>)
                    } */}
                    
                  </Select>
                </FormControl>
                {windowWidth <= MOBILE_BREAKPOINT
                && <FormControl>
                  <InputLabel shrink htmlFor="metric-type-select">Metric</InputLabel>
                  <Select
                    inputRef={addSelectRef}
                    value={sideNavTabIndex}
                    onChange={e => setSideNavTabIndex(e.target.value)}
                    inputProps={{
                      name: 'metricType',
                      id: 'metric-type-select',
                    }}
                  >
                    <MenuItem value={0}>Monthly Cost</MenuItem>
                    <MenuItem value={1}>Monthly Usage</MenuItem>
                    <MenuItem value={2}>Cost Annual Running Total</MenuItem>
                    <MenuItem value={3}>Usage Annual Running Total</MenuItem>
                    
                  </Select>
                </FormControl>}
                <div style={{width: 24}}>
                {loadingChart &&
                  <div className="yoy-loader">
                    <PrimaryColorLoader />
                  </div>
                }
                </div>
              </div>
            </div>
           
            {actualCostData && actualCostData.datasets && 
            <Grid container style={{justifyContent: 'center'}} spacing={2}>
              {actualCostData.datasets.map((ds, index) => (
                <Grid item key={ds.label} style={!visibleYears.has(ds.label) && slideShow ? {display:  'none'} : {}}>
                  <Button 
                    variant="contained" 
                    onClick={() => toggleDataset(ds.label)} 
                    style={{ 
                      marginTop: 12,
                      background: ds.borderColor, 
                      borderColor: ds.borderColor, 
                      opacity: visibleYears.has(ds.label) ? '1' : '.4',
                      pointerEvents: slideShow ? 'none' : 'auto'
                    }}
                  >
                    {ds.label}
                  </Button>
                </Grid>
              ))}
            </Grid>}
            {windowWidth > MOBILE_BREAKPOINT && 
              <div class='horizontal-nav-container'>
                <YoYNav
                  sideNavTabIndex={sideNavTabIndex}
                  energyType={energyType}
                  setSideNavTabIndex={setSideNavTabIndex}
                  setLoadingChart={setLoadingChart}
                  orientation={"horizontal"}
                />
            </div>
            }

            
            <div class="yoy-flex-nav-container">
              {sideNav && <div class="yoy-side-nav">
                <YoYNav
                  sideNavTabIndex={sideNavTabIndex}
                  energyType={energyType}
                  setSideNavTabIndex={setSideNavTabIndex}
                  setLoadingChart={setLoadingChart}
                />

            </div>}
            </div>
            <div class='yoy-content'>
              {showChart() && <div style={{
                  width: '100%',
                  height: fillScreen ? 'calc(100vh - 420px)' : 'default', // Adjust based on the remaining components' height
              }}>
                 <Line options={activeOptions} data={activeData} />
              </div>}
                
                { showTable() && <div style={{marginRight: 4}}>
                  <YoYDataTable 
                    property={property}
                    energyType={energyType}
                    propertyId={propertyId}
                    activeTheme={activeTheme}
                    loadingChart={loadingChart}
                    cost={costActive}
                    data={activeData} 
                    actualCostData={actualCostData}
                    actualUsageData={actualUsageData}
                    cumulativeCostData={cumulativeCostData}
                    cumulativeUsageData={cumulativeUsageData}
                  />
                </div>}
            </div>
          </div>
            
      }
    </>
}