import { Checkbox } from "@material-ui/core"

export default ({role, mapping, setMappings, saveUpdate}) => {

  const handleApplicableChange = e => {
    setMappings(prevState => {
      saveUpdate(mapping, {applicable: e.target.checked}, role)
      const newMappingsKey = `${role}Mappings`
      const newMappings = prevState[newMappingsKey]
      const idx = newMappings.findIndex(m => m.id == e.target.name)
      newMappings[idx].applicable = !newMappings[idx].applicable
      newMappings[idx].ack = true
      return {
        ...prevState,
        [newMappingsKey]: newMappings
      }
    })
  }
  return (
  <Checkbox
    name={mapping.id}
    checked={mapping.applicable}
    onChange={handleApplicableChange}
    color="primary"
  />
  )
}