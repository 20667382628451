import { Paper, Alert, List, ListItem,ListItemText, Divider,ListItemIcon } from "@material-ui/core"
import GPTBillContext from "../../../contexts/GPTBillContext"
import InvoyageTooltip from "./InvoyageTooltip";
import { useContext } from "react"
import OkIcon from '@material-ui/icons/CheckBox';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Report';

export default function ServerErrors(props) {
  const {serverErrors, setServerErrors} = useContext(GPTBillContext)

  const warningText = "These warnings require a second look. Double-check these items before saving this bill."
  const errorText = "These errors must be corrected or the bill cannot be saved."

  return ( 
    <div>
      <List component="div" aria-label="Server validations">
        {serverErrors.validation_errors.map(validationErrorText => 
          <InvoyageTooltip title={warningText} arrow placement='left'>
            <ListItem button>
              <ListItemIcon>
                <WarningIcon style={{color: 'goldenrod'}}/>
              </ListItemIcon>
              <ListItemText primary={validationErrorText} />
            </ListItem>
          </InvoyageTooltip>
        )}
      </List>
      {!!serverErrors.database_errors.length && 
      <>
        <Divider />
        <List component="div" aria-label="Database validations">
        {serverErrors.database_errors.map(dbValidation => 
          <InvoyageTooltip title={errorText} arrow placement='left'>
            <ListItem button>
              <ListItemIcon>
                <ErrorIcon style={{color: 'red'}} />
              </ListItemIcon>
              <ListItemText primary={dbValidation} />
            </ListItem>
          </InvoyageTooltip>  
        )}
        </List>
      </>
      }
    </div>
  )
}