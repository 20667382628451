import {Typography, Link, Box} from '@material-ui/core'
import logo from "../images/branding/logo.png";

export default function Copyright() {
    return (
        <Box mt={8}>
            <Typography variant="body2" color="textSecondary" align="center">
                {'Copyright © '}
                <Link rel="noopener noreferrer" target="_blank" color="inherit" href="https://energyprint.com/">
                EnergyPrint
                </Link>{' '}
                {new Date().getFullYear()}
                {''}
            </Typography>
            <a href="https://energyprint.com" target="_blank" rel="noopener noreferrer">
                <img style={{marginTop: 20, height: 24, width: 102}}src={logo} />
            </a>
        </Box>
    );
  }