import {Checkbox, Link, Modal, Typography, Dialog, Button, TextareaAutosize, TextField} from "@material-ui/core"
import {useContext, useState, useEffect} from 'react'
import {emailRegex} from "../helpers/regex-helpers"
import WarningIcon from '@material-ui/icons/Warning';
import RequestListContext from "../../contexts/RequestListContext";
import axiosAuth from "../../utils/axiosWithAuth";
import Loader from "react-loader-spinner";
import HiveLink from "./HiveLink"

export default function SendEmailModal({setOpenSnackbar, openModal, request, handleClose}) {
    const {editEmailInitial, fetchIssues, viewingIssues, fetchRequestList, selectedRequestList, selectedTemplate, markAsSent, selectedRequest} = useContext(RequestListContext)
    const [loading, setLoading] = useState(false)
    const [editEmail, setEditEmail] = useState({
        subject: "",
        body: "",
        attachments: [],
        to: ""
    })

    const [errors, setErrors] = useState([])

    useEffect(_ => {
        const newErrors = []
        if (!emailRegex.test(editEmail.to)) newErrors.push("Email address is invalid.")
        if (!editEmail.subject) newErrors.push("Please enter a subject.")
        if (!editEmail.body) newErrors.push("Please enter an email body.")
        if (/{{|}}/.test(editEmail.body + editEmail.subject)) newErrors.push("Some template strings could not be populated.")
        setErrors(newErrors)
    }, [editEmail])
    
    useEffect(_ => {
        if (openModal) {
            setEditEmail(editEmailInitial)
            setLoading(false)
        }
    }, [openModal])

    const sendEmail = (email, request) => {

        setLoading(true)
        axiosAuth().post("/admin/utility_email_requests/send", {email, request})
            .then(r => {
                handleClose()
                setOpenSnackbar(true)
                setLoading(false)
                if (email.isIssue) {
                    fetchIssues()
                } else {
                    fetchRequestList(selectedRequestList)
                }
            })
            .catch(err => console.log(err))

    }

    const styles = {
        field: {
            marginBottom: 10,
        }
    }

    const handleEditEmailChange = e => setEditEmail({...editEmail, [e.target.name]: e.target.value})

    return (
        <Dialog maxWidth={1260} open={openModal} onClose={handleClose}>
            <div style={{width: 1260, height: '80vh', padding: 20}}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 8}}>
                    <div style={{display: 'flex' }}>
                        <Typography variant="h3">Preview</Typography>
                        
                    </div>
                    <div style={{display: 'flex' }}>
                        <Button variant="outlined" onClick={handleClose}>Close X</Button>
                    </div>
                </div>
                <div className="preview-fields">
                    <TextField
                        required
                        id="outlined-required"
                        label="To"
                        name="to"
                        value={editEmail.to}
                        style={styles.field}
                        onChange={handleEditEmailChange}
                        />
                    <TextField
                        required
                        name="subject"
                        style={styles.field}
                        id="outlined-required"
                        label="Subject"
                        value={editEmail.subject}
                        onChange={handleEditEmailChange}
                        />
                    <TextField
                        multiline 
                        style={styles.field}
                        value={editEmail.body}
                        name="body"
                        onChange={handleEditEmailChange}
                        />
                </div>
                {!!editEmail.attachments && !!editEmail.attachments.length && 
                <>
                <Typography variant="h5">Attachments</Typography>
                    {editEmail.attachments.map(a => {
                        return <>
                            <Link href={a.download_url} target="_blank">{a.filename}</Link>
                        </>
                    })}
                </>}
                {!!editEmail.accounts && !!editEmail.accounts.length 
                ? <>
                    <Typography variant="h5">{editEmail.accounts.length} account{editEmail.accounts.length != 1 && "s"} will be marked sent:</Typography>
                    {editEmail.accounts.map((a,i) => {
                        return <Link href={`${process.env.REACT_APP_EP_BASE_URL}/admin/utility_accounts/${a.id}/edit`} target="_blank">{a.acct_num}{i == editEmail.accounts.length - 1 ? "" : ", "}</Link>
                    })}
                </>
                : <Typography variant="h5">No accounts will be marked sent.</Typography>}
                {!!selectedRequest.hive_action_id && viewingIssues && 
                <>
                    <Typography style={{marginTop: 5}} variant="h5">If this is an On-Issue email, it will be logged on Hive: <HiveLink linkStylesFromProps={{display: 'inline-flex'}} hive_action_id={selectedRequest.hive_action_id} hive_action_simple_id={selectedRequest.hive_action_simple_id} /></Typography>
                </>
                }
                <div style={{alignItems: "center", display: 'flex', justifyContent: "flex-end", paddingBottom: 20}}>
                    <div>
                    {!!errors.length && 
                        <ul style={{listStyleType: 'none', paddingLeft: 0}}>
                            {errors.map(e => 
                                <li>⚠️ {e}</li>
                                )}
                        </ul>
                    }
                    </div>
                    <div style={{alignItems: "center", display: 'flex'}}>
                        <Loader
                        style={{display:'flex', justifyContent: 'center', alignItems: "center", marginRight: 5, opacity: loading ? 1 : 0}}
                        type="Oval"
                        color="orange"
                        height={40}
                        width={40} />
                        <Button disabled={loading || errors.length} variant="contained" onClick={_ => sendEmail(editEmail, selectedRequest)}>Send</Button>
                    </div>
                </div>
                
            </div>
        </Dialog>
    )
}