import React from 'react';
import { Paper, Typography } from '@material-ui/core';

const GPTHtml = ({ bill }) => {
  return (
    <Paper className="gpt-html" style={{ padding: '20px', margin: '20px', textAlign: 'left' }}>
      {bill.gpt_html ? (
        <div dangerouslySetInnerHTML={{ __html: bill.gpt_html.html }} />
      ) : (
        <Typography variant="body1">No GPT HTML content available.</Typography>
      )}
    </Paper>
  );
};

export default GPTHtml;
