import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import {useDrop} from "react-dnd"
import {ItemTypes} from "./constants"
import FolderIcon from '@material-ui/icons/Folder';
import Business from '@material-ui/icons/Business'
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import BillingPortfolioContext from '../../contexts/BillingPortfolioContext';
import {useContext} from "react"

export default function PortfolioIndexListItem({portfolio}) {
  const {openPortfolios, setOpenPortfolios} = useContext(BillingPortfolioContext)
  const [{isOver}, drop] = useDrop(
    () => ({
      accept: ItemTypes.PROPERTY,
      drop: () => ({ portfolioId: portfolio.bpg_id }),
      collect: (monitor) => ({
        isOver: !!monitor.isOver()
      })
    }),
    [portfolio.bpg_id]
  )

  const toggleSelectedPortfolio = (selections, bpg_id) => {
    if (selections.includes(bpg_id)) {
      // deselect
      setOpenPortfolios(prevState => prevState.filter(p => p != bpg_id))
      return
    }
    setOpenPortfolios([...openPortfolios, bpg_id])
  }

  return (

    <div
      ref={drop}
      style={{
        position: 'relative',
      }}
    >
    <ListItem ref={drop} button onClick={_ => toggleSelectedPortfolio(openPortfolios, portfolio.bpg_id)}>
      <ListItemIcon>
        {
          openPortfolios.includes(portfolio.bpg_id)
            ? <FolderOpenIcon />
            : <FolderIcon />
          
        }
      </ListItemIcon>
      <ListItemText primary={portfolio.bpg_name || "No Name"} />
      <ListItemSecondaryAction style={{display: 'inline-flex', alignItems: 'center'}}>
        {/* <IconButton edge="end" aria-label="comments"> */}
          <span>{portfolio.properties.length}</span>
          &nbsp;
          <Business />
        {/* </IconButton> */}
      </ListItemSecondaryAction>
    </ListItem>
    {isOver && (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          zIndex: 1,
          opacity: 0.5,
          backgroundColor: '#fb8c00',
        }}
      />
    )}
    </div>
  )
}