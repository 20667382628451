import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tooltip: {
    fontSize: "1.2rem",  // Increase font size
    padding: "10px 20px", // Increase padding
    maxWidth: 400,
    cursor: "pointer" // Set a max width
  }
}));

function InvoyageTooltip(props) {
  const classes = useStyles();

  return (
    <Tooltip open={props.open} disableHoverListener={props.open !== undefined} title={props.title} classes={{ tooltip: classes.tooltip }} placement={props.placement}>
      {props.children}
    </Tooltip>
  );
}

export default InvoyageTooltip;