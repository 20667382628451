import {Typography, Button, Link, Table, TableRow, TableBody, TableContainer, TableHead, TableCell, Paper} from "@material-ui/core"
import ApplicableCheckbox from "./form/ApplicableCheckbox"
import MappingTypeSelect from "./form/MappingTypeSelect"
import axiosAuth from "../../../utils/axiosWithAuth"
import { useContext } from "react"
import {HIGHLIGHTED_ROW_STYLES, DARK_HIGHLIGHTED_ROW_STYLES} from "../../helpers/constants"
import UserContext from "../../../contexts/UserContext"



export default ({mappings, setMappings}) => {
  const {activeTheme} = useContext(UserContext)
  const saveUpdate = (mapping, params, role) => {
    axiosAuth().put(`/admin/textract_bills/mappings/${role}/${mapping.id}`, params)
    .then(console.log)
    .catch(e => console.log(e))
  }
  return (
    <div className="mappings-editor">

      <Typography variant="h4" style={{marginTop: 20}}>Charges</Typography>
      <TableContainer style={{marginTop: 10}} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Tables Data">
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Charge Type</TableCell>
              <TableCell>Include</TableCell>
              <TableCell>Date Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappings.chargeMappings.map((cm, idx) => {
              return <TableRow
                key={idx}
                sx={{ 
                  ...(!cm.ack ? activeTheme.palette.mode == "dark" ? DARK_HIGHLIGHTED_ROW_STYLES : HIGHLIGHTED_ROW_STYLES  : {}),
                  '&:last-child td, &:last-child th': { border: 0 } 
                }}
              >
                <TableCell align="left">{cm.description}</TableCell>
                <TableCell align="left">
                  <MappingTypeSelect
                    role="charge"
                    mapping={cm}
                    setMappings={setMappings}
                    saveUpdate={saveUpdate}
                    /></TableCell>
                <TableCell>
                  <ApplicableCheckbox
                    mapping={cm}
                    setMappings={setMappings}
                    saveUpdate={saveUpdate}
                    role="charge"
                  />
                </TableCell>
                <TableCell>
                  {cm.created_at}
                </TableCell>
              </TableRow>
            })}
          
          </TableBody>
        </Table>
      </TableContainer>
      <Typography variant="h4" style={{marginTop: 20}}>Usages</Typography>
      <TableContainer style={{marginTop: 10}} component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="Tables Data">
          <TableHead>
            <TableRow>
              <TableCell>Description</TableCell>
              <TableCell>Usage Type</TableCell>
              <TableCell>Include</TableCell>
              <TableCell>Date Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappings.usageMappings.map((um, idx) => {
              return <TableRow
                key={idx}
                sx={{ 
                  ...(!um.ack ? activeTheme.palette.mode == "dark" ? DARK_HIGHLIGHTED_ROW_STYLES : HIGHLIGHTED_ROW_STYLES  : {}),   
                  '&:last-child td, &:last-child th': { border: 0 } 
                }}
              >
                <TableCell align="left">{um.description}</TableCell>
                <TableCell align="left">
                  <MappingTypeSelect
                    role="usage"
                    mapping={um}
                    setMappings={setMappings}
                    saveUpdate={saveUpdate}
                  /></TableCell>
                <TableCell>
                  <ApplicableCheckbox
                  mapping={um}
                  setMappings={setMappings}
                  role="usage"
                  saveUpdate={saveUpdate}
                  />
                </TableCell>
                <TableCell>
                  {um.created_at}
                </TableCell>
              </TableRow>
            })}
          
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}