import React, {useState, useEffect,useContext} from 'react';
import WidgetContext  from '../../contexts/WidgetContext';
import axiosAuth from "../axiosWithAuth"
import Loader from "react-loader-spinner"
import moment from 'moment'
import {co2Data} from "./co2.js"
import {flatten} from "../../helpers/formatters"
import TimeControlBar from "./TimeControlBar"
import CsvDownload from "react-json-to-csv"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { CropSquareSharp } from '@material-ui/icons';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


// labels are all the months
const labels = ['INITIAL'];

export const INITIAL_DATA = {
  labels,
  initial: true,
  datasets: [
    {
      label: 'Dataset 1',
      data: [22,33,44,55,66,77,88],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }
  ],
};

const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
        text: 'XC Spec CO2 Monitor',
      },
    },
    bezierCurve: false,
    elements: {

        point: {
            radius: 1,
            pointStyle: 'circle'
        },
        line: {
            tension: 1,
            borderWidth: 3,
        },
        
    },
    zoom: {
        enabled: true,
        drag: {
            enabled: true,
        },
        mode: 'x'
    },
    pan: {
        enabled: true,
        mode: 'x'
    },
    scales: {
      y: {
          scaleLabel: {
              display: true,
              labelString: 'CO2 ppm'
          },
      },
    }     
  };

export default function App() {
    const {selectedProperties, modeString} = useContext(WidgetContext)
    const [propertyName, setPropertyName] = useState('')
    const [chartRef, setChartRef] = useState(null)

    const colors = [ "#f4a717", "#ff0000", "#00ff00", "#0000ff", "0ff00f0", "#ffff00", "#00ffff", "#ff00ff"]

    const [data, setData] = useState(INITIAL_DATA)
    const [allData, setAllData] = useState(INITIAL_DATA)
    const [csvData, setCsvData] = useState(null)
    const [timeInterval, setTimeInterval] = useState(86400000)
    useEffect(_ => {
        axiosAuth().get(`/dynamo/co2`)
            .then(r => {
                console.log(r)
                if (!csvData) setCsvData(r.data.dynamoData.map(flatten).map(i => { return {...i, sample_time: moment(i.sample_time).format('L') + " " + moment(i.sample_time).format('LTS')}}))
                let dataset = {
                    borderColor: '#1ab394' || "#1ab394",
                    backgroundColor: '#1ab394',
                    label: "Co2 in EP Office",
                    data: r.data.dynamoData.map(i => {
                        return {
                            x: i.sample_time,
                            y: i.device_data.co2_ppm
                        }
                    })
                }

                console.log(dataset)

                const newData = {
                    labels: dataset.data.map(d => moment(d.x).format('LT')),
                    datasets: [dataset],
                }
                setData(newData)
                setAllData(newData)
            })
            .catch(console.error)
    }, [modeString])
  return <>
    {console.log(options)}
        {data.initial
            ? <Loader
            type="Oval"
            color="orange"
            height={40}
            width={40} />
            : <>
            <TimeControlBar data={data} setData={setData} allData={allData} chartReference={chartRef} timeInterval={timeInterval} setTimeInterval={setTimeInterval} />
            <Line options={options} data={data} ref={(reference) => setChartRef(reference) } />
            
            <CsvDownload data={csvData} style={{background: 'white', color: "#fb8c00", border: "1px solid rgba(251, 140, 0, 0.5)", borderRadius: '4px', padding: '5px 15px', marginTop: 20, cursor: 'pointer'}} />
            </>
        }
    </>
}