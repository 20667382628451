export const getEntry = params => {
  const {api} = params;
  const fields = api
  .getAllColumns()
  .map((c) => c.field)
  .filter((c) => c !== "__check__" && !!c);
  const thisRow = {};
  
  fields.forEach((f) => {
      thisRow[f] = params.getValue(f);
  });

  return thisRow
}