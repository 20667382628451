import {Typography} from "@material-ui/core"
import EvaChatIcon from '@material-ui/icons/EmojiObjects';
import PersonIcon from '@material-ui/icons/Person';

export default function chatBadge({message}) {

  return (
    <div className='chat-badge-container' style={{justifyContent: message.role === 'user' ? "flex-end" : 'flex-start'}}>
      {message.role === 'assistant' ? <EvaChatIcon /> : null}
      <Typography variant='h6'>{message.role === 'user' ? "You" : "Eva"}</Typography>
      {message.role === 'user' ? <PersonIcon /> : null}
    </div>
  )
}