import React, {useState, useContext, useEffect} from "react"
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import {Button, TextField, Link, Typography, Select, MenuItem, InputLabel, NativeSelect} from "@material-ui/core"
import LaunchIcon from '@material-ui/icons/Launch';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import axiosAuth from "../../utils/axiosWithAuth";
import QuickbooksContext from "../../contexts/QuickbooksContext";



export default function QuickbooksCompanyGrid({companies, epCompanies, mapCompaniesTogether}) {
    const { selectedCompany, setSelectedCompany, selectedInvoice, setSelectedInvoice} = useContext(QuickbooksContext)

    const handleSelectionChange = newSelection => {
        const dataGridId = newSelection.selectionModel[0]
        const foundCompany = companies.find(c => c.Id == dataGridId)
        setSelectedCompany(foundCompany)
        setSelectedInvoice(null)
    }



    const getEntry = params => {
        const {api} = params;
        const fields = api
        .getAllColumns()
        .map((c) => c.field)
        .filter((c) => c !== "__check__" && !!c);
        const thisRow = {};
        
        fields.forEach((f) => {
            thisRow[f] = params.getValue(f);
        });

        return thisRow
    }



    const setQboId = function(cId, qboId) {
        axiosAuth().post(`/delivery/updateQboId`, {
            id: cId,
            qbo_id: qboId
        })
        .then(function(r) {
            mapCompaniesTogether(cId, qboId)
            console.log(r)
        })
        .catch(function(e){
            console.log(e)
        })
    }

/*     const linkToQuickbooks = params => {
        window.open(`https://app.qbo.intuit.com/app/customerdetail?nameId=${params.row.Id}`, "_blank")
    } */

    useEffect( _ => {
        console.log(selectedCompany)
    }, [selectedCompany])


    const INITIAL_COLUMNS = [
        {
            field: 'Id', 
            headerName: 'QBO ID', 
            headerAlign: "center", 
            width: 100,
            type: "number"
        },
        {
            field: 'CompanyName', 
            headerName: 'Company Name', 
            headerAlign: "center", 
            width: 300
        },
        {
            field: 'DisplayName', 
            headerName: 'Company Display Name', 
            headerAlign: "center", 
            width: 300
        },
        {
            field: 'FullyQualifiedName', 
            headerName: 'Full Company Name', 
            headerAlign: "center", 
            hide: true,
            width: 370
        },
        {
            field: 'ParentRef',  //this destructure didn't pull in a value even when one exists
            headerName: 'QBO C1 Company Id', 
            headerAlign: "center", 
            hide: true,
            width: 350,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.ParentRef) {
                    result= [params.row.ParentRef.value]
                }
                else {
                    result = [""]
                }
                return result
            }
        },
        {
            field: 'epc_id', 
            headerName: 'epc_id', 
            headerAlign: "center", 
            hide: true,
            width: 120
        },
        {
            field: "paired_company",
            headerName: "Ep Company",
            headerAlign: "center",
            align: "center",
            width: 500,
            renderCell: (params) => {
                
                const row = getEntry(params)
                //const [assignedEpC, setAssignedEpC] = useState([])
                //console.log(row)
                return (
                    <>
                        <Select
                            variant='outlined' 
                            id="ep-company-select"
                            fullWidth
                            name=""
                            value={row.epc_id || ""}
                            onChange={e => setQboId(e.target.value,row.Id)}
                        >       <MenuItem value=""></MenuItem>
                            {epCompanies.map(epC => 
                                <MenuItem key={epC.id} value={epC.id}>{epC.name}</MenuItem>
                            )}
                        </Select>
                    </>
                )
            }
        },
        {
            field: 'Balance', 
            headerName: 'Balance', 
            headerAlign: "center", 
            width: 120
        },
        {
            field: 'BalanceWithJobs', 
            headerName: 'Balance with Jobs', 
            headerAlign: "center", 
            hide: true,
            width: 120
        },
        {
            field: 'PrimaryEmailAddr', 
            headerName: 'Email', 
            headerAlign: "center", 
            hide: true,
            width: 220,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.PrimaryEmailAddr) {
                    result= [params.row.PrimaryEmailAddr.Address]
                }
                else {
                    result = [""]
                }
                return result
            } 
        },
        {
            field: 'PreferredDeliveryMethod', 
            headerName: 'Preferred Delivery', 
            headerAlign: "center", 
            hide: true,
            width: 170
        },
        {
            field: 'CustomerTypeRef', 
            headerName: 'Customer Type', 
            headerAlign: "center", 
            hide: true,
            width: 170
        },
        {
            field: 'Fax', 
            headerName: 'Fax #', 
            headerAlign: "center", 
            hide: true,
            width: 155,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.Fax) {
                    result = [params.row.Fax.FreeFormNumber]
                }
                else {
                    result = [""]
                }
                return result
            } 
        },
        {
            field: 'Mobile', 
            headerName: 'Mobile Phone #', 
            headerAlign: "center", 
            hide: true,
            width: 155,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.Mobile) {
                    result = [params.row.Mobile.FreeFormNumber]
                }
                else {
                    result = [""]
                }
                return result
            } 
        },
        {
            field: 'PrimaryPhone', 
            headerName: 'Primary Phone #', 
            headerAlign: "center", 
            hide: true,
            width: 155,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.PrimaryPhone) {
                    result = [params.row.PrimaryPhone.FreeFormNumber]
                }
                else {
                    result = [""]
                }
                return result
            } 
        },
        {
            field: 'AlternatePhone', 
            headerName: 'Alt Phone #', 
            headerAlign: "center", 
            hide: true,
            width: 155,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.AlternatePhone) {
                    result = [params.row.AlternatePhone.FreeFormNumber]
                }
                else {
                    result = [""]
                }
                return result
            } 
        },
        {
            field: 'Notes', 
            headerName: 'Notes', 
            headerAlign: "center", 
            hide: true,
            width: 200
        },
        {
            field: 'WebSiteAddress', 
            headerName: 'Website', 
            headerAlign: "center", 
            hide: true,
            width: 155,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.WebSiteAddress) {
                    result = [params.row.WebSiteAddress.URI]
                }
                else {
                    result = [""]
                }
                return result
            } 
        },
        {
            field: 'PrintOnCheckName', 
            headerName: 'Name On Checks', 
            headerAlign: "center", 
            hide: true,
            width: 235
        },
        {
            field: 'BillAddr', 
            headerName: 'Billing Address', 
            headerAlign: "center", 
            hide: true,
            width: 300,
            valueGetter: (params) =>{
                if (params.row.BillAddr) {
                    return `${params.row.BillAddr.Line1} ${params.row.BillAddr.Line2} ${params.row.BillAddr.Line3} ${params.row.BillAddr.Line4} ${params.row.BillAddr.Line5} ${params.row.BillAddr.City}, ${params.row.BillAddr.CountrySubDivisionCode} ${params.row.BillAddr.PostalCode} ${params.row.BillAddr.Country}`.replace(/\s+/, " ").replace(/undefined/g, "")
                }
                else {
                    return ""
                }
            } 
        },
        {
            field: 'ShipAddr', 
            headerName: 'Shipping Address', 
            headerAlign: "center", 
            hide: true,
            width: 300,
            valueGetter: (params) =>{
                if (params.row.ShipAddr) {
                    return `${params.row.ShipAddr.Line1} ${params.row.ShipAddr.Line2} ${params.row.ShipAddr.Line3} ${params.row.ShipAddr.Line4} ${params.row.ShipAddr.Line5} ${params.row.ShipAddr.City}, ${params.row.ShipAddr.CountrySubDivisionCode} ${params.row.ShipAddr.PostalCode} ${params.row.ShipAddr.Country}`.replace(/\s+/, " ").replace(/undefined/g, "")
                }
                else {
                    return ""
                }
            } 
        },
/*         {
            field: 'Actions',
            headerName: 'Actions', 
            headerAlign: "center", 
            width: 300,
            renderCell: (params) => (
                <>
                    <Button
                        varient="primary"
                        size="small"
                        onClick={linkToQuickbooks(params)}>
                            Go To Quickbooks
                        </Button>
                </>
            )
        }, */
    ]

    return (
        <DataGrid
        getRowId={companies => companies.Id}
        rows={companies}
        columns={INITIAL_COLUMNS}
        rowsPerPageOptions={[20,50,100]}
        autoHeight
        onSelectionModelChange={handleSelectionChange}
        components={{
            Toolbar: GridToolbar,
        }}
        />
    )
}