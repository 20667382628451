import {useState, useEffect} from "react"
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

export default ({utilities, selectedUtility, setSelectedUtility}) => {

  useEffect(_ => {
    if (utilities[0] && !selectedUtility) setSelectedUtility(utilities[0])
  }, [utilities])

  useEffect(_ => {
    console.log(utilities)
    console.log(selectedUtility)
  }, [selectedUtility])

  const handleUtilityChange = e => setSelectedUtility(e.target.value)


  const handleChange = (event, newValue) => {
    console.log(newValue)
    handleUtilityChange({
      target: {
        value: newValue,
      },
    });
  };

  return (
    <div style={{display: 'flex', justifyContent: 'center'}}>
      <Autocomplete
      id="utility-autocomplete"
      options={utilities}
      getOptionLabel={(option) => option.name + (option.has_unmapped ? " 🔴" : "")}
      style={{ width: 550 }}
      value={selectedUtility || null} // Set to null if no utility is selected
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} label="Utility" variant="outlined" />
        )}
        getOptionSelected={(option, value) => option.id === value.id}
      />
    </div>
    )
  }