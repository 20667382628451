import React, {useEffect, useState} from 'react';
import {FormControl, InputLabel, Select, Grid, TextField, FormControlLabel, Checkbox, Typography, Button} from '@material-ui/core';

export default function UtilityAccountsForm({accounts, setAccounts, utilityCompanies}) {

    useEffect(_ => {
        setAccounts([])
    },[])

    const handleChange = (e,i) => {
        const workingAccounts = accounts;
        workingAccounts[i][e.target.name] = e.target.value;
        setAccounts(workingAccounts);
    }
    
    const handleSelectChange = (e,i) => {
        const workingAccounts = accounts;
        workingAccounts[i][e.target.name] = +e.target.value;
        setAccounts(workingAccounts);
    }

    return(
        <>
            <Typography variant='h5'>Utility Accounts</Typography>
            <Button type="button" onClick={_ => setAccounts([...accounts, {utility_company_id: 22}])}>+ Add Account</Button>
            {accounts.map((a, i) => { 
                if (i >= 0) {
                    return (
                    <Grid key={i+1} container spacing={4}>
                        <Grid item xs>
                            
                            {/* <InputLabel id="utility-company-select-label">Utility Company</InputLabel> */}
                            <Select
                                style={{marginTop: 16}}
                                variant='outlined' 
                                labelid="utility-company-select-label"
                                id="utility-company-select"
                                inputProps={{name: "utility_company_id", id: "utility-company-id-select"}}
                                onChange={(e) => handleSelectChange(e, i)}
                                
                                native
                                >
                                {utilityCompanies.sort((a,b) => a.name - b.name).map(uc => 
                                    <option key={uc.id}>{uc.name}</option> 
                                )}

                            </Select>
                        </Grid>
                        <Grid item xs>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                name="username"
                                label="Username"
                                id={`account-username-${i}`}
                                value={a.username}
                                onChange={e => handleChange(e,i)}
                            />
                        </Grid>
                        <Grid item xs>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                name="password"
                                label="Password"
                                id={`account-password-${i}`}
                                value={a.password}
                                onChange={e => handleChange(e,i)}
                            />
                        </Grid>
                    </Grid>
                    )   
                }
            }
            )}
        </>
    )
}