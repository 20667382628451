import {Box, Typography, Tooltip} from "@material-ui/core"
const COLOR_MAP = {
  Active: "#1ab394",
  Static: "#1ab394",
  Onboarding: "#d88428",
  Inactive: "red",
  Delete: "red"
}

const TEXT_MAP = {
  PROCESSED_AUTO: "Auto Imported",
  PROCESSED_MANUAL: "Manually Imported",
  PROCESSED_FORCE: "Skipped",
  PROCESSED_FORCE_AUTO: "Skipped 🤖",
  ERROR: "Error"
}

export default ({uam}) => {

  return (

    <div style={{display: 'inline'}}>
      <Box 
        sx={{
          margin: "0 5px",
          backgroundColor: COLOR_MAP[uam.status] || '#999',
          borderRadius: 15, // rounded corners
          border: "2px solid gray",
          padding: "2px 8px",
          width: 'fit-content',
          textAlign: 'center',
          display: 'inline-block'
        }}
        >
        <Typography variant="body1" color="white" style={{fontWeight: 'bold'}}>
          {uam.status}
        </Typography>
      </Box>
    </div>
  );
}