import React, {useState, useContext, useEffect} from "react"
import { DataGrid, GridToolbar} from '@material-ui/data-grid';
import {Button, TextField, Link, Typography, Select, MenuItem, InputLabel, NativeSelect} from "@material-ui/core"
import LaunchIcon from '@material-ui/icons/Launch';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import axiosAuth from "../../utils/axiosWithAuth";
import InvoiceCard from "./InvoiceCard";
import QuickbooksContext from "../../contexts/QuickbooksContext";
import _ from 'lodash';
import moment from 'moment';



export default function QuickbooksInvoiceGrid({invoices}) {
    const { selectedCompany, setSelectedCompany, selectedInvoice, setSelectedInvoice} = useContext(QuickbooksContext)

    const handleSelectionChange = newSelection => {
        const dataGridId = newSelection.selectionModel[0]
        const foundInvoice = invoices.find(i => i.DgId == dataGridId)
        setSelectedInvoice(foundInvoice)
    }

    const getEntry = params => {
        const {api} = params;
        const fields = api
        .getAllColumns()
        .map((c) => c.field)
        .filter((c) => c !== "__check__" && !!c);
        const thisRow = {};
        
        fields.forEach((f) => {
            thisRow[f] = params.getValue(f);
        });

        return thisRow
    }

    const [sortModel, setSortModel] = useState([
        {
            field: 'TxnDate',
            sort: 'desc',
        },
    ]);

    const linkToQuickbooks = params => {
        window.open(`https://app.qbo.intuit.com/app/customerdetail?nameId=${params.row.CustomerRef.value}`, "_blank")
    }

    useEffect( _ => {
        console.log(selectedInvoice)
    }, [selectedInvoice])
    useEffect( _ => {
        console.log(sortModel)
    }, [sortModel])



    const INITIAL_COLUMNS = [
        {
            field: 'DgId', 
            headerName: 'Unique ID', 
            headerAlign: "center", 
            width: 130,
            type: "string"
        },
        {
            field: 'CustomerRef', 
            headerName: 'QBO Company ID', 
            headerAlign: "center", 
            hide: true,
            width: 125,
            type: "number",
            valueGetter: (params) =>{
                let result = [];
                if (params.row.CustomerRef) {
                    result= `${params.row.CustomerRef.value}`
                }
                else {
                    result = [""]
                }
                return result
            }
        },
        {
            field: 'CustomerRefName', 
            headerName: 'QBO Company Name', 
            headerAlign: "center", 
            width: 300,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.CustomerRef) {
                    result= `${params.row.CustomerRef.name}`
                }
                else {
                    result = [""]
                }
                return result
            }
        },
        {
            field: 'DocNumber', 
            headerName: 'Document Number', 
            headerAlign: "center", 
            hide: true,
            width: 170
        },
        {
            field: 'Id', 
            headerName: 'Transaction Id', 
            headerAlign: "center", 
            type: "number",
            //hide: true,
            width: 170
        },
        {
            field: 'DocType', 
            headerName: 'Transaction Type', 
            headerAlign: "center", 
            //hide: true,
            width: 170
        },
        {
            field: 'TxnDate', 
            headerName: 'Transaction Date', 
            headerAlign: "center", 
            width: 190,
            valueFormatter: params => {
                let result = [];
                if (params.row.TxnDate) {
                    result= `${moment(params.row.TxnDate).format("MM/DD/YYYY")}`
                }
                else {
                    result = [""]
                }
                return result
            },

        },
        {
            field: 'DueDate', 
            headerName: 'Due Date', 
            headerAlign: "center", 
            width: 190,
            valueFormatter: params => {
                let result = [];
                if (params.row.DueDate) {
                    result= `${moment(params.row.DueDate).format("MM/DD/YYYY")}`
                }
                else {
                    result = [""]
                }
                return result
            },
        },
        {
            field: 'CreateTime', 
            headerName: 'Created At', 
            headerAlign: "center", 
            hide: true,
            width: 190,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.MetaData.CreateTime) {
                    result= `${params.row.MetaData.CreateTime}`
                }
                else {
                    result = [""]
                }
                return result
            }
        },
        {
            field: 'LastUpdatedTime', 
            headerName: 'Last Updated At', 
            headerAlign: "center", 
            hide: true,
            width: 190,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.MetaData.LastUpdatedTime) {
                    result= `${params.row.MetaData.LastUpdatedTime}`
                }
                else {
                    result = [""]
                }
                return result
            }
        },
        {
            field: 'TotalAmt',
            headerName: 'Total Amount', 
            headerAlign: "center", 
            width: 200,
            valueFormatter: (params) =>{
                let result = [];
                if (params.row.TotalAmt) {
                    result= `$ ${params.row.TotalAmt}`
                }
                else {
                    result = ["$ 0"]
                }
                return result
            }
        },
        {
            field: 'PrivateNote',
            headerName: 'Private Note', 
            headerAlign: "center", 
            hide: true,
            width: 300,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.PrivateNote) {
                    result= `${params.row.PrivateNote.replace(/\n|\r/g, "")}`
                }
                else {
                    result = [""]
                }
                return result
            }
        },
        {
            field: 'CustomerMemo',
            headerName: 'Customer Memo', 
            headerAlign: "center", 
            hide: true,
            width: 300,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.CustomerMemo) {
                    result= `${params.row.CustomerMemo.value.replace(/\n|\r/g, "")}`
                }
                else {
                    result = [""]
                }
                return result
            }
        },
        {
            field: 'TxnSource',
            headerName: 'Transaction Source', 
            headerAlign: "center", 
            hide: true,
            width: 300,
        },
        {
            field: 'Balance',
            headerName: 'Balance', 
            headerAlign: "center", 
            width: 200,
            valueFormatter: (params) =>{
                let result = [];
                if (params.row.Balance) {
                    result= `$ ${params.row.Balance}`
                }
                else {
                    result = ["$ 0"]
                }
                return result
            }
        },
        {
            field: 'DepositToAccountRef', 
            headerName: 'Deposit Account', 
            headerAlign: "center", 
            width: 125,
            hide: true,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.DepositToAccountRef && params.row.DepositToAccountRef.name ) {
                    result= `${params.row.DepositToAccountRef.name}`
                }
                else {
                    result = ""
                }
                return result
            }
        },
        {
            field: 'SalesTermRef', 
            headerName: 'Terms', 
            headerAlign: "center", 
            width: 125,
            hide: true,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.SalesTermRef && params.row.SalesTermRef.name ) {
                    result= `${params.row.SalesTermRef.name}`
                }
                else {
                    result = ""
                }
                return result
            }
        },
        {
            field: 'PaymentMethodRef', 
            headerName: 'Payment Method', 
            headerAlign: "center", 
            width: 125,
            hide: true,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.PaymentMethodRef && params.row.PaymentMethodRef.name ) {
                    result= `${params.row.PaymentMethodRef.name}`
                }
                else {
                    result = ""
                }
                return result
            }
        },
        {
            field: 'BillAddr', 
            headerName: 'Billing Address', 
            headerAlign: "center", 
            hide: true,
            width: 300,
            valueGetter: (params) =>{
                if (params.row.BillAddr) {
                    return `${params.row.BillAddr.Line1} ${params.row.BillAddr.Line2} ${params.row.BillAddr.Line3} ${params.row.BillAddr.Line4} ${params.row.BillAddr.Line5} ${params.row.BillAddr.City}, ${params.row.BillAddr.CountrySubDivisionCode} ${params.row.BillAddr.PostalCode} ${params.row.BillAddr.Country}`.replace(/\s+/, " ").replace(/undefined/g, "")
                }
                else {
                    return ""
                }
            } 
        },
        {
            field: 'ShipAddr', 
            headerName: 'Shipping Address', 
            headerAlign: "center", 
            hide: true,
            width: 300,
            valueGetter: (params) =>{
                if (params.row.ShipAddr) {
                    return `${params.row.ShipAddr.Line1} ${params.row.ShipAddr.Line2} ${params.row.ShipAddr.Line3} ${params.row.ShipAddr.Line4} ${params.row.ShipAddr.Line5} ${params.row.ShipAddr.City}, ${params.row.ShipAddr.CountrySubDivisionCode} ${params.row.ShipAddr.PostalCode} ${params.row.ShipAddr.Country}`.replace(/\s+/, " ").replace(/undefined/g, "")
                }
                else {
                    return ""
                }
            } 
        },
        {
            field: 'Paid', 
            headerName: 'Paid', 
            headerAlign: "center", 
            width: 125,
            hide: true,
            valueGetter: (params) =>{
                let result = [];
                if (params.row.Balance && params.row.Balance == 0 ) {
                    result= "Paid"
                }
                else if (!params.row.Balance) {
                    result = "No Balance"
                }
                else {
                    result = "Unpaid"
                }
                return result
            }
        },
        {
            field: 'PrintStatus',
            headerName: 'Print Status', 
            headerAlign: "center", 
            hide: true,
            width: 300,
        },
        {
            field: 'Actions',
            headerName: 'Actions', 
            headerAlign: "center", 
            width: 300,
            renderCell: (params) => (
                <>
                    <Button
                        varient="primary"
                        size="small"
                        onClick={linkToQuickbooks(params)}>
                            Go To Quickbooks
                        </Button>
                </>
            )
        },


        
    ]

    return (
        <>
            <div style={{width: '100%'}}>
                {!!selectedInvoice 
                ? <InvoiceCard i={selectedInvoice} setSelectedInvoice={setSelectedInvoice}  />
                : null }
                <DataGrid
                getRowId={invoices => invoices.DgId}
                rows={invoices}
                columns={INITIAL_COLUMNS}
                rowsPerPageOptions={[20,50,100]}
                autoHeight
                onSelectionModelChange={handleSelectionChange}
                sortModel={sortModel}
                components={{
                    Toolbar: GridToolbar,
                }}
                />
            </div>

        </>
    )
}