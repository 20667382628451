import {useState, useContext} from "react";
import {TextField, Typography, Button} from "@material-ui/core"
import TextractContext from "../../../contexts/TextractContext";
export default function Queries({queries, handleQueryChange, handleNewQuery}) {
  const {selectedConfiguration} = useContext(TextractContext)
  return (
    <>
      {!!queries.length && 
        <>
          <Typography variant="h5">Queries</Typography>
          {queries.map((q, idx) => <div>
            <TextField
              InputLabelProps={{ shrink: true }}
              margin="normal"
              style={{width: 500}}
              id="body"
              label={`Query ${idx + 1}`}
              name={`query-${q.id}`}
              value={q.query || ""}
              onChange={handleQueryChange}
            />
            <TextField
              InputLabelProps={{ shrink: true }}
              margin="normal"
              
              id="field_name"
              label={`Field Name`}
              name={`ep_field_name-${q.id}`}
              value={q.ep_field_name || ""}
              onChange={handleQueryChange}
            />
            </div>  
          )}
        </>
      }
    <Button onClick={_ => handleNewQuery(selectedConfiguration)}>New Query +</Button>
    </>
  )
}