export function groupBy(array, callback) {
  const groups = {};
  array.forEach((item) => {
    const key = callback(item);
    if (!groups[key]) {
      groups[key] = [];
    }
    groups[key].push(item);
  });
  return groups;
}