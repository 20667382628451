export function abbreviateNumber(value) {
  if (value === null || value === undefined || isNaN(value)) {
    return '-';
  }

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  let suffixNum = 0;
  let absValue = Math.abs(value);

  while (absValue >= 1000 && suffixNum < suffixes.length - 1) {
    absValue /= 1000;
    suffixNum++;
  }

  let shortValue = parseFloat(absValue.toPrecision(3));

  if (shortValue % 1 !== 0) {
    shortValue = shortValue.toFixed(1);
  }

  return `${shortValue}${suffixes[suffixNum]}`;
}