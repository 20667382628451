import { Paper,MenuItem, Select, TextField, Typography, FormControl, InputLabel, IconButton } from "@material-ui/core";
import InvoyageTooltip from "../../InvoyageTooltip";
import { useContext, useState, useEffect } from "react";
import TextractContext from "../../../../../contexts/TextractContext";
import GPTBillContext from "../../../../../contexts/GPTBillContext";
import CheckBox from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';
import { UNFOCUSABLE_PROPS } from "../../../../helpers/constants";



export default ({uam, group}) => {
  const {uams,selectedTextractBill} = useContext(TextractContext)
  const {gptBill, setGptBill} = useContext(GPTBillContext)
  const [initialDates, setInitialDates] = useState({
    start: null,
    end: null
  })

  useEffect(_ => {
    const lineItems = [...gptBill.charges, ...gptBill.usages].filter(li => li.utility_account_meter_id == uam.id)
    if (!lineItems.length) return []
    
    const initialStart = [...lineItems].filter(li => li.applicable && li.start_date).map(li => li.start_date).sort()[0]
    const initialEnd = [...lineItems].filter(li => li.applicable && li.end_date).map(li => li.end_date).sort().pop()
    setInitialDates({
      start: initialStart ? initialStart.split("T")[0] : null,
      end: initialEnd ? initialEnd.split("T")[0] : null
    })
  }, [])

  function handleChange(e) {
    
    const traitToEdit = e.target.name;

    setGptBill(prevState => {
      const newCharges = [...prevState.charges]
      const newUsages = [...prevState.usages]
    
      newUsages.forEach(li => {
        if (li.utility_account_meter_id == uam.id) {
          li[traitToEdit] = e.target.value
        }
      })
      newCharges.forEach(li => {
        if (li.utility_account_meter_id == uam.id) {
          li[traitToEdit] = e.target.value
        }
      })
      return {
        ...prevState,
        charges: newCharges,
        usages: newUsages
      }
    })
  }

  function handleToggleAllApplicable(value) {
    setGptBill(prevState => {
      const newCharges = [...prevState.charges]
      const newUsages = [...prevState.usages]
    
      newUsages.forEach(li => {
        if (li.utility_account_meter_id == uam.id) {
          li["applicable"] = value
        }
      })
      newCharges.forEach(li => {
        if (li.utility_account_meter_id == uam.id) {
          li["applicable"] = value
        }
      })
      return {
        ...prevState,
        charges: newCharges,
        usages: newUsages
      }
    })
  }
  
  return (
    <Paper key={initialDates.start || "key-placeholder-dates"} elevation={5} style={{padding: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
      <Typography style={{fontWeight: 'bold', marginLeft: 20}} align='left' variant='h5'>
        {uam.utility_company_name} - {uam.acct_num} - {uam.meter_num || uam.um_meter_num || "No Meter #"} - {uam.display_name} - {uam.association_type}
      </Typography> 
      &nbsp;
      <div style={{display: 'flex'}}>
        <div style={{display: "flex", flexDirection: 'column', justifyContent: "center"}}>
          <InvoyageTooltip title={selectedTextractBill.processed ? "" : "Select All"} placement="left">
            <IconButton tabIndex={-1} color='primary' disabled={selectedTextractBill.processed} onClick={_ => handleToggleAllApplicable(true)}>
              <CheckBox />
            </IconButton>
          </InvoyageTooltip>
          <InvoyageTooltip title={selectedTextractBill.processed ? "" : "Deselect All"} placement="left">
            <IconButton tabIndex={-1} color='primary' disabled={selectedTextractBill.processed} onClick={_ => handleToggleAllApplicable(false)}>
              <CheckBoxOutlineBlank />
            </IconButton>
          </InvoyageTooltip>
        </div>
        <InvoyageTooltip title={selectedTextractBill.processed ? "" : "Change these to update all charges and usages on this meter."} placement="top">
          <div style={{display: 'flex', flexDirection: 'column', width: 335}}>
            <div style={{display: 'flex'}}>
              <TextField 
                onChange={handleChange}
                type="date"
                name="start_date"
                label='Start Date'
                defaultValue={initialDates.start || null}
                disabled={selectedTextractBill.processed}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={UNFOCUSABLE_PROPS}
              />
              &nbsp;
              <TextField 
                onChange={handleChange}
                type="date"
                name="end_date"
                label='End Date'
                defaultValue={initialDates.end || null}
                disabled={selectedTextractBill.processed}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={UNFOCUSABLE_PROPS}
              />
            </div>
            <FormControl variant="outlined">
              <Select
                fullWidth
                style={{width: '100%'}}
                variant='outlined'
                onChange={handleChange}
                name='utility_account_meter_id'
                value={uam.id}
                disabled={selectedTextractBill.processed}
                inputProps={{
                  id: `utility_account_meter_id_${uam.id}`,
                  tabIndex: -1
                }}
                >
                {uams.map(uam => <MenuItem value={uam.id}>
                  {uam.meter_num || uam.um_meter_num} - {uam.display_name} {!!(uam.association_type == "STANDARD") && `(${uam.association_type})`} {!(uam.status == "Active") && `(${uam.status.toUpperCase()})`}
                </MenuItem>)}
              </Select>
            </FormControl>
          </div>
        </InvoyageTooltip>
      </div>
    </Paper>  
  )
}