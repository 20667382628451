import { Alert } from "@material-ui/core";

export default ({confidence, response}) => {
  return (
    response
    && <div className="gpt-response">
      <Alert severity="info">
        🤖: {response}
      </Alert> 
    </div>
  )
}