import {Tabs, Tab} from "@material-ui/core"
import {useState, useContext, useEffect} from "react";
import Queries from "./Queries";
import TextractContext from "../../../contexts/TextractContext";
import axiosAuth from "../../../utils/axiosWithAuth";
import { a11yProps } from "../../helpers/a11y";
import TabPanel from "../../request-emails/TabPanel";
import Forms from "./Forms";
import Tables from "./Tables";
import Expenses from "./Expenses"
import GPTBill from "./GPTBill"
import {useParams, Link as RouterLink} from "react-router-dom"
import SnackbarLoader from "../snackbar/SnackbarLoader";
import BillNav from "./BillNav";
import Onboard from "./onboarding/Onboard";
import TemplateList from "./TemplateList";
import GPTHtml from "./GPTHtml";
import { useUser } from "../../../contexts/UserContext";

export default function ShowBill(props) {
  // Get bills from api and list them
  const {loadingQueue, setLoadingQueue, deselectBill, textractBills, setTextractBills, selectedConfiguration, selectedTextractBillId, selectedTextractBill, setSelectedTextractBill, snackbar, setSnackbar} = useContext(TextractContext)
  const [subTabIndex, setSubTabIndex] = useState(0)
  const {user} = useUser()
  let { id } = useParams();
  const parseWithGPT = (selectedTextractBill, url="parse_tables") => {
    if (selectedTextractBill.gpt_bill && !window.confirm("This bill has already been parsed by GPT. If you continue, the previous data will be archived before parsing again. Are you sure you wish to continue?")) {
      return
    }
    const body = {textract_bill_id: selectedTextractBill.id}
    setSnackbar({message: "Parsing with GPT...", severity: "info", loader: <SnackbarLoader />, timeout: 120000})
    axiosAuth().post(`/admin/openai/${url}`, body)
      .then(r => {
        setSnackbar({message: "Parsing complete!", severity: "success" })
        setSelectedTextractBill(r.data.bill)
      })
      .catch(err => setSnackbar({message: "GPT parsing failed", severity: "error" }))
  }
  const reparseAccount = (utility_account_id) => {
    if (!window.confirm("You are about to attempt to reparse this ENTIRE ACCOUNT and all its unprocessed bills. Are you sure you wish to continue?")) {
      return
    }
    const body = { utility_account_id }
    setSnackbar({message: "Parsing with GPT...", severity: "info", loader: <SnackbarLoader />, timeout: 120000})
    axiosAuth().post(`/admin/openai/reparse_account`, body)
      .then(r => {
        setSnackbar({message: r.data.message, severity: "info", loader: <SnackbarLoader />, timeout: 10000})
      })
      .catch(err => setSnackbar({message: "GPT parsing failed", severity: "error" }))
  }

  useEffect(_ => {
    const apiCallId = Date.now()
    console.log("Runnign the id useeffect", apiCallId, id)
    setLoadingQueue(true)
    axiosAuth().get(`/admin/textract_bills/${id}`)
    .then(r => {
      console.log(r.data, apiCallId)
      setSelectedTextractBill(r.data)
      if (r.data.gpt_bill) {
        setSubTabIndex(2)
        setTimeout(_ => setLoadingQueue(false), 250)
      } else {
        setLoadingQueue(false)
      }
    }).catch(console.error)
  },[id])

  const extractBill = (selectedTextractBill, config) => {
    setSnackbar({message: "Beginning extract...", severity: "info", loader: <SnackbarLoader />})
    axiosAuth().post(`/admin/textract_bills/${selectedTextractBill.id}/extract`, {s3Key: selectedTextractBill.s3_key, config})
    .then(r => {
      setSelectedTextractBill(r.data)
      
      setSnackbar({message: `Extract of bill #${selectedTextractBill.id} completed successfully.`, severity: "success"})
    })
    .catch(e => setSnackbar({message: `Extract of bill #${selectedTextractBill.id} failed.`, severity: "error"}))
  }

  return (
    selectedTextractBill && 
    <div style={{textAlign: "center"}}>
      <BillNav 
        id={id}
        extractBill={extractBill}
        selectedConfiguration={selectedConfiguration}
        textractBills={textractBills}
        selectedTextractBill={selectedTextractBill}
        parseWithGPT={parseWithGPT}
        reparseAccount={reparseAccount}
        deselectBill={deselectBill}
      />
      <div style={{display: 'flex'}}>
        <div style={{display: 'flex', flexDirection: "column"}}>

          <Tabs   
              centered 
              indicatorColor="primary" 
              value={subTabIndex} 
              orientation="vertical"
              onChange={(e, val) => setSubTabIndex(val)}>
              <Tab label="Queries" {...a11yProps(0)} />
              {/* <Tab label="Forms" {...a11yProps(1)} /> */}
              <Tab label="Tables" {...a11yProps(1)} />
              {/* <Tab label="Expenses" {...a11yProps(3)} /> */}
              <Tab label="Process" {...a11yProps(2)} />
              {!user.os && <Tab label="Onboard" {...a11yProps(3)} />}
              <Tab label="HTML" {...a11yProps(4)} />
          </Tabs>
          <TemplateList />
        </div>
        

        <TabPanel style={{width: "100%"}} value={subTabIndex} index={0}>
          <Queries bill={selectedTextractBill} />
        </TabPanel>
        {/* <TabPanel style={{width: "100%"}} value={subTabIndex} index={1}>
          <Forms bill={selectedTextractBill} />
        </TabPanel> */}
        <TabPanel style={{width: "100%"}} value={subTabIndex} index={1}>
          <Tables bill={selectedTextractBill} />
        </TabPanel>
        {/* <TabPanel style={{width: "100%"}} value={subTabIndex} index={3}>
          <Expenses bill={selectedTextractBill} />
        </TabPanel> */}
        <TabPanel style={{width: "100%"}} value={subTabIndex} index={2}>
          {!loadingQueue && <GPTBill bill={selectedTextractBill} setBill={setSelectedTextractBill} />}
        </TabPanel>
        <TabPanel style={{width: "100%"}} value={subTabIndex} index={3}>
          <Onboard bill={selectedTextractBill} setBill={setSelectedTextractBill} />
        </TabPanel>
        <TabPanel style={{width: "100%"}} value={subTabIndex} index={4}>
          <GPTHtml bill={selectedTextractBill} />
        </TabPanel>
      </div>

      
    </div>
  )
}