import {useContext, useEffect} from "react"
import {Tooltip, Autocomplete, TextField, IconButton} from "@material-ui/core"
import InvoyageTooltip from "../InvoyageTooltip";
import TextractContext from "../../../../contexts/TextractContext";
import LaunchIcon from '@material-ui/icons/Launch';
import axiosAuth from "../../../../utils/axiosWithAuth";



const CompanySelect = ({isDisabled}) => {
    const {selectedUtilityCompany, utilityCompanies, setSelectedAccount, setSelectedUtilityCompany, selectedTextractBill, setSelectedTextractBill} = useContext(TextractContext)
    const handleChange = (e, newValue) => {
        const paramsToUpdate = {utility_company_id: newValue ? newValue.id : null}
        if (!newValue || !newValue.id) paramsToUpdate.utility_account_id = null
        if (newValue && newValue.id != selectedTextractBill.id) {
            paramsToUpdate.utility_account_id = null
            setSelectedAccount(null)
        }
    
        axiosAuth().put(`/admin/textract_bills/${selectedTextractBill.id}`, paramsToUpdate)
            .then(r => {
                setSelectedTextractBill(r.data.textractBill)
            })
            .catch(console.error)
        setSelectedUtilityCompany(!newValue ? null : utilityCompanies.find(uc => uc.id == newValue.id))
        setSelectedTextractBill({...selectedTextractBill, ...paramsToUpdate})
    }

    const navigateToResource = (company) => {
        window.open(`${process.env.REACT_APP_EP_BASE_URL}/admin/utility_companies/${company.id}/edit`)
    }
    return (
    <Autocomplete
        options={[{name: "None", id: null}, ...utilityCompanies]}
        disabled={isDisabled}
        value={selectedUtilityCompany}
        onChange={handleChange}
        getOptionLabel={(option) => option.name}
        style={{ width: 300 }}
        // renderInput={(params) => <TextField {...params} label="Utility" variant="outlined" />}
        renderOption={(props, option) => (
            <div {...props}>
                {option.name}
            </div>
        )}
        renderInput={(params) => (
            <TextField 
                {...params}
                label="Utility"
                variant="outlined"
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <>
                            {selectedUtilityCompany && 
                              <InvoyageTooltip title="View Utility Company in EP" arrow>
                                <IconButton color='primary'>
                                    <LaunchIcon style={{ cursor: 'pointer' }} onClick={() => navigateToResource(selectedUtilityCompany)} />
                                </IconButton>
                              </InvoyageTooltip>
                            }
                            {params.InputProps.endAdornment}
                        </>
                    )
                }}
            />
        )}
    /> 
    )   
}
export default CompanySelect;