import {useContext, useEffect, useState} from "react";
import {FormLabel, Checkbox, FormGroup, FormControlLabel, Modal, TextField, Button, Typography, Select, MenuItem, Snackbar, Alert} from "@material-ui/core";
import RequestListContext from "../../contexts/RequestListContext";
import {makeStyles} from "@material-ui/core/styles";
import axiosAuth from "../../utils/axiosWithAuth";
import UserContext from "../../contexts/UserContext"
const useStyles= makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '50%',
        backgroundColor: 'white',
        border: '2px solid black',
        boxShadow: "2px 2px 2px #888888",
        padding: theme.spacing(2,4,3),
        top: `${5}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, 0%)`
    },
    templateSelect: {
        width: 400, 
        marginLeft: 5, 
        textAlign: 'left', 
        padding: 5
    },
    templateTypeSelect: {
        width: 120,
        marginLeft: 5,
        padding: 5
    }
}))


export default function EmailTemplates() {
    const INITIAL_TEMPLATE = {
        name: "",
        subject: "",
        body: "",
        template_type: "Ongoing",
        automated: false
    }
    const {selectedTemplate, setSelectedTemplate} = useContext(UserContext)
    const {groupedTemplates, templates, setTemplates} = useContext(RequestListContext)
    const [editing, setEditing] = useState(false)
    const [addingTemplate, setAddingTemplate] = useState(false);
    const [newTemplate, setNewTemplate] = useState(INITIAL_TEMPLATE)
    const handleNewTemplateChange = e => {setNewTemplate({ ...newTemplate, [e.target.name]: e.target.value })}
    const handleSelectedTemplateChange = e => { setEditing(true) ; setSelectedTemplate({ ...selectedTemplate, [e.target.name]: e.target.value})}
    const handleClose = e => setAddingTemplate(false)
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [openSnackbar, setOpenSnackbar] = useState(false)
    
    const handleTemplateChange = e => {
        setSelectedTemplate(templates.find(t => t.id === +e.target.value))
    }
    
    const handleCreateTemplate = e => {
        e.preventDefault()
        setSnackbarMessage("Template created")
        axiosAuth().post("admin/utility_email_requests/templates/new", newTemplate)
            .then(r => {
                setOpenSnackbar(true)
                setNewTemplate(INITIAL_TEMPLATE)
                setTemplates([...templates, r.data.template].sort(( a, b) => a.name.localeCompare(b.name) ))
                setSelectedTemplate(r.data.template)
                handleClose()
            })
            .catch(e => console.log(e.response))
        }
        
    const handleSaveChanges = e => {
        e.preventDefault()
        setSnackbarMessage("Template saved")
        axiosAuth().put(`admin/utility_email_requests/templates/${selectedTemplate.id}`, selectedTemplate)
        .then(r => {
                setEditing(false)
                setOpenSnackbar(true)
                setTemplates(r.data.templates.sort(( a, b) => a.name.localeCompare(b.name) ))
            })
            .catch(e => console.log(e.response))

    }
    const handleDeleteTemplate = e => {
        e.preventDefault()
        setSnackbarMessage("Template archived")
        var confirmDelete = window.confirm("Are you sure you want to archive this template?") 
        if (confirmDelete) {
            axiosAuth().delete(`admin/utility_email_requests/templates/${selectedTemplate.id}`)
                .then(r => {
                    setEditing(false)   
                    setOpenSnackbar(true)
                    setTemplates(templates.filter(t => t.id !== selectedTemplate.id).sort(( a, b) => a.name.localeCompare(b.name) ))
                    setSelectedTemplate(templates.find(t => t.id !== selectedTemplate.id))
                })
                .catch(e => console.log(e.response))
        } 

    }

    const handleCheckboxChange = e => {
        console.log(e)
        setEditing(true)
        setSelectedTemplate({...selectedTemplate, automated: e.target.checked})
    }

    const isValidTemplate = template => {
        const keys = ["name", "subject", "body"]
        return keys.every(key => template[key].trim())
    }

    const AllowedDynamicFields = props => {
        return (
            <div className="dynamic-field-container">
                <h3>Supported dynamic fields:</h3>
                <ul className="no-mark-list">
                    <li>{"{{UTILITY_COMPANY_NAME}}"}</li>
                    <li>{"{{ACCOUNT_NUMBER}}"}</li>
                    <li>{"{{MASTER_ACCOUNT_NUMBER}}"}</li>
                    <li>{"{{METER_NUMBER}}"}</li>
                    <li>{"{{CUSTOMER_REQUEST_ACCOUNTS}}"}</li>
                    <li>{"{{C2_ONLY_REQUEST_ACCOUNTS}}"}</li>
                    <li>{"{{NON_INVOICE_REQUEST}}"}</li>
                    <li>{"{{DELIVERY_NAME}}"}</li>
                    <li>{"{{DELIVERY_EMAIL}}"}</li>
                    <li>{"{{SERVICE_NAME}}"}</li>
                    <li>{"{{SERVICE_ADDRESS}}"}</li>
                    <li>{"{{PROPERTY_NAME}}"}</li>
                    <li>{"{{TAX_ID}}"}</li>
                    <li>{"{{C1_NAME}}"}</li>
                    <li>{"{{C2_NAME}}"}</li>
                    <li>{"{{LATEST_INVOICE_DATE}}"}</li>
                    <li>{"{{ELIGIBILITY_END_MONTH}}"}</li>
                    <li>{"{{ENERGY_TYPE}}"}</li>
                    <li>{"{{ASSOCIATION_TYPE}}"}</li>
                    <li>{"{{PREMISE_OR_ESI_NUMBER}}"}</li>
                    <li>{"{{C2_UTILITIES}}"}</li>
                    <li>{"{{LAST_WORKING_DAY_OF_MONTH}}"}</li>
                    <li><strong>---- ON-ISSUE Variables ----</strong></li>
                    <li>---- Login Issue ----</li>
                    <li>{"{{ONLINE_ACCESS_URL}}"}</li>
                    <li>{"{{ONLINE_ACCESS_USERNAME}}"}</li>
                    <li>{"{{ONLINE_ACCESS_PASSWORD}}"}</li>
                    <li>{"{{PROPERTY_NAMES_FOR_LOGIN}}"}</li>
                    <li>{"{{ACCOUNT_NUMBERS_FOR_LOGIN}}"}</li>
                    <li>{"{{UTILITY_COMPANIES_FOR_LOGIN}}"}</li>
                    <li>---- Contact Info Needed ----</li>
                    <li>{"{{PROPERTY_NAMES_FOR_DELIVERY_EMAIL}}"}</li>
                    <li>{"{{ACCOUNT_NUMBERS_FOR_DELIVERY_EMAIL}}"}</li>
                    <li>{"{{UTILITY_COMPANIES_FOR_DELIVERY_EMAIL}}"}</li>
                    <li>---- Missing Invoice ----</li>
                    <li>{"{{COMPANY_ACCOUNTS_WITH_SAME_GAP}}"}</li>
                    <li>{"{{COMPANY_PROPERTIES_WITH_SAME_GAP}}"}</li>
                    <li>{"{{GAP_START}}"}</li>
                    <li>{"{{GAP_END}}"}</li>
                </ul>
            </div>
        )
    }


    
    const styles = useStyles();
    return (
        <>
        <Modal open={addingTemplate} onClose={handleClose}>
            <form  autocomplete="off" onSubmit={handleCreateTemplate} className={styles.paper}>
                <Typography variant="h4">Create a new email template:</Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Template Name"
                    name="name"
                    value={newTemplate.name}
                    onChange={handleNewTemplateChange}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    margin="normal"
                    fullWidth
                    id="subject"
                    label="Subject"
                    name="subject"
                    value={newTemplate.subject}
                    onChange={handleNewTemplateChange}
                    required
                />
                <TextField
                    InputLabelProps={{ shrink: true }}
                    className="template-body-input"
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="body"
                    label="Body"
                    name="body"
                    value={newTemplate.body}
                    onChange={handleNewTemplateChange}
                    multiline
                    rows={10}
                    rowsMax={30}
                />
                <div className="flex-container-right">
                    <Button type="submit">Create Template</Button>
                </div>
            </form>
        </Modal>
        <div className="request-email-container">
                {true && 
                    <div className="request-email-input template-select">
                        <label>Template:</label>
                        <Select 
                            label="Template"
                            variant='standard'
                            className={styles.templateSelect}
                            onChange={handleTemplateChange} 
                            value={selectedTemplate ? selectedTemplate.id : ""}>

                            
                            {groupedTemplates} 
                        </Select>
                        <Button onClick={_ => setAddingTemplate(true)}>Create New +</Button>
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            fullWidth
                            id="name"
                            label="Name"
                            name="name"
                            value={selectedTemplate.name || ""}
                            onChange={handleSelectedTemplateChange}
                            error={!selectedTemplate.name}
                            helperText={!selectedTemplate.subject.trim() ? "A name is required" : ""}

                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            fullWidth
                            id="subject"
                            label="Subject"
                            name="subject"
                            value={selectedTemplate.subject || ""}
                            onChange={handleSelectedTemplateChange}
                            error={!selectedTemplate.subject}
                            helperText={!selectedTemplate.subject.trim() ? "A body is required" : ""}
                        />
                        <TextField
                            InputLabelProps={{ shrink: true }}
                            margin="normal"
                            fullWidth
                            id="body"
                            label="Body"
                            name="body"
                            value={selectedTemplate.body || ""}
                            onChange={handleSelectedTemplateChange}
                            multiline
                            rows={10}
                            maxRows={30}
                            error={!selectedTemplate.body}
                            helperText={!selectedTemplate.body.trim() ? "A body is required" : ""}
                        />
                        <FormGroup style={{display: 'flex', alignItems: 'center', flexDirection: "row", justifyContent: "space-between"}}>
                            <FormControlLabel control={<Checkbox onChange={handleCheckboxChange} name="automated" checked={selectedTemplate.automated} />} label="Automated" />
                            <div>
                                <FormLabel style={{color: 'black'}}>Template Type:</FormLabel>
                                <Select 
                                    label="Template Type"
                                    variant='standard'
                                    className={styles.templateTypeSelect}
                                    name="template_type"
                                    onChange={handleSelectedTemplateChange} 
                                    value={selectedTemplate ? selectedTemplate.template_type : ""}>
                                        <MenuItem value="Ongoing">Ongoing</MenuItem>
                                        <MenuItem value="Issue">On-Issue</MenuItem>
                                        <MenuItem value="Onboarding">Onboarding</MenuItem>
                                        <MenuItem value="Hive">Hive</MenuItem>
                                </Select>                                
                            </div>
                        </FormGroup>
                        <Button variant="contained" disabled={!selectedTemplate} onClick={handleDeleteTemplate}>Archive Template</Button>
                        <Button variant="contained" disabled={
                                                            !selectedTemplate || 
                                                            !editing || 
                                                            !isValidTemplate(selectedTemplate)
                                                            } 
                                                    onClick={handleSaveChanges}>Save Changes</Button>
                    </div>
                }
                <AllowedDynamicFields />
                <Snackbar 
                    anchorOrigin={{ vertical:"top", horizontal: "right" }}
                    open={openSnackbar} 
                    autoHideDuration={6000} 
                    onClose={_ => setOpenSnackbar(false)}>
                    <Alert onClose={_ => setOpenSnackbar(false)} severity="success">
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </div>
            </>
    )
}
