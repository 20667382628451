import { Box, Typography, Paper, Divider, Link, Button } from "@material-ui/core";
import MeterStatus from "./MeterStatus";
import { useContext } from "react";
import GPTBillContext from "../../../../contexts/GPTBillContext";
import Comment from "@material-ui/icons/Comment";
import MeterCalendar from "./MeterCalendar";
import { useUser } from "../../../../contexts/UserContext";
import InvoyageTooltip from "../InvoyageTooltip";

const linkTo = (url, text) => <Link tabIndex="-1" target="_blank" href={`${process.env.REACT_APP_EP_BASE_URL}/admin/${url}`}>{text}</Link>

const DeliveryDetails = ({uam}) => uam.delivery_email 
? 
<>
  <Typography variant="h6">Delivery Name: {uam.delivery_name}</Typography>
  <Typography variant="h6">Delivery Email: {uam.delivery_email}</Typography>
</> 
: 
<>
  <Typography variant="h6">
    Website: <Link tabIndex="-1" target="_blank" href={uam.online_access_url}>{uam.online_access_url}</Link>
  </Typography>
  <Typography variant="h6">Username: {uam.online_access_username}</Typography>
  <Typography variant="h6">Password: {uam.online_access_password}</Typography>
</> 

export default function MeterInfo({uam, group}) {
  const {setSelectedUam} = useContext(GPTBillContext)
  const {user} = useUser()
  
  return (
    !!uam && <Paper style={{alignSelf: 'flex-start', margin: "21px 8px 21px", width: "25%"}} elevation={8} className="lig-meter-info">
      <Box p={3} style={{textAlign: 'left'}}>
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography variant="h5">{uam.display_name}</Typography>
          <div>
            <Button tabIndex={-1} onClick={e => setSelectedUam({utility_account_meter_id: uam.id})}>Notes</Button> 
          </div> 
        </div>
        <Typography variant="h6">Status: <MeterStatus uam={uam} /></Typography>
        <Typography variant="h6">Utility Company: {linkTo(`utility_companies/${uam.utility_company_id}/edit`, uam.utility_company_name)}</Typography>
        <Typography variant="h6">
          Account: {linkTo(`utility_accounts/${uam.utility_account_id}/edit`, uam.acct_num)} 
          <InvoyageTooltip title="This is a tricky account! Check the notes and proceed with caution.">
            <span>{!uam.third_party_eligible && "❗❗"}</span>
          </InvoyageTooltip></Typography>
        <Typography variant="h6">Connection Type: {linkTo(`utility_meters/${uam.utility_meter_id}/utility_account_meters/${uam.id}/edit`, uam.association_type)}</Typography>
        <Typography variant="h6">Meter #: {linkTo(`utility_meters/${uam.utility_meter_id}/edit`, uam.meter_num || "No Meter #")}</Typography>
        <Typography variant="h6">Measurement Unit: {uam.measurement_unit_name}</Typography>
        {!!uam.premise_num && <Typography variant="h6">Premise #: {uam.premise_num}</Typography>}
        <Typography variant="h6">Property: {linkTo(`properties/${uam.property_id}/edit`, uam.property_name)}</Typography>
        <Divider style={{margin: 20}} />
        <Typography variant="h6">Delivery Method: {uam.delivery_method}</Typography>
        {!user.os && <DeliveryDetails uam={uam} />}
      </Box>
      <MeterCalendar uam={uam} />
    </Paper>
  )
}