export function callFunctionAtInterval(func, interval, totalTime) {
  // Ensure the parameters are correct
  if (typeof func !== 'function') {
      throw new Error('First argument must be a function');
  }
  if (typeof interval !== 'number' || interval <= 0) {
      throw new Error('Interval must be a positive number');
  }
  if (typeof totalTime !== 'number' || totalTime < 0) {
      throw new Error('Total time must be a non-negative number');
  }

  func()

  // Calculate how many times to call the function
  const timesToCall = Math.floor(totalTime / interval);
  let callsMade = 0;

  // Set up the interval
  const intervalId = setInterval(() => {
      if (callsMade >= timesToCall) {
          clearInterval(intervalId);
          return;
      }
      func(); // Call the provided function
      callsMade++;
  }, interval);
}