import { Link, Typography, Tooltip } from "@material-ui/core"
import { Launch } from "@material-ui/icons"
import InvoyageTooltip from "./bill/InvoyageTooltip"
export default ({bill, resourceText, resourceName, resourceId, epName}) => {
  return (
    resourceId
      ? <>
          <InvoyageTooltip open={resourceText != epName ? undefined : false} title={`Extracted as ${resourceText}`} arrow>
            <Link target="_blank" href={`${process.env.REACT_APP_EP_BASE_URL}/admin/${resourceName}/${resourceId}/edit`}>
              {epName || resourceText}&nbsp;
            </Link>
          </InvoyageTooltip>
        </>
      : <span>{resourceText}</span>
  )
}