import React, {useState, useEffect,useContext, Form} from 'react';
import {Typography, Select, MenuItem} from "@material-ui/core"
import WidgetContext  from '../../contexts/WidgetContext';
import ChartDateSelector from "./ChartDateSelector.js"
import axiosAuth from "../axiosWithAuth"
import Loader from "react-loader-spinner"
import moment from 'moment';
import ControlBar from "./ControlBar"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    tooltip: {
      enabled: false
    },
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Property Details',
    },
  },
  bezierCurve: false,
  elements: {

      point: {
          radius: 2,
          pointStyle: 'circle'
      },
      line: {
          tension: 0.1,
          borderWidth: 3,
      },
      
  },
  scales: {
    x: {
      ticks: {
        beginAtZero: true,
        callback: function numberWithCommas(val, index) {
          return index % 5 === 0 ? this.getLabelForValue(index) : ""
        },
      }
    },
    y: {
        scaleLabel: {
            display: true,
        },
      ticks: {
          beginAtZero: true,
          callback: function numberWithCommas(x) {
            return (x).toLocaleString(undefined,{'minimumFractionDigits':0,'maximumFractionDigits':2});;
        }
      }
    },
  }     
};
// labels are all the months
const labels = ['INITIAL'];

export const INITIAL_DATA = {
  labels,
  initial: true,
  datasets: [
    {
      label: 'Dataset 1',
      data: [22,33,44,55,66,77,88],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }
  ],
};

export default function App() {
    const {selectedProperties, modeString} = useContext(WidgetContext)
    const [propertyName, setPropertyName] = useState('')
    const [loadingChart, setLoadingChart] = useState(false)
    const [timeFormData, setTimeFormData] = useState({
      start_date: new Date(moment().subtract(5, "hours").subtract(2, "days")),
      end_date: new Date(moment().subtract(5, "hours")),
      normalize_by: 900
    })
    const colors = [ "#f4a717", "#4d1618", "#e28625", "#a75224", "#752e1f", "#4d1618", "#cc83cd", "#d0ddb7","#e3c798", "#f2a78a", "#f286a1",  ]

    const demoRequestBody = {
      "point_ids": [33,34,35,36,37,38],
      ...timeFormData
    }
    const [devices, setDevices] = useState([])

    const [selectedDevice, setSelectedDevice] = useState({
      name: "INITIAL DEVICE",
      initial: true,
      points: []
    })

    const [data, setData] = useState(INITIAL_DATA)

    const getChartData = (params={}) => {
      setLoadingChart(true)
      axiosAuth().post(`/vitality/readings`, {...demoRequestBody, ...params, point_ids: selectedDevice.points.map(p => p.id)})
            .then(r => {
                console.log(r)
                const newData = {
                  labels: r.data.response[0].intervals.map(i => moment(i.time).format('l') + ' ' + moment(i.time).format('LT')),
                  datasets: r.data.response.map((point, i) => {
                    return {
                      // TODO - handle multiple axes
                      label: point.name,
                      data: point.intervals.map(i => i.value),
                      borderColor: colors[i],
                      backgroundColor: colors[i],
                    }
                  }) 
                };
                setLoadingChart(false)
                setData(newData)
            })
            .catch(e => setLoadingChart(false))
    }


    
    useEffect(_ => {
      if (selectedDevice && !selectedDevice.initial) {

        getChartData(selectedDevice.points)
      }
    }, [selectedDevice])

    useEffect(_ => {
      axiosAuth().get(`/vitality/property/${selectedProperties[0]}`) // eventually work with property
      .then(r => {
        console.log(r.data.response)
        if (r.data.response[0]) setPropertyName(r.data.response[0].property_name)
        setDevices(r.data.response)
        setSelectedDevice(r.data.response[0])
      })
      .catch(err => console.log(err))
    }, [])




  return <>
    {console.log(options)}
        {data.initial
            ? <div style={{display:'flex', justifyContent: 'center', paddingTop: 30}}>   
            <Loader
            type="Oval"
            color="orange"
            height={40}
            width={40} />
            </div>
            : 
            <>
              <ControlBar />
              <Typography style={{textAlign: 'center', marginRight: 30}} variant='h3'>
                {propertyName}
                <span style={{display: 'inline-block',width: 20}} />
                <Select
                  value={selectedDevice.id}
                  onChange={e => setSelectedDevice(devices.find(d => d.id == e.target.value))}
                  >
                  {devices.map(d => {
                    return <MenuItem value={d.id}>{d.meter_name}</MenuItem>
                  })}
                </Select>
              </Typography>
              <Line options={{...options, plugins: {...options.plugins, title: { display: false }}}} data={data} />
              <ChartDateSelector
                loadingChart={loadingChart}
                timeFormData={timeFormData} 
                setTimeFormData={setTimeFormData}
                getChartData={getChartData}
              />
            

            </>
        }
    </>
}
