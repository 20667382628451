import axiosAuth from "../../../../utils/axiosWithAuth"
import { IconButton, Typography, TextField } from "@material-ui/core"
import {Add} from "@material-ui/icons"
import { titleize } from "../../../helpers/string-helpers"
import {useState, useContext} from "react";
import TextractContext from "../../../../contexts/TextractContext";


export default ({bill, setBill}) => {
  const [acctNum, setAcctNum] = useState(bill.acct_num || "")
  const {setSelectedTextractBill, setAccounts} = useContext(TextractContext)
  const handleAdd = _ => {
    const newAccount = {acct_num: acctNum, utility_company_id: bill.utility_company_id}
    axiosAuth().post(`/admin/textract_bills/onboarding/utility_account`, {textract_bill_id: bill.id, newAccount})
      .then(createResponse => {
        console.log("createResponse", createResponse)
        axiosAuth().get(`/admin/utility_companies/${bill.utility_company_id}/accounts`)
        .then(accounts => {
          console.log("list", accounts)
          setAccounts(accounts.data)
          setSelectedTextractBill(createResponse.data.newBill)
        })
      })
      .catch(err => console.log(err.response))
  }

  const handleChange = e => setAcctNum(e.target.value)

  return(
    
    !bill.utility_account_id && bill.utility_company_id &&
       <div>
        <Typography variant="h3">Create utility account</Typography>
          <TextField 
            style={{width: 500}}
            value={acctNum}
            onChange={handleChange}
            />
            
            <IconButton disabled={!bill.utility_company_id} color='primary'> <Add onClick={handleAdd} /></IconButton>
        </div>
        
  )
}