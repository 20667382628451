import { useState, useEffect } from "react"
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import { Typography, Paper, Link } from "@material-ui/core"
import { getFormattedDateUTC } from "../../../helpers/date-helpers";
import PrimaryColorLoader from "../../../PrimaryColorLoader";
import axiosAuth from "../../../../utils/axiosWithAuth"
import { getEntry } from "../../../helpers/datagrid-helpers";
import ScraperCard from "./ScraperCard"

export default function ScraperMonitor(props) {

  const [scraperErrors, setScraperErrors] = useState([])
  const [selectedError, setSelectedError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [sortModel, setSortModel] = useState([]);
  const [filterModel, setFilterModel] = useState({ items: [] });
  const INITIAL_COLUMNS = [
    { field: 'id', headerName: 'DataGrid uuid', width: 100, hide: true },
    { field: 'utility_company_name', headerName: 'Utility Company', width: 400,
    renderCell: (params) => {
          
      const row = getEntry(params)
      
      return  <div>
                  <Link target="_blank"
                      rel="noopener noreferrer"
                      href={`${process.env.REACT_APP_EP_BASE_URL}/admin/utility_companies/${row.utility_company_id}/edit`}
                      className="grid-link">
                          {row.utility_company_name}
                  </Link>              
              </div>
      } 
    },
    { field: 'utility_company_id', headerName: 'Utility Company ID', width: 100, hide: true },
    { field: 'online_access_username', headerName: 'Username', width: 100 },
    { field: 'error', headerName: 'Error', width: 1000 },
    { field: 'created_at', width: 100, headerName: 'Logged At', valueFormatter: function(v) { 
        return v.row.created_at  
        ? getFormattedDateUTC(v.row.created_at)
        : ""
      } 
    },
  ]
  const [columns, setColumns] = useState(INITIAL_COLUMNS)
  const getRowClassName = params => `super-app-theme--${params.getValue(params.id, 'property_name')}`
  const handleSortModelChange = (newModel) => {
    if (JSON.stringify(filterModel) != JSON.stringify(newModel.sortModel)) {
      setSortModel(newModel.sortModel);
    }
  };
  const handleFilterModelChange = (newModel) => {
    if (JSON.stringify(filterModel) != JSON.stringify(newModel.filterModel)) {
      setFilterModel(newModel.filterModel);
    }
  };

  const [selectionModel, setSelectionModel] = useState([]);
  const handleSelectionChange = newSelection => {
    const dataGridId = newSelection.selectionModel[0]
    const foundError = scraperErrors.find(r => r.id == dataGridId)
    if (foundError) {
      axiosAuth().get(`/admin/scraper_error_logs/${foundError.id}`)
      .then(r => setSelectedError(r.data.scraperError))
      .catch(console.error)
    }
  }

  useEffect(_ => {
    axiosAuth().get(`/admin/scraper_error_logs`)
    .then(r => setScraperErrors(r.data.scraperErrors))
    .catch(e => console.error("Error", e.response))
  },[])

  return (
    <div className="intake-table">
      {!!scraperErrors.length
        ? <>
          <Paper elevation={3}>
            <ScraperCard 
              error={selectedError}
              setSelectedError={setSelectedError}
            />
          </Paper>
          <DataGrid
            rows={scraperErrors}

            pageSize={20}
            rowsPerPageOptions={[20, 50, 100]}
            getRowClassName={getRowClassName}
            disableColumnMenu
            autoHeight
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelection) => {
              handleSelectionChange(newSelection)
              setSelectionModel(newSelection.selectionModel);
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterModel={filterModel}
            onFilterModelChange={handleFilterModelChange}
            columns={columns}
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </>
        : <Typography variant="h2">Loading... <PrimaryColorLoader width={40} height={40} /></Typography>
      }

    </div>
  )
}

