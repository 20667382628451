import { TextField } from "@material-ui/core";
import { useContext } from "react";
import GPTBillContext from "../../../../contexts/GPTBillContext";
import { getFormattedDateTZ } from "../../../helpers/date-helpers";

function DateInput({gptBill, role, disabled}) {
    const {setGptBill} = useContext(GPTBillContext)
    const handleChange = e => {
      setGptBill(prevState => ({
        ...prevState,
        [role]: e.target.value,
        charges: prevState.charges.map(c => ({...c, [role]: e.target.value}) ),
        usages: prevState.usages.map(c => ({...c, [role]: e.target.value}) )
      }))
    }
    return (
      <TextField
        disabled={disabled}
        name={role}
        type='date'
        onChange={handleChange}
        value={gptBill && gptBill[role] && gptBill[role].split("T")[0]}
        InputLabelProps={{
          shrink: true,
        }}
      />
    );
  }
  
  export default DateInput;