import {Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from "@material-ui/core"

export default function Queries({bill}) {

  return (
    !!bill.queries.length
      && 
      <div>
        <Typography variant="h4">Queries</Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Field Name</TableCell>
                <TableCell>Query</TableCell>
                <TableCell>Response</TableCell>
                <TableCell>Confidence</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bill.queries.sort((a,b) => new Date(b.confidence || 0) - new Date(a.confidence || 0)).sort((a, b) => a.response && !b.response ? -1 : 1).map((q) => (
                <TableRow key={q.id}>
                  <TableCell>{q.name}</TableCell>
                  <TableCell><strong>{q.query}</strong></TableCell>
                  <TableCell>
                    {q.response ? <strong>A: {q.response}</strong> : ""}
                  </TableCell>
                  <TableCell><strong>Confidence: {q.confidence}</strong></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
  )

}