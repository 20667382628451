import logo from '../../../images/branding/logo.png'
import PropertySelect from './PropertySelect';

export default function ChatHeader({properties, setSelectedProperty, selectedProperty})  {

  return (
    <div class='chat-header'>
      <img src={logo} style={{height: 48, width: 204, padding: 20}} />
      <PropertySelect
        properties={properties}
        setSelectedProperty={setSelectedProperty}
        selectedProperty={selectedProperty}
        />
    </div>
  )
}