import {Typography, Switch, FormControlLabel, Box, Paper} from "@material-ui/core"
export default ({showSavedBill, setShowSavedBill}) => 
<Paper elevation={7} className="bill-switch-container" style={{marginLeft: "20px", marginTop: 10, marginBottom: -15, padding: "5px 10px "}}>
  <Box display="flex" alignItems="center" >
    <Typography variant="body1" style={{ marginRight: 8 }}>
      GPT Parsing
    </Typography>

    <FormControlLabel
      control={
        <Switch
        name="show_saved"
        checked={showSavedBill}
        onChange={() => setShowSavedBill(prev => !prev)}
        />
      }
      label={<Typography variant="body1">EP Bill</Typography>}
      labelPlacement="end"
      style={{ marginRight: 0 }} 
      />
  </Box>
</Paper>

