import React from 'react';
import UserContext from "../contexts/UserContext";
import {Container, Typography, Button} from "@material-ui/core";

export default function LoggedIn() {
    const {user, setUser} = React.useContext(UserContext);
    const logOut = _ => {
        setUser({...user, loggedIn: false})
        localStorage.removeItem('jwt')
    }

    return (
        <Container>
            <Typography style={{marginTop: "30px"}} variant='h4'>You are logged in. Redirecting you...</Typography>
            {/* <Button onClick={logOut} style={{marginTop: "30px"}} variant="contained">Log Out</Button> */}
        </Container>
    )   
}