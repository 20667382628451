import React, {useEffect, useContext} from 'react';
import {Button, Link} from "@material-ui/core"
import {DataGrid} from "@material-ui/data-grid"
import Loader from "react-loader-spinner";
import WidgetContext from "../../contexts/WidgetContext"
import axiosAuth from '../axiosWithAuth';
import axios from 'axios';




export default function PropertyList() {
    // get properties list
    const {properties, setProperties, setModeString, selectedProperties, setSelectedProperties} = useContext(WidgetContext)
    function alphabetize(a, b) {
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
    }
    useEffect(_ => {
        axiosAuth().get("/properties")
            .then(r => {
              console.log(r.data)
              setProperties(r.data.properties.sort(alphabetize))
              console.log(r.data.response)
            })
            .catch(console.error)
    }, [])
    const columns = [
        {
            width: 100,
            field: "name",
            headerName: "Name",
            disableClickEventBubbling: true,
            renderCell: (params) => {
               const name = params.getValue('name')
              const onClick = () => {
                const api = params.api;
                setModeString("")
                const fields = api
                  .getAllColumns()
                  .map((c) => c.field)
                  .filter((c) => c !== "__check__" && !!c);
                const thisRow = {};
                fields.forEach((f) => {
                  thisRow[f] = params.getValue(f);
                });
                setSelectedProperties([...selectedProperties, +thisRow.id])
                return thisRow.id
              };
              return <Link style={{cursor: "pointer"}} onClick={onClick}>{name}</Link>;
            },
            flex: .5,
        },
        { field: 'id', hide: true },
        { field: 'sq_ft', headerName: "Sq ft."},
        { field: 'created_at', renderCell: params => new Date(params.value).toLocaleString(), headerName: 'Earliest Reading', width: 200 },
        { field: 'info_id', headerName: '# of Devices', width: 200 },
      ];


    return (
        <div>
                
            {!!properties.length 
                ? <DataGrid autoHeight rows={properties} columns={columns} pageSize={10} />
                : 
                <div style={{display:'flex', justifyContent: 'center', paddingTop: 30}}>
                  <Loader
                  type="Oval"
                  color="orange"
                  height={40}
                  width={40} />
                </div>
            }
        </div>
    )
}

