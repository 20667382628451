import React from "react";
import hiveLogo from "../../images/hive.png"
import { Link } from "@material-ui/core";

export default function HiveLink({hive_action_id, hive_action_simple_id, linkStylesFromProps={}}) {

    const linkStyles = {display: "flex", justifyContent: "flex-end", alignItems: "center", ...linkStylesFromProps}
    
    return (
        <Link 
        style={linkStyles}
        target="_blank" 
        href={`https://app.hive.com/workspace/rBJLFzyBuurbhkXpj?actionId=${hive_action_id}`}>
            <span>{hive_action_simple_id}</span>
            <img style={{width: 24, height: 24, marginLeft: 3}} src={hiveLogo}></img>
        </Link>
    )    
}