import InvoyageTooltip from './textract/bill/InvoyageTooltip';
import Brightness7Icon from '@material-ui/icons/Brightness7'; // Sun icon
import Brightness2Icon from '@material-ui/icons/Brightness2'; // Moon icon
import { Icon, IconButton } from '@material-ui/core';
export default ({ handleDarkModeToggle, activeTheme, color }) => {

  return(
    <span>
       <InvoyageTooltip title={`Switch to ${activeTheme.palette.mode == "light" ? "dark" : "light"} mode`}>
        <IconButton>
          {activeTheme.palette.mode == "dark" 
            ? <Brightness7Icon color={color} onClick={_ => handleDarkModeToggle("light")} /> 
            : <Brightness2Icon color={color} onClick={_ => handleDarkModeToggle("dark")} />}  
        </IconButton>
      </InvoyageTooltip> 
    </span>
  )
}