import React, {useContext} from "react";
import {Button} from "@material-ui/core"
import ArrowLeft from "@material-ui/icons/ArrowLeft"
import WidgetContext from "../../contexts/WidgetContext";

export default function ControlBar() {

    const {selectedProperties, setSelectedProperties, modeString, setModeString} = useContext(WidgetContext)

    return(
        <>
            <Button align='left' onClick={_ => setSelectedProperties([])}>◀ Properties</Button>
            <Button align='left' variant={!modeString ? "contained" : "outlined"} onClick={_ => setModeString("")}>Property</Button>
            <Button align='left' variant={modeString === 'energytype' ? "contained" : "outlined"} onClick={_ => setModeString("energytype")}>Energy Type</Button>
            <Button align='left' variant={modeString === 'meters' ? "contained" : "outlined"} onClick={_ => setModeString("meters")}>Meters</Button>
        </>
    )

}