import { Autocomplete, TextField, IconButton } from "@material-ui/core"
import LaunchIcon from "@material-ui/icons/Launch"
import InvoyageTooltip from "./bill/InvoyageTooltip"

export default function CompanySelector({isDisabled, handleChange, utilityCompanies, selectedUtilityCompany, showLink}) {

  return (

    <Autocomplete
    options={[{name: "No Company Selected", id: null}, ...utilityCompanies]}
    disabled={isDisabled}
    value={selectedUtilityCompany}
    onChange={handleChange}
    getOptionLabel={(option) => option.name}
    style={{ width: 300 }}
    // renderInput={(params) => <TextField {...params} label="Utility" variant="outlined" />}
    renderOption={(props, option) => (
            <div {...props}>
            {option.name}
            </div>
            )}
            renderInput={(params) => (
            <TextField 
                {...params}
                label="Utility"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                    endAdornment: (
                        <>
                            {selectedUtilityCompany.id && showLink && 
                              <InvoyageTooltip title="View Utility Company in EP" arrow>
                                <IconButton color='primary'>
                                    <LaunchIcon style={{ cursor: 'pointer' }} onClick={() => console.log(selectedUtilityCompany)} />
                                </IconButton>
                              </InvoyageTooltip>
                            }
                            {params.InputProps.endAdornment}
                        </>
                    )
                }}
            />
            )}
    /> 
  )
}