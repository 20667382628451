import axiosAuth from "../../../../utils/axiosWithAuth"
import { IconButton, Typography, TextField } from "@material-ui/core"
import {Add} from "@material-ui/icons"
import { titleize } from "../../../helpers/string-helpers"
import {useState, useContext} from "react";
import TextractContext from "../../../../contexts/TextractContext";


export default ({bill, setBill}) => {
  const [companyName, setCompanyName] = useState(titleize(bill.utility_company_name || ""))
  const {utilityCompanies, setUtilityCompanies, setSelectedTextractBill} = useContext(TextractContext)
  const handleAdd = _ => {
    console.log("adding...")
    const newCompany = {name: titleize(bill.utility_company_name)}
    axiosAuth().post(`/admin/textract_bills/onboarding/utility_company`, {textract_bill_id: bill.id, newCompany})
      .then(createResponse => {
        console.log("createResponse", createResponse)
        axiosAuth().get(`/admin/utility_companies`)
        .then(r => {
          console.log("list", r)
          setUtilityCompanies(r.data)
          setSelectedTextractBill(createResponse.data.newBill)
        })
      })
      .catch(err => console.log(err.response))
  }

  const handleChange = e => setCompanyName(e.target.value)

  return(
    
    !bill.utility_company_id &&
        <div>
          <Typography variant="h3">Create utility company</Typography>
          <TextField 
            style={{width: 500}}
            value={companyName}
            onChange={handleChange}
            />
            
            <IconButton color='primary'> <Add onClick={handleAdd} /></IconButton>
        </div>
        
  )
}