import React, {useState, useEffect,useContext} from 'react';
import WidgetContext  from '../../contexts/WidgetContext';
import axiosAuth from "../axiosWithAuth"
import Loader from "react-loader-spinner"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Property Details',
    },
  },
  scales: {
    y: {
        scaleLabel: {
            display: true,
            labelString: 'Total kbtu'
        },
      ticks: {
          beginAtZero: true,
          callback: function numberWithCommas(x) {
            return parseInt(x).toLocaleString() + " kbtu";
        }
      }
    },
  }     
};
// labels are all the months
const labels = ['INITIAL'];

export const INITIAL_DATA = {
  labels,
  initial: true,
  datasets: [
    {
      label: 'Dataset 1',
      data: [22,33,44,55,66,77,88],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    }
  ],
};

export default function App() {
    const {selectedProperties, modeString} = useContext(WidgetContext)
    const [propertyName, setPropertyName] = useState('')

    const colors = [ "#f4a717", "#ff0000", "#00ff00", "#0000ff", "0ff00f0", "#ffff00", "#00ffff", "#ff00ff"]

    const [data, setData] = useState(INITIAL_DATA)
    useEffect(_ => {
        axiosAuth().get(`/properties/${selectedProperties[0]}/monthly/${modeString}`)
            .then(r => {
                let datasets;
                const property = r.data.property
                setPropertyName(r.data.property.name + " - Total Cost")
                console.log(property);
                let mcds;
                if (!modeString) {
                     mcds = property.property_aggregated_monthly_data.filter(mcd => mcd.month_complete)
                     datasets = [
                        {
                            label: property.name,
                            data: mcds.map(mcd => Number(mcd.distribution_kbtus) + Number(mcd.supply_kbtus) ),
                            borderColor: "#f4a717",
                            backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        }
                    ]
                } else if(modeString === 'energytype') {
                    mcds = property.energy_type_aggregated_monthly_data.filter(mcd => mcd.month_complete)
                    const months = [...new Set(mcds.map(mcd => mcd.effective_month))]
                    
                    const energyTypeStrings = [ ...new Set(mcds.map(mcd => mcd.energy_type))]
                    datasets = energyTypeStrings.map((etString, i) => {
                        const mcdsWithData = mcds.filter(mcd => mcd.energy_type === etString)
                        console.log(months.map(m => {
                            const existingData = mcdsWithData.find(mcd => mcd.effective_month === m)
                            return existingData
                                ? existingData.total_cost
                                : 0
                            }))
                            return {
                                label: etString,
                            data: months.map(m => {
                                const existingData = mcdsWithData.find(mcd => mcd.effective_month === m)
                                return existingData
                                ? existingData.total_cost
                                : 0
                            }),
                            borderColor: colors[i] || "#f4a717",
                            backgroundColor: colors[i] || 'rgba(255, 99, 132, 0.5)',
                        }
                    })
                } 
                else if(modeString === 'meters') {
                    mcds = property.monthly_meter_data.filter(mcd => mcd.month_complete)
                    const months = [...new Set(mcds.map(mcd => mcd.effective_month))]
                    const meterIds = [ ...new Set(mcds.map(mcd => mcd.utility_meter_id))]
                    console.log(meterIds)
                    datasets = meterIds.map((meterId, i) => {
                        const meter = property.utility_meters.find(um => um.id == +meterId)
                        const mcdsWithData = mcds.filter(mcd => mcd.utility_meter_id === meterId)
                        
                            return {
                                label: (meter.meter_num || "No Meter #") + " - " + meter.energy_type,
                                spanGaps: true,
                                data: months.map(m => {
                                const existingData = mcdsWithData.find(mcd => mcd.effective_month === m)
                                return existingData
                                ? existingData.total_cost
                                : null
                            }),
                            borderColor: colors[i] || "#f4a717",
                            backgroundColor: colors[i] || 'rgba(255, 99, 132, 0.5)',
                        }
                    })
                    
                    
                }
                const newData = {
                    labels: [...new Set(mcds.map(mcd => mcd.effective_month))].map(mcd => {
                        const [year, month, day] = mcd.split("T")[0].split("-")
                        return `${month}/${year}`
                    }),
                    datasets: datasets,
                }
                setData(newData)
            })
            .catch(console.error)
    }, [modeString])
  return <>
    {console.log(options)}
        {data.initial
            ? <Loader
            type="Oval"
            color="orange"
            height={40}
            width={40} />
            : <Line options={{...options, plugins: {...options.plugins, title: { display: true, text: propertyName }}}} data={data} />
        }
    </>
}