import {useState} from  "react";
import { Alert } from "@material-ui/core";

export default ({errors}) => {
  
  return (
    errors 
    && !!errors.length 
    && <div className="bill-save-errors">
      {errors.map( 
        (e, idx) => <Alert key={idx} severity="error">{e}</Alert> 
      )}
    </div>
  )
}