import React, {useEffect, useState} from 'react';
import {Button, TextField, MenuItem, AppBar, Tabs, Tab, InputLabel, Select, makeStyles} from "@material-ui/core"
import axios from 'axios';
import QuickbooksCompanyGrid from "./QuickbooksCompanyGrid";
import Loader from "react-loader-spinner";
import {useLocation} from "react-router-dom";
import TabPanel from "./TabPanel"
import QuickbooksInvoiceGrid from "./QuickbooksInvoiceGrid";
import axiosAuth from "../../utils/axiosWithAuth";
import {a11yProps} from "../helpers/a11y"
import QuickbooksContext from "../../contexts/QuickbooksContext";

export default function Quickbooks() {
    const location = useLocation()
    const [customerList, setCustomerList] = useState([])
    const [authenticated, setAuthenticated] = useState(true)
    const [epCompanies, setEpCompanies] = useState([])
    const [gridReady, setGridReady] = useState(false)
    const [invoiceList, setInvoiceList] = useState([])
    const [tabIndex, setTabIndex] = useState(1)
    const [loading, setLoading] = useState(true)
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    const [selectedQuickbooksList, setSelectedQuickbooksList] = useState("customer_list")
    const [selectedCompany, setSelectedCompany] = useState(new URLSearchParams(location.search).get('qbo_id'))

    const revokeAccessToken = _ => {
        axiosAuth().get(`/quickbooks/auth/revokeAccessToken`)
            .then(r => setAuthenticated(false))
            .catch(console.error)
    }

    const getAuthUrl = _ => {
        axiosAuth().get(`/quickbooks/auth/authUri`)
            .then(r => window.location.assign(r.data))
            .catch(console.error)
    }

    const getAllCustomers = _ => {
        setLoading(true)
        axiosAuth().get(`/quickbooks/auth/customerReadAll`)
            .then(r => {
                console.log(r)
                setCustomerList(r.data.QueryResponse.Customer)
                setAuthenticated(true)
                setLoading(false)
            })
            .catch(function(e) {
                //this could intelligently handle different error statuses
                setAuthenticated(false)
            })
    }

    const getAllInvoices = _ => {
        setLoading(true)
        axiosAuth().get(`/quickbooks/auth/invoiceReadAll`)
            .then(r => {
                console.log(r)
                setInvoiceList(r.data.QueryResponse.Invoice)
                //setMemoList(r.data.QueryResponse.CreditMemo)
                //setJournalList(r.data.QueryResponse.JournalEntry)
                setAuthenticated(true)
                setLoading(false)
            })
            .catch(function(e) {
                //this could intelligently handle different error statuses
                setAuthenticated(false)
            })
    }

    const getCompanyInvoices = qboId => {
        
        axiosAuth().get(`/quickbooks/auth/CustomerReadInvoice`, {params: {qbo_id: qboId} })
            .then(r => {
                console.log(r)
                setInvoiceList(r.data.QueryResponse.Invoice)
                setLoading(false)
                setAuthenticated(true)
            })
            .catch(function(e) {
                console.error(e)
                console.log(e.response)
                setAuthenticated(false)
            })
    }

    const getEnergyprintCompanies = _ => {
        setLoading(true)
        axiosAuth().get(`/delivery/companiesListWhere`)
            .then(r => {
                console.log(r)
                setEpCompanies(r.data.companies) //do sorting here
                //setLoading(false)
            })
            .catch(function(e) {
                //nothing for now?
            })
    }

    const sortByCompanyName = arr => {
        return arr.sort((a, b) => {
                const textA = a.name.toUpperCase()
                const textB = b.name.toUpperCase()
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        })
    }

    const mapCompaniesTogether = (c_id, qbo_id) =>{
        let newEpCompanies = epCompanies
        if (c_id && qbo_id) { //empty id would be falsey
            const epCompanyToEdit = epCompanies.find(c => c.id == c_id)
            epCompanyToEdit.qbo_id = qbo_id
            newEpCompanies = [...epCompanies.filter( c => c.id != c_id ), epCompanyToEdit]
        }
        if (customerList.length && epCompanies.length) {
            const mappedCompanies = newEpCompanies.filter(c => c.qbo_id)
            const newCustomerList = customerList
            mappedCompanies.forEach(epc => {
                newCustomerList.find(qbc => qbc.Id == epc.qbo_id).epc_id = epc.id
            })

            console.log("this is the new customer List:")
            console.log(newCustomerList)
            setCustomerList(newCustomerList)
            setEpCompanies(newEpCompanies) 
            console.log("this is the updated Customer List:")
            console.log(customerList)
        }
    }
    
/*     const handleGridReady = _ => {
        if (!gridReady && customerList.some(c => c.epc_id)) setGridReady(true) 
    } */
    const handleGridReady = _ => {
        if (!gridReady && invoiceList) setGridReady(true) 
    }



     useEffect( _ => {
        if (selectedCompany) {
            getCompanyInvoices(selectedCompany.Id || selectedCompany)
        }
        else {
            getAllInvoices()
        }
    }, [selectedCompany]) 

    
    useEffect( _ => setTimeout(_ => setGridReady(true), 3000) , []) // assume no qbo ids after 10 seconds and render the grid, should only happen on first use.
    useEffect(getAllCustomers, [])
    useEffect(getEnergyprintCompanies, [])
    //useEffect(getAllInvoices, [])
    useEffect(mapCompaniesTogether, [epCompanies, authenticated])
    useEffect(handleGridReady, [])

    return(
        <QuickbooksContext.Provider value={{ selectedCompany, setSelectedCompany, selectedInvoice, setSelectedInvoice }}>
            {!loading
            ? 
                <>
                    <AppBar position="static" color="default">
                        <h1>Quickbooks</h1>
                        <Tabs   
                            centered 
                            indicatorColor="primary" 
                            value={tabIndex} 
                            onChange={(e, val) => setTabIndex(val)}>
                            <Tab label="Customer List" {...a11yProps(0)} />
                            <Tab label="Invoice List" {...a11yProps(1)} />
                        </Tabs>
                     </AppBar>
                        <TabPanel value={tabIndex} index={0}>
                            {gridReady && <QuickbooksCompanyGrid 
                                companies={customerList}
                                epCompanies={sortByCompanyName(epCompanies)}
                                mapCompaniesTogether = {mapCompaniesTogether} />} 
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            {gridReady && <QuickbooksInvoiceGrid 
                                invoices={invoiceList} />}
                        </TabPanel>
                </>
            :
                <>
                    <AppBar position="static" color="default">
                        <h1>Quickbooks</h1>
                    </AppBar>
                    {!authenticated && <Button 
                            onClick={getAuthUrl}
                            variant="contained">
                                Get Auth Url
                        </Button> }
                    <Loader
                        type="Oval"
                        color="orange"
                        height={40}
                        width={40} />
                </>
            }
        </QuickbooksContext.Provider>
    )
}