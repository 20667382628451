import { useState, useCallback } from "react"
import { debounce } from 'lodash';
import { TextField } from "@material-ui/core";

export default ({isDisabled, li, handleChange}) => {
  const [amount, setAmount] = useState(li.amount)
  const debouncedHandleChange = useCallback(debounce((newAmount) => {
    handleChange(li, { target: { name: 'amount', value: newAmount } });
  }, 300), []);

  const handleWheel = (event) => {
    event.target.blur()
  };

  const handleAmountInputChange = (event) => {
    const { value } = event.target;
    setAmount(value);
    debouncedHandleChange(value);
  };

  return <TextField
    disabled={isDisabled}
    style={{width: 150}}
    type="number"
    name="amount"
    value={amount}
    onChange={handleAmountInputChange}
    onWheel={handleWheel}
  />
}