import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    fontWeight: 'bold',
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const TemplateStringsList = () => {
  const classes = useStyles();

  const templateStrings = [
    '{{METER_NUMBERS}}',
    '{{ACCT_NUM}}',
    '{{UTILITY_ACCOUNT_METER_NOTES}}',
    '{{LATEST_INVOICE_DATE}}',
    '{{INVOICE_DATE}}',
    '{{CURRENT_YEAR}}',
    '{{TOTAL_CHARGES}}',
  ];

  return (
    <Paper className={classes.root} elevation={3}>
      <Typography variant="h5" className={classes.title}>
        Template Strings
      </Typography>
      <List>
        {templateStrings.map((item, index) => (
          <ListItem key={index} className={classes.listItem}>
            <ListItemText primary={item} />
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default TemplateStringsList;