import {useState, useEffect} from "react"
import UtilitySelect from "./UtilitySelect"
import MappingsEditor from "./MappingsEditor"
import axiosAuth from "../../../utils/axiosWithAuth"

export default ({}) => {
  const [utilities, setUtilities] = useState([])
  const [selectedUtility, setSelectedUtility] = useState(null)
  const [mappings, setMappings] = useState({
    chargeMappings: [],
    usageMappings: []
  })

  useEffect( _ => {
    if (!selectedUtility) return
    const allMappings = [...mappings.chargeMappings, ...mappings.usageMappings]
    if (allMappings.every(m => m.ack) && selectedUtility.has_unmapped) {
      const newUtility = {...selectedUtility, has_unmapped: false}
      setUtilities([...utilities.filter(u => u.id != selectedUtility.id), newUtility].sort((a,b) => a.name - b.name))
    }
  }, [mappings])

  useEffect(_ => {
    axiosAuth().get("/admin/textract_bills/mappings/utilities")
      .then(r => setUtilities(r.data.utilities))
      .catch(err => console.log(err.response))
  }, [])

  useEffect(_ => {
    if (selectedUtility) {
      axiosAuth().get(`/admin/textract_bills/mappings/utility/${selectedUtility.id}`)
        .then(r => setMappings(r.data.line_items))
        .catch(err => console.log(err.response))
    }
  }, [selectedUtility])
  return (
    <div className="mappings-pane">
      <UtilitySelect 
        utilities={utilities}
        selectedUtility={selectedUtility}
        setSelectedUtility={setSelectedUtility}
      />
      <MappingsEditor
        mappings={mappings}
        setMappings={setMappings}
      />
    </div>
    // company selector
    // list with charge/usage defs dropdowns
    
    )
  }