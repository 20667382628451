import React, {useState} from 'react';
import {Fab, Modal, Avatar, Alert, Button, CssBaseline, TextField, Link, Grid, Typography, Container } from '@material-ui/core'
import {Link as RouterLink } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import axios from "axios";
import UserContext from "../contexts/UserContext";
import {passwordValidationRegex, emailValidationRegex} from "../helpers/formatters";
import AttachUsers from './AttachUsers';

const baseUrl = process.env.REACT_APP_EP_API_URL;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp({history}) {
  const classes = useStyles();
  const [errors, setErrors] = useState([])
  const [attachedUsers, setAttachedUsers] = useState([])
  const [creds, setCreds] = useState({
    full_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    company_name: "",
    phone: ""
  })
  const {user, setUser} = React.useContext(UserContext);

  const handleChange = e => setCreds({...creds, [e.target.name] : e.target.value })

  const credsAreValid = creds => creds.full_name && creds.email && creds.password && creds.password_confirmation && creds.phone

  const phoneIsValid = num => /^\d{10,15}$/.test(num.replace(/[^\d]/ig, ""))

  const handleSubmit = e => {
    e.preventDefault()
    const errorBuilder = []
    // SIGN UP form validations
    setErrors([])

    if (!credsAreValid(creds)) {
      errorBuilder.push("Fields marked with a star are required.")
    } if (creds.phone && !phoneIsValid(creds.phone)) {
      errorBuilder.push("Please enter a valid phone number.")
    } if (creds.email && !emailValidationRegex.test(creds.email)) {
      errorBuilder.push("Please enter a valid email.")
    } if (creds.password && !passwordValidationRegex.test(creds.password)) {
      errorBuilder.push("Your password must be 8 characters long, and include a lowercase letter, a capital letter, and a number.")
    } if (creds.password && creds.password !== creds.password_confirmation) {
      errorBuilder.push("Your passwords don't match.")
    }

    if (errorBuilder.length) {
      setErrors(errorBuilder)
      return
    }


    axios.post(`${baseUrl}/auth/signup`, {...creds, attached_users: attachedUsers} )
      .then(r => {
        localStorage.setItem("jwt", r.data.token)
        setUser({...user, loggedIn: true})
        window.gtag('event', 'conversion', {
          'send_to': 'AW-652173935/UMvYCNLbrZcZEO_E_bYC'
        });
        history.push("/confirm")
      })
      .catch(err => {
        if (err.response && err.response.status === 400) {
          setErrors(["That email is already registered."])
        }
      })
  }
  

  return (
      <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <p>EP</p>
        </Avatar>
        <Typography component="h1" variant="h5">
          Create an account
        </Typography>
        <form onSubmit={handleSubmit} className={classes.form} noValidate>
          <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="full_name"
              label="Full Name"
              id="full_name"
              value={creds.full_name}
              onChange={handleChange}
              autoComplete="current-password"
              autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="company_name"
            label="Company"
            id="company_name"
            value={creds.company_name}
            onChange={handleChange}
            autoComplete="current-password"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone"
            label="Phone Number"
            name="phone"
            value={creds.phone}
            onChange={handleChange}
            autoComplete="phone"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            value={creds.email}
            onChange={handleChange}
            autoComplete="email"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            helperText="Minimum 8 characters, 1 lowercase, 1 uppercase, 1 number"
            id="password"
            value={creds.password}
            onChange={handleChange}
            autoComplete="current-password"
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            error={creds.password_confirmation && creds.password !== creds.password_confirmation}
            name="password_confirmation"
            label="Confirm Password"
            type="password"
            id="password_confirmation"
            helperText="Passwords do not match."
            value={creds.password_confirmation}
            onChange={handleChange}
            autoComplete="current-password"
            FormHelperTextProps={{
              style: {
                display: creds.password_confirmation && creds.password !== creds.password_confirmation ? "inline-block" : "none"
              }
            }}
          />

          <AttachUsers
            attachedUsers={attachedUsers}
            setAttachedUsers={setAttachedUsers}
          />
          
          {!!errors.length && errors.map(e => <Alert variant='outlined' className="form-validation-error" severity="error">{e}</Alert>)}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Create Account{!!attachedUsers.length && "s"}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link target="_blank" rel="noopener noreferrer" href="https://live.energyprint.com/password_resets/new" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              {/* <RouterLink to="/login">
                <Link variant="body2">
                  {"Already have an account? Log in"}
                </Link>
              </RouterLink>  */}
              <RouterLink className="login-link" to={`/login`} >
                {"Already have an account? Log in"}
              </RouterLink>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}