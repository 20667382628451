import React, {useState} from 'react';
import WidgetContext from "../../contexts/WidgetContext"
import PropertyList from "./PropertyList"
import PropertyChart from "./PropertyChart"
import VitalityChart from "./VitalityChart"
import ControlBar from "./ControlBar"
import XCChart from "./XCChart"
import {Paper, Typography} from "@material-ui/core"

export default function EnergyPrint() {

  const [properties, setProperties] = useState([])
  const [selectedProperties, setSelectedProperties] = useState([])
  const [view, setView] = useState("list")
  const [modeString, setModeString] = useState("")

  const cardStyles = {
    padding: 5,
    margin: "0 auto",
    maxWidth: 1200,
    height: 630
  }

  return(
    <>
    <br/>
    <div style={cardStyles} elevation={3}>
      <WidgetContext.Provider value={{modeString, setModeString, properties, setProperties, selectedProperties, setSelectedProperties}}>
        {/* <XCChart /> */}
        {
          !selectedProperties.length
          ? <PropertyList />
          : <> 
            {/* <VitalityChart /> */}
            <ControlBar />
            <PropertyChart />
          </>
        }
      </WidgetContext.Provider>
    </div>
    </>
  )

}