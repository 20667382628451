import React, { useContext, useEffect } from 'react';
import YearOverYearChart from "./YearOverYearChart"
import UserContext from '../../../contexts/UserContext';
import {Paper, makeStyles, Modal} from "@material-ui/core";
import {useParams} from 'react-router-dom';
import logo from "../../../images/branding/logo.png";
import YoyModal from "./YoyModal";
import WidgetContext from '../../../contexts/WidgetContext';

export default function YearOverYearChartContainer({showPropertySelect, sendHeight, widgetOptions }) {
  let { id } = useParams();
  const {setActiveTheme, activeTheme} = useContext(UserContext)
  const {propertyId, openModal, handleCloseModal, queryParams, setWindowWidth } = useContext(WidgetContext)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return(
    <div>
      <Paper elevation={0} style={{
        backgroundColor: activeTheme.palette.background.paper,
        color: activeTheme.palette.text.primary,
        transition: 'background-color 0.3s ease',
        border: 'none',
        boxShadow: 'none',
        minHeight: !queryParams.embed ? '100vh' : undefined,
        padding: 8,
        maxWidth: (queryParams.fillScreen || widgetOptions.fillScreen) ? '95%' : 1200,
        margin: '0 auto'
      }}>
          <YearOverYearChart sendHeight={sendHeight} propertyId={id} {...queryParams} {...widgetOptions} propertySelect={showPropertySelect} />
          <YoyModal
            propertyId={id}
            open={openModal}
            onClose={handleCloseModal}
          />
        {(queryParams.showLogo || widgetOptions.showLogo) && <div style={{display: 'flex', justifyContent: 'center',  alignItems: 'flex-end', borderRadius: activeTheme.palette.mode === 'dark' ? '8px' : '0', width: 180, margin: '0 auto'}}>
          <img style={{margin: 20, height: 36, width: 153}} src={logo} />
        </div>}
      </Paper>
    </div>
  )

}