import {useContext} from "react"
import {useDrag} from "react-dnd"
import { ItemTypes } from './constants.js'
import BillingPortfolioContext from "../../contexts/BillingPortfolioContext.js"
import axiosAuth from "../../utils/axiosWithAuth.js"

export default function PropertyEntry({property, setPortfolios}) {
  const {companyId} = useContext(BillingPortfolioContext)
  
  const [{ isDragging }, drag] = useDrag(() => ({
  
    type: ItemTypes.PROPERTY,
    item: { property },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        const moveParams = {property_id: item.property.id, portfolio_id: dropResult.portfolioId}
        setPortfolios(prevState => 
          (prevState.map(portfolio => {
            console.log(portfolio)
            console.log(item.property.id)
            const foundProperty = portfolio.properties.find(p => p.id == item.property.id)
            console.log("foundProperty", foundProperty)
            if (dropResult.portfolioId == portfolio.bpg_id && foundProperty) {
              return {...portfolio}
            } else if (foundProperty) {
              console.log("filtering...")
              return ({
                ...portfolio,
                properties: portfolio.properties.filter(p => p.id != item.property.id)
              })
            } else if (portfolio.bpg_id == dropResult.portfolioId) {
              axiosAuth().post("/admin/billing_property_groups/move", moveParams)
              return ({
                ...portfolio,
                properties: [...portfolio.properties, item.property]
              })
            } else {
              return {...portfolio}
            }
          }))
        )
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }))

  return ( 
      <div 
        ref={drag}
        style={{
          opacity: isDragging ? 0.5 : 1,
          fontSize: 25,
          fontWeight: 'bold',
          cursor: 'move',
        }} >
        <p>{property.name}</p>
    
      </div>
    )
}