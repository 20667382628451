
import { useContext, useEffect } from "react";
import { a11yProps } from "../../../components/helpers/a11y";
import { Tabs, Tab, makeStyles, Paper } from '@material-ui/core';
import WidgetContext from "../../../contexts/WidgetContext";

const useStyles = makeStyles(theme => ({
  tabs: {
    [theme.breakpoints.down('sm')]: {
      minHeight: '32px', // Adjust the minHeight for smaller screens
    },
  },
  tab: {
    '@media (max-width: 700px)': {
      minHeight: '32px', // Adjust the minHeight for smaller screens
      fontSize: '1rem', // Adjust the fontSize for smaller screens
      padding: '6px 12px', // Adjust the padding for smaller screens
    },
  },
}));

export default function YoYNav({sideNavTabIndex, energyType, setSideNavTabIndex, setLoadingChart, orientation}) {
  const classes = useStyles();
  const {windowWidth, widgetOptions} = useContext(WidgetContext)

  useEffect(_ => console.log(widgetOptions), [widgetOptions])

  const getTabStyles = (widgetOptions, property) => !!widgetOptions[property] ? {} : {display: 'none'}

  return ( 

    // <Paper style={{background: "#fcfcfc", marginBottom: 10}}>
      <Tabs   
        centered 
        indicatorColor="primary" 
        variant={windowWidth < 700 ? "fullWidth" : "standard"}
        value={sideNavTabIndex} 
        orientation={windowWidth < 700 ? "vertical" : orientation || "horizontal"}
        className={classes.tabs}
        onChange={(e, val) => {setLoadingChart(true); setSideNavTabIndex(val); setTimeout(_ => setLoadingChart(false), 25)}}>
        <Tab style={getTabStyles(widgetOptions, "includeCosts")} label="Monthly Cost" {...a11yProps(0)} className={classes.tab} />
        <Tab style={getTabStyles(widgetOptions, "includeUsage")} label="Monthly Usage" {...a11yProps(1)} disabled={energyType === 'all-costs'} className={classes.tab} />
        <Tab style={getTabStyles(widgetOptions, "includeCosts")} label="Cumulative Cost" {...a11yProps(2)} className={classes.tab} />
        <Tab style={getTabStyles(widgetOptions, "includeUsage")} label="Cumulative Usage" {...a11yProps(3)} disabled={energyType === 'all-costs'} className={classes.tab} />
      </Tabs>
    // </Paper>
  )
}