import React from "react";
import {Tabs, Tab, AppBar} from "@material-ui/core";
import {Link, useLocation, useHistory} from "react-router-dom";
import { SettingsInputSvideoRounded } from "@material-ui/icons";
import UserContext from '../contexts/UserContext';
import logo from "../images/branding/logo.png";
import ColorPicker from "./ColorPicker";
import { darkenHexColor } from "./helpers/color-helpers";

export default function Navigation() {

    const location = useLocation()
    const {activeTheme, user, setUser} = React.useContext(UserContext)
    const history = useHistory();
    
    const getActiveTab = pathname => {
        if (pathname.includes("confirm")) {
            return 1
        } else if (pathname.includes("invoyage")) {
            return 1
        } else {
            return 0
        }
    }

    const [activeTab, setActiveTab] = React.useState(getActiveTab(location.pathname))

    React.useEffect(_ => {
        const {pathname} = location;
        setActiveTab(getActiveTab(pathname))

    }, [location.pathname])

    const logout = _ => {
        setUser({...user, loggedIn: false})
        localStorage.removeItem("jwt")
        history.push("/login")
    }
    const pushTo = path => history.push(path)

    const invoyageTab = <Tab style={{color: 'white'}} onClick={_ => pushTo("/invoyage")} className="nav-tab" label="Invoyage" />

    
    return(
        <AppBar style={{background: activeTheme.palette.mode == "dark" ? darkenHexColor(activeTheme.palette.primary.main, 30) : activeTheme.palette.primary.main}} position="static">
             <Tabs
              TabIndicatorProps={{style: {background: "white"} }}
              value={activeTab}
              onChange={(e, v) => setActiveTab(v)}
              indicatorColor={"secondary"}
              style={{color: "white"}}
              centered>
                    {user.os 
                        ? invoyageTab
                        : user.admin 
                            ? [
                                    <Tab style={{color: 'white'}} onClick={_ => pushTo("/request")} className="nav-tab" label="Utility Requests" />,
                                    invoyageTab,            
                            ]
                            : null
                    }
                    {user.loggedIn 
                        ? <Tab style={{color: 'white'}} onClick={logout} className="nav-tab" label="Log Out" />
                        : <Tab style={{color: 'white'}} className="nav-tab" label="Energyprint" />
                    }
                    {user && user.loggedIn && user.admin && <ColorPicker />}
            </Tabs>
        </AppBar>
    )
}