import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from "@material-ui/core/Link"
import { Launch } from '@material-ui/icons';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const UAMTable = ({ uams }) => {
  const classes = useStyles();

  return (
    <Paper elevation={5}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>Meter Number</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {uams.map((uam, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <Link 
                  target="_blank"
                  href={`${process.env.REACT_APP_EP_BASE_URL}/admin/utility_meters/${uam.utility_meter_id}/edit`}
                  >
                  {uam.meter_num || "<blank>"}
                </Link>
              </TableCell>
              <TableCell align="right">{uam.status}</TableCell>
              <TableCell align="right">
                <Link
                  target="_blank"
                  href={`${process.env.REACT_APP_EP_BASE_URL}/admin/utility_meters/${uam.utility_meter_id}/utility_account_meters/${uam.id}/edit`}
                >
                  {uam.billing_status || "No billing status"}
                  &nbsp;
                  <Launch />
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default UAMTable;