import { useState } from 'react';

const useLocalStorage = (key, initialValue, changes={}) => {
  const [storedValue, setStoredValue] = useState(() => {
    let item = JSON.parse(localStorage.getItem(key))
    item = item || initialValue
    if (Object.keys(changes).length) {
      Object.entries(changes).forEach(([key, value]) => {
        item[key] = value
      })
    }
    return item
  })

  const setValue = newVal => {
    setStoredValue(newVal)
    localStorage.setItem(key, JSON.stringify(newVal))
  }

  return [storedValue, setValue]
}

export default useLocalStorage;