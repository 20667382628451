import { useEffect} from "react";
import { Typography } from "@material-ui/core";
import './CheckoutForm.scss'

import {redirectToRails} from "../helpers/rails-helpers";

const CheckoutForm = ({buildingCount}) => {

  useEffect(_ => {
    redirectToRails();
  }, [])

  return (
    <form className="checkout-form" >
          <Typography variant='h5'>Redirecting you to your EnergyPrint Dashboard...</Typography>
    </form>
  );
};

export default CheckoutForm;
