import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import { makeStyles } from '@material-ui/core/styles';
import PortfolioIndexListItem from './PortfolioIndexListItem';
import axiosAuth from '../../utils/axiosWithAuth';
import {useContext} from "react"
import BillingPortfolioContext from '../../contexts/BillingPortfolioContext';
import ScrollArea from "react-scrollbar"
function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: "5px",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));



export default function PortfolioIndex({portfolios, setPortfolios, companyId}) {
  const {openPortfolios, setOpenPortfolios} = useContext(BillingPortfolioContext)
  const classes = useStyles();

  const handleNewPortfolio = cid => {
    axiosAuth().post(`/admin/billing_property_groups/${cid}/new`)
      .then(r => setPortfolios(r.data.portfolios))
      .catch(console.error)
  }
  const handleResetPortfolio = cid => {
    if (window.confirm("This will delete all added portfolios and move all properties to 'Default'. Are you sure?")) {

      axiosAuth().post(`/admin/billing_property_groups/${cid}/reset`)
      .then(r => {
        console.log(r.data.portfolios)
        setPortfolios(r.data.portfolios)})
      .catch(console.error)
    }
  }

  const handlePropertySplit = cid => {
    if (window.confirm("This will remove all your custom groups, and create one group per property. Are you sure?")) {

      axiosAuth().post(`/admin/billing_property_groups/${cid}/split`)
      .then(r => setPortfolios(r.data.portfolios))
      .catch(console.error)
    }
  }

  return   (
  <div className={classes.root}>
    <List component="nav" aria-label="main mailbox folders">
      <div
            style={{
              maxHeight: 800,
              overflowY: 'auto'
            }}
      >
        {portfolios.map((p, idx) => {

          return <PortfolioIndexListItem key={p.bpg_id} portfolio={p} />
        })}
      </div>
    </List>
    <Divider />
    <List component="nav" aria-label="secondary mailbox folders">
      <ListItem button onClick={_ => handleNewPortfolio(companyId)}>
        <ListItemText primary="New Portfolio +" />
      </ListItem>
      <ListItem button onClick={_ => handleResetPortfolio(companyId)}>
        <ListItemText primary="Reset Portfolios" />
      </ListItem>
      <ListItem button onClick={_ => handlePropertySplit(companyId)}>
        <ListItemText primary="Split into Per-Property billing" />
      </ListItem>
    </List>

  </div>
  )
}