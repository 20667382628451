import {useEffect, useContext, useState} from "react";
import {Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from "@material-ui/core";
import QuickbooksContext from "../../contexts/QuickbooksContext";
import moment from 'moment';


export default function InvoiceCard({i, setSelectedInvoice}) {
    const {selecedCompany} = useContext(QuickbooksContext)

    const lineItems = []
    if (i.Line.length && i.TxnTaxDetail && i.TxnTaxDetail.TaxLine && i.TxnTaxDetail.TaxLine.length) {
        lineItems.push(...i.Line, ...i.TxnTaxDetail.TaxLine);
    } else if (i.Line.length) {
        lineItems.push(...i.Line)
    } else {
        
    }

    const linkToQuickbooks = i => {
        window.open(`https://app.qbo.intuit.com/app/customerdetail?nameId=${i.CustomerRef.value}`, "_blank")
    }

    return(
        <>
            <div className={`invoice-container`} key={i.DgId}>
                <div className="invoice-title-bar">
                    {i.CustomerRef ?<h3 style={{marginTop: 0}}> {i.CustomerRef.name} - {i.DocType} {i.TxnDate}</h3> : <h3 style={{marginTop: 0}}>{i.DocType} {i.TxnDate} </h3> }
                    <div>
                        <Button
                            varient="contained"
                            size="small"
                            onClick={_ => linkToQuickbooks(i)}>
                                Go To Quickbooks
                        </Button>
                        <Button onClick={_ => setSelectedInvoice(null)}>Close X</Button>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{textAlign: "left", marginLeft: "25px"}}>
                        {i.CustomerRef && <div><b style={{margin: 0}}>Customer:</b> {i.CustomerRef.name}</div>}
                        <div><b>Transaction Type:</b> {i.DocType}</div>
                        <div><b>Transaction Date:</b> {moment(i.TxnDate).format("MM/DD/YYYY")}</div>
                        {i.DueDate && <div><b>Due Date:</b> {moment(i.DueDate).format("MM/DD/YYYY")}</div>}
                        {i.TxnTaxDetail && <div><b>Total Tax:</b> $ {i.TxnTaxDetail.TotalTax}</div>}
                        {i.TotalAmt !== undefined && <div><b>Total Amount:</b> $ {i.TotalAmt}</div>}
                        {(i.Balance !== undefined) && <div><b>Balance:</b> $ {i.Balance}</div>}
                    </div>
                    <div style={{textAlign: "left"}}>
                        {i.CustomerMemo && <div><b>External Memo:</b> {i.CustomerMemo.value}</div>}
                        {i.PrivateNote && <div><b>Private Note:</b> {i.PrivateNote}</div>}
                    </div>
                    <div>
                        <div className={`line-item-header`}>
                            <h3 style={{marginTop: 0}}>Line Items</h3>
                        </div>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Detail Type</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Amount</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {lineItems.map((row, index) => (
                                        <TableRow 
                                            key={index}
                                        >
                                            <TableCell>{row.DetailType}</TableCell>
                                            <TableCell>{row.Description}</TableCell>
                                            <TableCell>$ {row.Amount}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </>
    )

}