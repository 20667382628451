import { MenuItem, Typography, AppBar, Tabs, Tab, InputLabel, Select, makeStyles} from "@material-ui/core"
import {useState, useContext} from "react";
import {a11yProps} from "../helpers/a11y"
import axiosAuth from "../../utils/axiosWithAuth";
import TextractContext from "../../contexts/TextractContext"
import DirectionsBoat from "@material-ui/icons/DirectionsBoat"
import { useUser } from "../../contexts/UserContext";

export default function TextractAppBar() {
  const {user} = useUser()
  const {configurations, setConfigurations, selectedConfiguration, setSelectedConfiguration, tabIndex, setTabIndex} = useContext(TextractContext)
  const boxShadow = "0px 3px 4px -1px rgba(0,0,0,0.2)"
  const handleConfigChange = e => {
    axiosAuth().get("/admin/textract_configurations/" + e.target.value).then(r => {
      setSelectedConfiguration(r.data.configuration)
    })
  }
  const useStyles= makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '50%',
        backgroundColor: 'white',
        border: '2px solid black',
        boxShadow: "2px 2px 2px #888888",
        padding: theme.spacing(2,4,3),
        top: `${5}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, 0%)`
    },
    configSelect: {
        width: 400, 
        marginLeft: 5, 
        textAlign: 'left', 
        padding: 5
    }
  }))
  const styles=useStyles()
  return (
    <AppBar position="static" color="default" style={{boxShadow}}>
    <Typography variant="h2">Invoyage <DirectionsBoat style={{fontSize: 40}} /> Processing</Typography>
    {!user.os && <div className="select-container">

        <InputLabel style={{marginLeft: 20}}  htmlFor="request-list-select">Selected Configuration:</InputLabel>

        <Select
            name="main-config-select"
            label="Configuration"
            variant='standard'
            className={styles.configSelect}
            onChange={handleConfigChange} 
            value={(selectedConfiguration && selectedConfiguration.id) || ""}>
          {configurations.map(c => {
                    return <MenuItem key={"config-" + c.id} value={c.id}>{c.name}</MenuItem>
          })}
        </Select>
    </div>}
        {!user.os && <Tabs   
            centered 
            indicatorColor="primary" 
            value={tabIndex} 
            onChange={(e, val) => setTabIndex(val)}>
            {!user.os && <Tab label="Extract Config" {...a11yProps(0)} />}
            <Tab label="Bills" {...a11yProps(1)} />
            {!user.os && <Tab label="Line Item Mappings" {...a11yProps(2)} />}
            {!user.os && <Tab label="AI Instructions" {...a11yProps(3)} /> }
        </Tabs>}
    </AppBar>
  )
}