import { groupBy } from "../../../../../helpers/arrayHelpers"

export const buildLineItemGroups = function(gptBill, uams) {
  const groupedCharges = groupBy( gptBill.charges, c => c.utility_account_meter_id )
  const groupedUsages = groupBy( gptBill.usages, u => u.utility_account_meter_id )
  const chargeUsageUamIds = [...new Set([...Object.keys(groupedCharges), ...Object.keys(groupedUsages)])].sort((a,b) => +a - +b)
  
  return chargeUsageUamIds.map(uamId => {

    let uam = uams.find(uam => uam.id === +uamId);
    console.log("Found UAM", uam)
    const name = !uam ? "Loading uams..." : `${uam.meter_num} ${uam.display_name} ${uam.association_type != "STANDARD" ? uam.association_type : ''}`
    return {
      name,
      utility_account_meter: uam,
      energy_type_id: uam && uam.energy_type_id,
      charges: groupedCharges[uamId] || [],
      usages: groupedUsages[uamId] || [],

  }}).sort((a,b) => a.energy_type_id - b.energy_type_id)
}




export const buildGptBillFromUtilityBill = function(textractBill) {
  const {ep_bill, gpt_bill} = textractBill
  if  (!ep_bill || !gpt_bill) return null

  const gptBillClone = {
    ...gpt_bill,
    charges: gpt_bill.charges.map(charge => ({ ...charge })),
    usages: gpt_bill.usages.map(usage => ({ ...usage }))
  };

  gptBillClone.charges.forEach(gptc => {
    if (!ep_bill.charges.find(c => c.gpt_charge_id == gptc.id)) {
      gptc.applicable = false
    }
  })
  gptBillClone.usages.forEach(gptu => {
    if (!ep_bill.usages.find(u => u.gpt_charge_id == gptu.id)) {
      gptu.applicable = false
    }
  })

  const unappliedCharges = gptBillClone.charges.filter(c => !c.applicable)
  const unappliedUsages = gptBillClone.usages.filter(u => !u.applicable)
  
  const newBill = {
    ...ep_bill,
    charges: ep_bill.charges.map(charge => ({ ...charge })),
    usages: ep_bill.usages.map(usage => ({ ...usage }))
  
  }
  newBill.usages.forEach(u => {
    u.applicable = true
    const gptUsage = gptBillClone.usages.find(gptUsage => gptUsage.id == u.gpt_usage_id)
    console.log(gptBillClone.usages)
    const newDescription = gptUsage ? gptUsage.description : "Manually Added Usage"
    if (gptUsage) {
      u.meter_num = gptUsage.meter_num
      u.measurement_unit = gptUsage.measurement_unit
      u.estimated = gptUsage.estimated
    }
    u.description = newDescription
  })
  newBill.charges.forEach(c => {
    c.applicable = true
    const gptCharge = gptBillClone.charges.find(gptCharge => gptCharge.id == c.gpt_charge_id)
    const newDescription = gptCharge ? gptCharge.description : "Manually Added Charge"
    if (gptCharge) c.meter_num = gptCharge.meter_num
    c.description = newDescription
  })
  newBill.usages.push(...unappliedUsages)
  newBill.charges.push(...unappliedCharges)
  console.log("built new gpt bill for saved data", newBill)

  return newBill      
}