import React, {useState, useContext} from "react"
import { DataGrid, GridToolbar  } from '@material-ui/data-grid';
import {Button, TextField, Link, Typography, Select, MenuItem} from "@material-ui/core"
import LaunchIcon from '@material-ui/icons/Launch';
import StarIcon from '@material-ui/icons/Grade';
import StarBorderIcon from '@material-ui/icons/StarBorder'
import SendIcon from '@material-ui/icons/Send';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import CustomToolbar from "./CustomToolbar";
import RequestListContext from "../../contexts/RequestListContext"
import UserContext from "../../contexts/UserContext"
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import {olderThanDays, getLaterDate, requestHasAlarm, getFormattedDate} from "../helpers/date-helpers";
import RequestCard from "./RequestCard"
import UamNotesModal from "./UamNotesModal"
import PlaceholderCard  from "./PlaceholderCard";
import { getEntry } from "../helpers/datagrid-helpers";

const styles= {
    follow_up_input: {
        width: "77px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        direction: "rtl",
        textAlign: "right",
        marginLeft: '123px'
    },
    delete_icon: {
        marginLeft: 5,
        cursor: "pointer"
    }
}

export default function RequestListGrid({requests, issue}) {
    const {handleMailToClick, templates, openNotesModal, setOpenNotesModal, selectedRequest, setSelectedRequest, markAsSent, markAsReceived, setFollowUpDate, setEmailTemplateId, handleDateRemoval} = useContext(RequestListContext)
    const {selectedTemplate} = useContext(UserContext)
    const [sortModel, setSortModel] = useState([]);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const handleSortModelChange = (newModel) => {
        if (JSON.stringify(filterModel) != JSON.stringify(newModel.sortModel)) {
          setSortModel(newModel.sortModel);
        }
      };
      const handleFilterModelChange = (newModel) => {
        if (JSON.stringify(filterModel) != JSON.stringify(newModel.filterModel)) {
    
          console.log(newModel)
          setFilterModel(newModel.filterModel);
        }
      };
    const handleSelectionChange = newSelection => {
        const dataGridId = newSelection.selectionModel[0]
        const foundRequest = requests.find(r => r.id == dataGridId)
        setSelectedRequest(foundRequest)
    }

    const getRowClassName = params => `super-app-theme--${params.getValue(params.id, 'property_name')}`
  
    const INITIAL_COLUMNS = [
        { field: 'id', headerName: 'DataGrid uuid', width: 100, hide: true },
        {
            field: "mark_request",
            headerName: `Mark ${issue? "Issue" : "Request"}`,
            headerAlign: "center",
            align: "center",
            width: 200,
            renderCell: (params) => {
                
                const row = getEntry(params)
                
                return <div style={{display: 'flex', justifyContent: "center", width: "100%"}}>
                    <Button onClick={_ => markAsSent(row, true, (issue ? "ISSUE" : ""))}>Sent</Button>
                    <Button onClick={_ => markAsReceived(row, true)}>Received</Button>
                </div>
            }
        },
        {
            field: 'acct_num',
            headerName: 'Account Number',
            width: 150,
            renderCell: (params) => {
                
                const row = getEntry(params)
                
                return  <div>
                            <Link target="_blank"
                                rel="noopener noreferrer"
                                href={`${process.env.REACT_APP_EP_BASE_URL}/admin/utility_accounts/${row.utility_account_id}/edit`}
                                className="grid-link">
                                    {row.acct_num}
                            </Link>              
                        </div>
                }
        },
        { field: 'property_name', 
        headerName: 'Property', 
        width: 200,
        renderCell: (params) => {
                
            const row = getEntry(params)
            
            return  <div>
                        <Link 
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`${process.env.REACT_APP_EP_BASE_URL}/admin/properties/${row.property_id}/edit`}
                            className="grid-link">
                                {row.property_name}
                        </Link>              
                    </div>
            }
     },
        {
          field: 'utility_company_name',
          headerName: 'Utility Company',
          width: 150,
          renderCell: (params) => {
                
            const row = getEntry(params)
            
            return  <div>
                        <Link  target="_blank"
                            rel="noopener noreferrer"
                            href={`${process.env.REACT_APP_EP_BASE_URL}/admin/utility_companies/${row.utility_company_id}/edit`}
                            className="grid-link">
                                {row.utility_company_name}
                        </Link>              
                    </div>
            },
        },

        {
            field: 'tax_id',
            headerName: 'Tax Id',
            width: 150,
            hide: true
        },
        {
            field: "tax_id_required",
            headerName: "Tax ID Required",
            headerAlign: "center",
            width: 100,
            hide: true,
            renderCell: (params) => {
                
                const row = getEntry(params)

                return  row.tax_id_required ? <div>Yes</div> : <div></div>
            }
        },
        {
            field: 'online_access_url',
            headerName: 'Online Access URL',
            width: 150,
            hide: true
        },
        {
            field: 'online_access_username',
            headerName: 'Online Access Username',
            width: 150,
            hide: true
        },
        {
            field: 'online_access_password',
            headerName: 'Online Access Password',
            width: 150,
            hide: true
        },
        {
            field: 'exclude_from_automated_requests',
            headerName: 'Exclude from Request Automation',
            width: 300,
            hide: true
        },
        {
            field: 'exclude_from_automated_issues',
            headerName: 'Exclude from Issue Automation',
            width: 300,
            hide: true
        },
        {
            field: 'master_acct_num',
            headerName: 'Master Account #',
            width: 150,
            hide: true
        },
        {
            field: 'premise_num',
            headerName: 'Premise/ESI #',
            width: 150,
            hide: true
        },
        {
            field: 'service_address',
            headerName: 'Service Address',
            width: 150,
            hide: true
        },
        {
            field: 'service_name',
            headerName: 'Service Name',
            width: 150,
            hide: true
        },
        {
            field: "c1_name",
            headerName: "C1 Name",
            hide: true,
            width: 100,
            renderCell: (params) => {
                
                const row = getEntry(params)
                
                return  <div>
                            <Link target="_blank"
                                rel="noopener noreferrer"
                                href={`${process.env.REACT_APP_EP_BASE_URL}/admin/companies/${row.c1_id}/edit`}
                                className="grid-link">
                                    {row.c1_name}
                            </Link>              
                        </div>
                }
            
        },
        {
            field: "c2_name",
            headerName: "C2 Name",
            hide: true,
            width: 100,
            renderCell: (params) => {
                
                const row = getEntry(params)
                
                return  <div>
                            <Link target="_blank"
                                rel="noopener noreferrer"
                                href={`${process.env.REACT_APP_EP_BASE_URL}/admin/companies/${row.c2_id}/edit`}
                                className="grid-link">
                                    {row.c2_name}
                            </Link>              
                        </div>
                }
        },
        {
            field: "utility_account_meter_id",
            headerName: "UAM id",
            hide: true,
            width: 100,
            renderCell: (params) => {
                
                const row = getEntry(params)
                
                return  <div>
                            <Link target="_blank"
                                rel="noopener noreferrer"
                                href={`${process.env.REACT_APP_EP_BASE_URL}/admin/utility_meters/${row.utility_meter_id}/utility_account_meters/${row.utility_account_meter_id}/edit`}
                                className="grid-link">
                                    {row.utility_account_meter_id}
                            </Link>              
                        </div>
                }
        },
        {
            field: "billing_status",
            headerName: "Billing Status",
            hide: true,
            width: 200
        },
        {
            field: "eligibility_end_month",
            headerName: "Eligibility End",
            hide: true,
            width: 200
        },
        {
            field: "company_issue_contact",
            headerName: "Company Issue Contact",
            hide: true,
            width: 200,
        },
        {
            field: "property_issue_contact",
            headerName: "Property Issue Contact",
            hide: true,
            width: 200,
        },
        {
            field: "c1_issue_contact",
            headerName: "C1 Issue Contact",
            hide: true,
            width: 200,
        },
        {
            field: "utility_company_issue_contact",
            headerName: "Utility Issue Contact",
            hide: true,
            width: 200,
        },
        {
            field: "issue_contact",
            headerName: "Issue Contact",
            hide: true,
            width: 200,
            renderCell: (params) => {
                
                const row = getEntry(params)
                
                return  <div>{row.property_issue_contact || row.company_issue_contact}</div>
                }
        },
        {
            field: "energy_type_name",
            headerName: "Energy Type",
            hide: true,
            width: 300
        },
        {
            field: "um_meter_num",
            headerName: "UM Meter Number",
            hide: true,
            width: 300
        },
        {
            field: "uam_meter_num",
            headerName: "UAM Meter Number",
            hide: true,
            width: 300
        },
        {
            field: "association_type",
            headerName: "Association Type",
            hide: true,
            width: 300
        },
        {
            field: "delivery_name",
            headerName: "Delivery Name",
            hide: true,
            width: 300
        },
        {
            field: "delivery_email",
            headerName: "Delivery Email",
            hide: true,
            width: 300
        },
        {
            field: "delivery_method",
            headerName: "Delivery Method",
            width: 200
        },
        {
          field: 'latest_invoice_date',
          headerName: 'Latest Invoice',
          width: 160,
          valueFormatter: function(v) { 
              return v.row.latest_invoice_date 
              ? getFormattedDate(new Date(v.row.latest_invoice_date).getTime() + (1000 * 60 * 60 * 24))
              : ""
            }
        },
        {
            field: "send_email",
            headerName: "Outlook",
            headerAlign: "center",
            sortable: false,
            width: 100,
            renderCell: (params) => {
                
                const row = getEntry(params)
                
                return  <div style={{display: 'flex', justifyContent: "center", width: "100%"}}>
                        {/* Launch Icon */}
                        {row.delivery_email && row.delivery_email.includes("[CALL]")
                            ? <Button onClick={_ => window.alert(row.delivery_email)}><PhoneInTalkIcon /></Button>
                            : <Button onClick={e => handleMailToClick(e, row, selectedTemplate, issue)} title="Use selected template" >
                                <LaunchIcon style={(!issue && row.exclude_from_automated_requests) || (issue && row.exclude_from_automated_issues) ? {color: '#1d9bf0'} : {}} />
                            </Button>
                    
                        }
                        {/* Favorite Icon  */}
                        {(!!row.delivery_email && !row.delivery_email.includes("[CALL]") && row.email_template_id) || issue
                            ? <Button title={issue ? "Auto-assign Issue Template" : "Use favorite template"} onClick={e => handleMailToClick(e, row, templates.find(t => t.id == row.email_template_id) || selectedTemplate, issue, true)}>
                                <StarIcon style={(!issue && row.exclude_from_automated_requests) || (issue && row.exclude_from_automated_issues) ? {color: '#1d9bf0'} : {}} />
                              </Button>
                            : <Button disabled>
                                <StarBorderIcon title="This account doesn't have a favorite template." />
                              </Button>
                        }
                            
                        </div>
            }
        },
        {
            field: "email_template_id",
            headerName: "Favorite Template ★",
            align: 'right',
            headerAlign: 'center',
            width: 170,
            hide: true,
            renderCell: (params) => {
                
                const row = getEntry(params)
                
                return <Select
                            id="email_template_id"
                            value={row.email_template_id}
                            label="Template"
                            onChange={e => setEmailTemplateId(row, e.target.value)}
                        >
                            <MenuItem value={null}>None</MenuItem>
                            {templates.map(t => <MenuItem value={t.id}>{t.name}</MenuItem>)}
                        </Select>
                
            }
        },
        {
          field: 'last_issue_sent_date',
          headerName: 'Last Issue Sent',
          width: 160,
          hide: !issue,
          valueFormatter: function(v) { 
              return v.row.last_issue_sent_date  
              ? getFormattedDate(v.row.last_issue_sent_date)
            : ""
          }
        },
        {
            field: 'last_sent_date',
            headerName: 'Last Sent',
          width: 120,
          hide: !!issue,
          valueFormatter: function(v) { 
            return v.row.last_sent_date  
            ? getFormattedDate(v.row.last_sent_date)
            : ""
        }
        },
        {
          field: 'last_received_date',
          headerName: 'Last Received',
          width: 160,  
          valueFormatter: function(v) { 
              return v.row.last_received_date  
              ? getFormattedDate(v.row.last_received_date)
            : ""
        }
    },

    
        {
            field: "follow_up_date",
            headerName: "Follow Up",
            align: 'right',
            headerAlign: 'center',
            width: 260,
            renderCell: (params) => {
                
                const row = getEntry(params)
                
                return <>
                    <TextField
                        id="date"
                        type="date"
                        style={!row.follow_up_date ? styles.follow_up_input : {}}
                        value={row.follow_up_date ? `${row.follow_up_date}`.split("T")[0] : ""}
                        defaultValue={row.follow_up_date ? row.follow_up_date.split("T")[0] : ""}
                        placeholder=""
                        onChange={e => setFollowUpDate(row, e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {!!row.follow_up_date && <span title="Remove Follow-up Date" style={styles.delete_icon} onClick={_ => handleDateRemoval(row)}>❌</span>}
                </>
            }
        },
        {
            field: "action_required",
            headerName: "Alarm",
            headerAlign: 'center',
            width: 80,
            sortComparator: (v1, v2, param1, param2) => {
                const row1 = getEntry(param1)
                return requestHasAlarm(row1) ? 1 : 0
            }
                    ,
            renderCell: (params) => {
                // todo: write sortComparator function for this column
                
                const row = getEntry(params)
                const titleBuilder = []
                if (row.follow_up_date && new Date() > new Date(row.follow_up_date)) {
                    titleBuilder.push("Follow up date has passed.")
                } 
                if ((row.last_received_date || row.last_sent_date) && olderThanDays( getLaterDate(row.last_sent_date, row.last_received_date), 14)) {
                    titleBuilder.push("Last correspondence is over 2 weeks old.")
                }
                return <>
                    {!!titleBuilder.length && requestHasAlarm(row)
                        &&  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', color: "#fb8c00", width: "100%", textAlign: "center"}} title={titleBuilder.join(" ")}>
                                <NotificationsActiveIcon /> 
                            </div>}
                </>
            }
        },
        {
          field: "issue_note",
          headerName: "Issue Note",
          width: 200,
          hide: true
        },
        {
          field: "request_on_week",
          headerName: "Request On Week",
          width: 200,
          hide: true
        },
        {
            field: "notes",
            headerName: "Notes",
            headerAlign: "center",
            width: 100,
            renderCell: (params) => {
                
                const row = getEntry(params)

                // const handleClick = _ => alert(row.notes)

                const handleClick = _ => setOpenNotesModal(true)


                
                return  row.notes ? <Button onClick={handleClick}>Notes</Button> : null
            }
        },        
        {
            field: "loa_required",
            headerName: "Loa Required",
            headerAlign: "center",
            width: 100,
            hide: true,
            renderCell: (params) => {
                
                const row = getEntry(params)
                
                return  row.loa_required ? <div>Yes</div> : <div></div>
            }
        },
        {
            field: "loa_signed_date",
            headerName: "LOA Signed Date",
            headerAlign: "center",
            width: 100,
            hide: true
        },
        {
            field: "loa_expiry_date",
            headerName: "LOA Expiry Date",
            headerAlign: "center",
            width: 100,
            hide: true
        },
        {
            field: "utility_company_id",
            headerName: "UC id",
            headerAlign: 'center',
            hide: true
        },
        {
            field: "utility_account_id",
            headerName: "UA id",
            headerAlign: 'center',
            hide: true
        },
        {
            field: "utility_meter_id",
            headerName: "UM id",
            headerAlign: 'center',
            hide: true
        },
        {
            field: "property_id",
            headerName: "Prop id",
            headerAlign: 'center',
            hide: true
        },
        {
            field: "c1_id",
            headerName: "C1 id",
            headerAlign: 'center',
            hide: true
        },
        {
            field: "c2_id",
            headerName: "C2 id",
            headerAlign: 'center',
            hide: true
        },
        {
            field: "non_invoice_request",
            headerName: "Non Invoice Request",
            headerAlign: 'center',
            hide: true
        },
    ];

    const [columns, setColumns] = useState(INITIAL_COLUMNS)
    const handleColumnVisibilityChange = (params) => {
        setColumns(currentColumns =>
          currentColumns.map((column) => {
            if (column.field === params.field) {
              return { ...column, hide: !params.isVisible };
            }
            return column;
          }),
        );
    };
        
    return (
        <>

            <div style={{ width: '100%' }}>
                <UamNotesModal 
                    request={selectedRequest}
                    openModal={openNotesModal}
                    handleClose={_ => setOpenNotesModal(false)}
                />
                {!!selectedRequest 
                ? <RequestCard r={selectedRequest} setSelectedRequest={setSelectedRequest} issue={!!issue}  />
                : <PlaceholderCard /> }
                <DataGrid
                    rows={requests}
                    pageSize={20}
                    rowsPerPageOptions={[20,50,100]}
                    getRowClassName={getRowClassName}
                    disableColumnMenu
                    autoHeight
                    onSelectionModelChange={handleSelectionChange}
                    sortModel={sortModel}
                    onSortModelChange={handleSortModelChange}
                    filterModel={filterModel}
                    onFilterModelChange={handleFilterModelChange}
                    columns={columns}
                    onColumnVisibilityChange={handleColumnVisibilityChange}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    />
            </div>
            
        </>
    )
}