export function titleize(snakeCaseStr) {
    return snakeCaseStr
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
}

export const parseQueryString = _ => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    
    const paramsObj = {};
    for (const [key, value] of searchParams) {
        paramsObj[key] = value;
    }
    
    return paramsObj
  }

export const abbreviateCommaNumber = (n) => {
  const num = parseInt(n, 10);
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(".0", "") + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(".0", "") + 'k';
  } else {
    return num.toString();
  }
}