import { useState, useEffect } from "react"
import TabPanel from "../../request-emails/TabPanel"
import IntakeAppBar from "./IntakeAppBar"
import IntakeTable from "./tables/IntakeTable"
import IntakeContext from "../../../contexts/IntakeContext"
import ScraperMonitor from "./scrapers/ScraperMonitor"
import "./Intake.scss"
import { IntakeAccountProvider, useIntakeAccounts } from "../../../contexts/AccountContext"

export default function Intake(props) {
  const {accounts, setAccounts} = useIntakeAccounts();
  const [queueTabIndex, setQueueTabIndex] = useState(1)
  const [selectedIntakeAccount, setSelectedIntakeAccount] = useState(null)
  useEffect(_ => setSelectedIntakeAccount(null), [queueTabIndex])
  useEffect(_ => {
    if (selectedIntakeAccount) {
      const foundSelectedAccount = accounts.find(a => a.id == selectedIntakeAccount.id)
      setSelectedIntakeAccount(foundSelectedAccount || null)
    }
  }, [accounts])

  return (
    <IntakeContext.Provider value={{accounts, setAccounts, selectedIntakeAccount, setSelectedIntakeAccount}}>
      <div className="invoyage-intake">
        <IntakeAppBar 
          queueTabIndex={queueTabIndex}
          setQueueTabIndex={setQueueTabIndex}
        />
        <TabPanel value={queueTabIndex} index={0} >
          <IntakeTable sources={["EP Manual"]} filter="all" />
        </TabPanel>
        <TabPanel value={queueTabIndex} index={1} >
          <IntakeTable sources={["EP Manual"]} filter="open" order="online_access_url, online_access_username, Utility" />
        </TabPanel>
        <TabPanel value={queueTabIndex} index={2} >
          <IntakeTable sources={["EP Manual"]} filter="closed" />
        </TabPanel>
        <TabPanel value={queueTabIndex} index={3} >
          <IntakeTable sources={["EP Scraped"]} filter="open"/>
        </TabPanel>
        <TabPanel value={queueTabIndex} index={4} >
          <IntakeTable sources={["ARDEM"]}/>
        </TabPanel>
        <TabPanel value={queueTabIndex} index={5} >
          <ScraperMonitor />
        </TabPanel>
      </div>
    </IntakeContext.Provider>
  )
}