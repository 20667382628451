import PropertyEntry from "./PropertyEntry";
import {ItemTypes} from "./constants.js"
import {useDrop} from "react-dnd"
import { TextField, Paper, Button, Typography, Divider } from "@material-ui/core";
import {useState} from "react";
import ScrollArea from 'react-scrollbar';
import axiosAuth from "../../utils/axiosWithAuth";

export default function PortfolioList({canDropProperty, portfolio, setPortfolios}) {
  const [editing, setEditing] = useState(false)
  const [form, setForm] = useState({
    name: portfolio.bpg_name,
    invoicing_contact_email: portfolio.invoicing_contact_email || ""
  })
  const handleChange = e => {
    setForm(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }))
  }

  const handleNameChange = e => {
    axiosAuth().put(`/admin/billing_property_groups/${portfolio.bpg_id}/update`, form)
      .then(r => {
        setPortfolios(r.data.portfolios)
        setEditing(false)
      })
      .catch(console.error)
  }

  const [{isOver}, drop] = useDrop(
    () => ({
      accept: ItemTypes.PROPERTY,
      drop: () => ({ portfolioId: portfolio.bpg_id }),
      collect: (monitor) => ({
        isOver: !!monitor.isOver()
      })
    }),
    [portfolio.bpg_id]
  )
  return (
          <Paper
            ref={drop}
            style={{
              position: 'relative',
              width: 500,
              height: 550,
              margin: 20
            }}
          >
            <div style={{display: 'flex', justifyContent: 'center'}}>
          {editing ?
          <div> 
            <TextField
                margin="normal"
                id="name"
                label="Name"
                name="name"
                value={form.name}
                onChange={handleChange}
                autoComplete="name"
                autoFocus
                variant="standard"
            />
            <TextField
                style={{marginLeft: 5}}
                margin="normal"
                id="invoicing_contact_email"
                label="Accounting Email"
                name="invoicing_contact_email"
                value={form.invoicing_contact_email}
                onChange={handleChange}
                autoComplete="invoicing_contact_email"
                autoFocus
                variant="standard"
            />
            <Button onClick={handleNameChange}>Save</Button>
          </div>
          : <div>
            <Typography style={{display: 'block', margin: 10}} variant="h5">{form.name}</Typography>
            <Typography style={{display: 'block', margin: 10}} variant="p">{form.invoicing_contact_email}</Typography>
            </div>
          }
          <Button onClick={_ => setEditing(!editing)}>{editing ? "Close X" : "Edit"}</Button>
          </div>
          <Divider />
          <ScrollArea
            speed={0.8}
            className="area"
            contentClassName="content"
            horizontal={false}
            style={{
              maxHeight: 495
            }}
            containerHeight={495}
          >
          
          {portfolio.properties.map( (prop, idx) => 
            <PropertyEntry key={prop.id} setPortfolios={setPortfolios} property={prop} />
          )}
          </ScrollArea>
          {isOver && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 1,
                opacity: 0.5,
                backgroundColor: '#fb8c00',
              }}
            />
          )}
  
         </Paper>
         
        )
}