import {useEffect, useContext, useState} from "react";
import {Typography, Button, Snackbar, Alert, TextField, Select, MenuItem, InputLabel, FormControl} from "@material-ui/core"
import RequestListContext from "../../contexts/RequestListContext"
import UserContext from "../../contexts/UserContext"
import StarIcon from '@material-ui/icons/Grade';
import StarBorderIcon from '@material-ui/icons/StarBorder'
import SendEmailModal from "./SendEmailModal";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EmailIcon from '@material-ui/icons/Email';
import StarMail from "../icons/StarMail.png"
import BlueStarMail from "../icons/BlueStarMail.png"
import {requestHasAlarm} from "../helpers/date-helpers";
import LaunchIcon from '@material-ui/icons/Launch';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import TextractUploader from "../textract/TextractUploader";
import TextractSnackbar from "../textract/snackbar/TextractSnackbar";
import moment from 'moment'
import HiveLink from "./HiveLink"

export default function RequestCard({r, setSelectedRequest, issue}) {
    const {selectedTemplate} = useContext(UserContext)
    const {groupedTemplates, openModal, setOpenModal, viewingIssues, handleMailToClick, setRequestOnWeek, handleAutomationToggle, templates, setEmailTemplateId, handleDateRemoval, setOpenNotesModal, authLetters, setFollowUpDate, markAsSent, markAsReceived} = useContext(RequestListContext)
    const [snackbar, setSnackbar] = useState({
        message: "",
        severity: "success"
    })
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const handleClose= _ => setOpenModal(false)
    const formatDateTime = dt => {
        if (!dt) return ""   
        return dt.split("T")[0]
    }
    useEffect(_ => {
        console.log("filtered templates", templates.filter(t => t.automated).map(t => t.id))
        console.log("template id", r.email_template_id)


    },[r])
    useEffect(_ => console.log(r),[r])

    function isOnIssue(r) {
        return ["Missing Invoice",
                "Website Issue",
                "Login Issue",
                "Contact Info Needed",
                "Account Update",
                "Data Repair",
                "Authorization Update",
                "Non-Responsive",
                "Delayed Invoice"].includes(r.billing_status)
    }

    function englishOrdinal(n) {
        return n == 1 
            ? "First"
            : n == 2
                ? "Second"
                : n == 3
                    ? "Third"
                    : n == 4
                        ? "Fourth"
                        : n == 5
                        ? "Fifth"
                        : "Sixth"
                    
    }

    function formatUTCDate(d) {
        var date = new Date(d)
        var userTimezoneOffset = date.getTimezoneOffset() * 60000;
        return moment(new Date(date.getTime() - userTimezoneOffset)).add(1,'days').format("l");
    }


    return(
        <>
            <SendEmailModal 
                setOpenSnackbar={setOpenSnackbar}
                openModal={openModal} 
                request={r}
                handleClose={handleClose}
            />
            <div className={`request-container ${requestHasAlarm(r) ? "alarm-request-card" : ""}`} key={r.id}>

                    <div className="request-title-bar">
                        <div style={{textAlign: "left"}}>
                            <h3 style={{marginTop: 0}}>
                                Acct: {r.acct_num} - {r.utility_company_name}
                            </h3>
                            <h3>{r.delivery_method || ""}</h3>
                            <h3 style={{margin: 0}}>Property: {r.property_name}</h3>
                            {r.delivery_name && <h3>Delivery Name: {r.delivery_name}</h3>}
                            {r.delivery_email && <h3>Delivery Email: {r.delivery_email}</h3>}
                            {r.master_acct_num && <h3>Master Acct #: {r.master_acct_num}</h3>}
                            {r.service_name && <h3>Service Name: {r.service_name}</h3>}
                            {r.service_address && <h3>Service Address: {r.service_address}</h3>}
                        </div>
                        
                        <div style={{marginTop: -7}}>
                            <div style={{display:'flex', justifyContent: 'flex-end', alignItems: 'flex-start'}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>    
                                    {r.delivery_email && r.delivery_email.includes("[CALL]")
                                        ? <Button onClick={_ => window.alert(r.delivery_email)}><PhoneInTalkIcon /></Button>
                                        : <Button onClick={e => handleMailToClick(e, r, selectedTemplate, viewingIssues)} title="Use selected template"><LaunchIcon style={(!viewingIssues && r.exclude_from_automated_requests) || (viewingIssues && r.exclude_from_automated_issues) ? {color: '#1d9bf0'} : {}} /></Button>    
                                    }
                                    <Button title="Preview with selected template" style={(!viewingIssues && r.exclude_from_automated_requests) || (viewingIssues && r.exclude_from_automated_issues) ? {color: '#1d9bf0'} : {}} onClick={e => {handleMailToClick(e, r, selectedTemplate, viewingIssues, false, true)}}><MailOutlineIcon /></Button>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    {viewingIssues || (!!r.delivery_email && !r.delivery_email.includes("[CALL]") && r.email_template_id) ?
                                    <Button onClick={e => handleMailToClick(e, r, templates.find(t => t.id == r.email_template_id) || selectedTemplate, viewingIssues, true)} title={issue ? "Auto-assign Issue Template" : "Use favorite template"} className="action-button">
                                        <StarIcon style={(!viewingIssues && r.exclude_from_automated_requests) || (viewingIssues && r.exclude_from_automated_issues) ? {color: '#1d9bf0'} : {}} />
                                    </Button>
                                    : <Button disabled><StarBorderIcon title="This account doesn't have a favorite template." /></Button>
                                    }
                                    {(viewingIssues || (!!r.delivery_email && !r.delivery_email.includes("[CALL]") && r.email_template_id)) && <><Button title={viewingIssues ? "Activate Izzy" : "Activate Oscar" } onClick={e => {handleMailToClick(e, r, templates.find(t => t.id == r.email_template_id), viewingIssues, true, true, true)}}><EmailIcon style={(!viewingIssues && r.exclude_from_automated_requests) || (viewingIssues && r.exclude_from_automated_issues) ? {color: '#1d9bf0'} : {}} /> </Button></>}
                                </div>


                                <Button onClick={_ => setSelectedRequest(null)}>Close X</Button>
                            </div>
                            <FormControl  variant="standard" sx={{ m: 1, minWidth: 300 }}>
                                <InputLabel id="email-template-select-label">Favorite Template (Ongoing)</InputLabel>
                                <Select
                                    labelId="email-template-select-label"
                                    id="email-template-select"
                                    value={r.email_template_id}
                                    label="Favorite Template (Ongoing)"
                                    onChange={e => setEmailTemplateId(r, e.target.value)}
                                >
                                    <MenuItem value={null}>None</MenuItem>
                                    {groupedTemplates}
                                    {/* {templates.map(t => <MenuItem value={t.id}>{t.name}</MenuItem>)} */}
                                </Select>
                            </FormControl>
                          
                            <br/>
                            {
                                (!!r.email_template_id && !viewingIssues) &&
                                <>
                                <FormControl  variant="standard" sx={{ m: 1, minWidth: 300 }}>
                                    <InputLabel shrink id="request-on-week-select-label">Request on Week of Month</InputLabel>
                                    <Select
                                        labelId="request-on-week-select-label"
                                        id="request-on-week-select"
                                        value={r.request_on_week}
                                        label="Request on Week of Month"
                                        onChange={e => setRequestOnWeek(r, e.target.value)}
                                        displayEmpty
                                        >
                                        <MenuItem value={null}>Any</MenuItem>
                                        {[1,2,3,4].map(n => <MenuItem value={n}>{englishOrdinal(n)}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                <Typography variant='h6' color='primary'>{!r.exclude_from_automated_requests ? "Automation Active 🤖" : "Automation Paused 🚧"}</Typography>
                                <Button variant="contained" onClick={e => handleAutomationToggle(r)} >{!r.exclude_from_automated_requests ? "Exclude from Automation" : "Activate Automation"}</Button>
                    
                                </>
                            }            
                            
                            <div style={{maxWidth: '330px', marginTop: 15}}>
                                <TextractUploader setSnackbar={setSnackbar} utility_company_id={r.utility_company_id} account={null} selectNextRow={null} role={"request"} />
                            </div>

                            {
                                viewingIssues &&
                                <>
                                    <Typography variant='h6' color='primary'>{!r.exclude_from_automated_issues ? "On-Issue Automation Active ⚠️🤖" : "On-Issue Automation Paused ⚠️🚧"}</Typography>
                                    <Button variant="contained" onClick={e => handleAutomationToggle(r)} >{!r.exclude_from_automated_issues ? "Pause On-Issue Automation" : "Activate On-Issue Automation"}</Button>
                                </>
                            }
                            {!!r.loa_signed_date && <h3 style={{textAlign: "right"}}>LOA Signed: {formatUTCDate(r.loa_signed_date)}</h3>}
                            {!!r.loa_expiry_date && <h3 style={{textAlign: "right"}}>LOA Expires: {formatUTCDate(r.loa_expiry_date)}</h3>}
                        </div>
                    </div>
                    
                    <div style={{margin: "0 auto", maxWidth: 800, textAlign: "left", display: 'flex', justifyContent: "space-between"}}>
                        <h3>Latest invoice: {formatDateTime(r.latest_invoice_date)}</h3>
                        <h3>|</h3> 
                        {viewingIssues
                        ? <h3>Last Issue sent: {formatDateTime(r.last_issue_sent_date)} {!!r.last_issue_sent_date && <span title="Remove Sent Date" style={{cursor: "pointer"}} onClick={_ => markAsSent(r, false, "ISSUE")}>❌</span>}</h3>
                         : <h3>Last sent: {formatDateTime(r.last_sent_date)} {!!r.last_sent_date && <span title="Remove Sent Date" style={{cursor: "pointer"}} onClick={_ => markAsSent(r, false)}>❌</span>}</h3>
                        }
                        <h3>|</h3>
                        <h3>Last received: {formatDateTime(r.last_received_date)} {!!r.last_received_date && <span title="Remove Received Date" style={{cursor: "pointer"}} onClick={_ => markAsReceived(r, false)}>❌</span>}</h3>
                    </div>
                    <div className="request-card-controls">
                        {
                            viewingIssues 
                                ? <Button variant='outlined' onClick={_ => markAsSent(r, true, "ISSUE")}>Mark Issue as Sent</Button>
                                : <Button variant='outlined' onClick={_ => markAsSent(r, true)}>Mark as Sent</Button>
                        }
                        <Button variant='outlined' onClick={_ => markAsReceived(r, true)}>Mark as Received</Button>
                        <TextField
                            id="date"
                            label="Follow Up On"
                            type="date"
                            value={r.follow_up_date ? `${r.follow_up_date}`.split("T")[0] : ""}
                            defaultValue={r.follow_up_date ? r.follow_up_date.split("T")[0] : ""}
                            onChange={e => setFollowUpDate(r, e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            />
                        {!!r.follow_up_date && <span style={{marginTop: 15, cursor: 'pointer'}} title="Remove Follow-up date" onClick={_ => handleDateRemoval(r)}>❌</span>}
                    </div>
                    <div className="request-card-bottom-row">

                        {!!r.notes && 
                        <div style={{textAlign: 'left'}}>
                            <h3>Newest Note <Button onClick={_ => setOpenNotesModal(true)}>See All</Button></h3>
                            <ul className="notes-container">
                                { r.notes.split(";").map(note => <li key={`${r.id}` + note}>{note}</li>)}
                            </ul>
                        </div>}
                        
                        {/* <div className="form-row">
                            {(dateVisible.includes(r.id) || r.follow_up_date)
                                ? <Button onClick={_ => handleDateRemoval(r) }>Remove follow up date</Button>
                                : <Button onClick={_ => setDateVisible([...dateVisible, r.id])}>
                                Set a follow up Date
                            </Button>}
                            
                            {(dateVisible.includes(r.id) || r.follow_up_date) &&
                                
                            }
                            
                            {r.completed 
                            ? <Button variant="outlined" onClick={_ => completeRequest(r.id, false)}>Mark as Incomplete</Button>
                            : <Button variant="outlined" onClick={_ => completeRequest(r.id, true)}>Mark as Complete</Button>}

                        </div> */}
                        
                        {!!r.loa_required && !!authLetters.length &&
                            <div className="loa-container">
                                <ul style={{textAlign: 'left'}}>
                                <h4>LOAs <a href={`${process.env.REACT_APP_EP_BASE_URL}/admin/companies/${r.c2_id}/edit`} target="_blank" >({r.c2_name})</a></h4>
                                    {authLetters.map( al => {
                                        return <li><a target="_blank" href={al.download_url}>{al.document_file_name}</a></li>
                                    })}
                                </ul>
                            </div>
                        }
                    </div>
                    {!!r.loa_required && <p style={{textAlign: 'left'}}><strong>Auth Path:</strong> {[r.c1_name, r.c2_name].filter(v => v).join(" -> ")} {"->"} authorization folder</p>}
                    {viewingIssues && <p style={{textAlign: 'left'}}><strong>Issue Note:</strong> {r.issue_note || "Not Found ❓"}</p>}
                    <p style={{textAlign: 'left'}}>
                        Delivery Method: {r.delivery_method}
                        <br/>
                        Billing Status: {r.billing_status}
                    </p>
                    {!!r.hive_action_id &&
                        <div>
                            <HiveLink hive_action_id={r.hive_action_id} hive_action_simple_id={r.hive_action_simple_id} />
                        </div>
                    }
                    <Snackbar 
                        anchorOrigin={{ vertical:"bottom", horizontal: "center" }}
                        open={openSnackbar} 
                        autoHideDuration={6000} 
                        onClose={_ => setOpenSnackbar(false)}>
                        <Alert onClose={_ => setOpenSnackbar(false)} severity="success">
                            Email sent!
                        </Alert>
                    </Snackbar>
                    <TextractSnackbar
                        snackbar={snackbar}
                        setSnackbar={setSnackbar}
                    />
                </div>
        </>
    )
}