import {Snackbar, Alert} from "@material-ui/core"



const TextractSnackbar = ({snackbar, setSnackbar}) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({...snackbar, message: ""})
  };
   return <Snackbar 
    anchorOrigin={{ vertical:"top", horizontal: "right" }}
    open={!!snackbar.message} 
    autoHideDuration={snackbar.timeout || 10000} 
    onClose={handleClose}>
    <Alert styleonClose={_ => setSnackbar({...snackbar, message: ""})} severity={snackbar.severity}>
        <div style={{display: 'flex'}}>
          {snackbar.message}
          {snackbar.loader || null}
        </div>
    </Alert>
  </Snackbar>
}

export default TextractSnackbar;