import { Select, MenuItem } from "@material-ui/core"
import { UTILITY_CHARGE_TYPES, UTILITY_USAGE_TYPES } from "../../../helpers/constants"
import { titleize } from "../../../helpers/string-helpers"
export default ({role, mapping, setMappings, saveUpdate}) => {
  const handleMappingTypeChange = e => {
    setMappings(prevState => {
      saveUpdate(mapping, {[`utility_${role}_type_id`]: e.target.value}, role)
      const newMappingsKey = `${role}Mappings`
      const newMappings = prevState[newMappingsKey]
      const idx = newMappings.findIndex(m => m.id == e.target.name)
      console.log(idx)
      console.log(newMappings[idx][`utility_${role}_type_id`])
      newMappings[idx][`utility_${role}_type_id`] = e.target.value
      newMappings[idx].ack = true
      return {
        ...prevState,
        [newMappingsKey]: newMappings
      }
    })
  }
  return (
  <Select
    name={mapping.id}
    value={mapping[`utility_${role}_type_id`] || "blank"}
    onChange={handleMappingTypeChange}
    color="primary"
  >
    <MenuItem key={'none'} value={'blank'}>Select {titleize(role)} Type</MenuItem>
    { role == "charge"
    ? UTILITY_CHARGE_TYPES.map(ct => <MenuItem key={ct.id} value={ct.id}>{ct.name}</MenuItem>)
    : UTILITY_USAGE_TYPES.map(ct => <MenuItem key={ct.id} value={ct.id}>{ct.name}</MenuItem>)
    
    }
  </Select>
  )
}