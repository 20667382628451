import { Typography, Paper, makeStyles, Divider, Alert} from "@material-ui/core"
import LineItemTable from "./LineItemTable"
import { useState, useContext } from "react"
import MeterInfo from "../MeterInfo"
import { IANAZone } from "luxon"
import LineItemGroupTitle from "./LineItemGroupTitle"
import GPTBillContext from "../../../../../contexts/GPTBillContext"

const useStyles= makeStyles(theme => ({
  billEntryPaper: {
      width: '80%',
      padding: theme.spacing(2,2,2),
      margin: "5px 0"
  },
  configSelect: {
      width: 400, 
      marginLeft: 5, 
      textAlign: 'left', 
      padding: 5
  }
}))

export default function LineItemGroup({uam, idx, group, bill, handleChargeChange, handleUsageChange, handleNewLineItem, uams, gptBill, setGptBill}) {
  const styles = useStyles()
  const {processingNotes} = useContext(GPTBillContext)

  return (
    <>
      {idx != 0 && <Divider style={{margin: 12}} />}
      <div className="line-item-group" style={{display: uam ? 'flex' : 'none'}}>

        <div className={styles.billEntryPaper}>
          {uam && processingNotes.filter(pn => pn.utility_account_meter_id == uam.id).map(note => 
              <>
                <Alert severity="info">
                  {note.content}
                </Alert>
                <br/>
              </>
          )}

          {uam && <LineItemGroupTitle uam={uam} group={group} />}
          <br/>
          <LineItemTable
            groupIndex={idx}
            isDisabled={!!bill.processed}
            role="usages"
            lineItems={group.usages}
            setGptBill={setGptBill}
            gptBill={gptBill}
            handleChange={handleUsageChange}
            uams={uams}
            uam={uam}
            handleNewLineItem={handleNewLineItem}
          />
          <LineItemTable
            groupIndex={idx}
            isDisabled={!!bill.processed}
            role="charges"
            lineItems={group.charges}
            setGptBill={setGptBill}
            gptBill={gptBill}
            handleChange={handleChargeChange}
            uams={uams}
            uam={uam}
            handleNewLineItem={handleNewLineItem}
            />
        </div>
        <MeterInfo uam={uam} group={group} />
        {/* Group by dates. */}
      </div>
    </>
  )
}